import DevicesIcon from '@mui/icons-material/Devices';
import { TabContext, TabPanel } from '@mui/lab';
import { Box, Button, Tab, Tabs, Typography } from '@mui/material';
import useLocale from '@theora360/shared/src/shared/use-locale';
import React, { useMemo, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useNavigate, useParams } from 'react-router-dom';
import InfoBox from '../../../components/InfoBox';
import MainPageTemplate from '../../../components/MainPageTemplate';
import useSelectedCampusId from '../../../shared/use-selected-campus-id';
import AnchorConfigurationBox from './AnchorConfigurationBox';
import AnchorCountersTable from './AnchorCountersTable';
import DeleteAnchorModal from './DeleteAnchorModal';
import NextPrevAnchorButtons from './NextPrevAnchorButtons';
import useAnchorLastUpdates from './use-anchor-last';
import ResetAnchorSequenceStatsBtn from './ResetAnchorSequenceStatsBtn';
import AnchorStdoutTerm from './AnchorStdoutTerm';
import EventsTab from '../events/EventsTab';
import EditAnchorModal from './EditAnchorModal';
import EditIcon from '@mui/icons-material/Edit';

function ViewAnchorPage() {
  const navigate = useNavigate();
  const { _id, tab } = useParams();
  const campusId = useSelectedCampusId();
  const device = useSelector(
    (store) =>
      store.anchors[campusId] &&
      store.anchors[campusId] &&
      store.anchors[campusId][_id],
    shallowEqual,
  );
  const locale = useLocale();
  const dateFormat = useMemo(() => {
    return new Intl.DateTimeFormat(locale, {
      dateStyle: 'short',
      timeStyle: 'medium',
    });
  }, [locale]);
  const last = useAnchorLastUpdates(_id);
  const [info1, info2, info3] = useMemo(() => {
    if (device) {
      return [
        [
          {
            label: 'Label',
            desc: device.label,
          },
          {
            label: 'Description',
            desc: device.desc,
          },
          device.address && {
            label: 'IP Address',
            desc: device.address && device.address.host,
          },
          device.siteMasterId && {
            label: 'Site Master',
            component: (
              <Link
                to={`/devices/site-masters/${device.siteMasterId}/fw-status`}
              >
                {device.siteMasterId}
              </Link>
            ),
          },
          {
            label: 'Updated At',
            desc: dateFormat.format(new Date(device.updatedAt)),
          },
          last && {
            label: 'Last Message At',
            desc: dateFormat.format(new Date(last.updatedAt)),
          },
          last && {
            label: 'Message Sequence No',
            desc: `${last.sequenceNo}`,
          },
          last && {
            label: 'Last Anchor Post',
            desc: last.lastAnchorPost
              ? dateFormat.format(new Date(last.lastAnchorPost * 1000))
              : undefined,
          },
          last &&
            last.ipSourceType && {
              label: 'Last TL Protocol',
              desc: `${last.ipSourceType}`,
            },
        ].filter((i) => i),
        [
          last && {
            label: '1 min',
            desc: `${last.numPacketsIn1Min}`,
          },
          last && {
            label: '1 hr',
            desc: `${last.numPacketsIn1Hr}`,
          },
          last && {
            label: '1 day',
            desc: `${last.numPacketsIn1Day}`,
          },
        ].filter((i) => i),
        [
          last && {
            label: '1 min',
            desc: `${last.numDropPacketsIn1Min}`,
          },
          last && {
            label: '1 hr',
            desc: `${last.numDropPacketsIn1Hr}`,
          },
          last && {
            label: '1 day',
            desc: `${last.numDropPacketsIn1Day}`,
          },
        ].filter((i) => i),
      ];
    }
    return [[], [], []];
  }, [device, dateFormat, last]);

  const [editing, setEditing] = useState();
  const onCancelEditAnchor = () => setEditing(undefined);
  const onEditAnchor = () => setEditing({ data: device });
  const onFinishEditAnchor = () => setEditing(undefined);

  return (
    <MainPageTemplate title="Anchor">
      <Typography variant="h3" gutterBottom>
        <DevicesIcon style={{ height: 40, width: 40 }} /> Anchor
      </Typography>
      <Box sx={{ display: 'flex', mb: 2, height: 36.5 }}>
        <NextPrevAnchorButtons _id={_id} />
      </Box>
      <Box sx={{ display: 'flex', mb: 2 }}>
        <Button
          startIcon={<EditIcon />}
          variant="contained"
          sx={{ mr: 2 }}
          onClick={onEditAnchor}
        >
          Edit Anchor Details
        </Button>
        {device && <DeleteAnchorModal data={device} />}
      </Box>
      <Typography variant="h6" sx={{ mb: 1 }}>
        Device Details
      </Typography>
      <InfoBox items={info1} sx={{ mb: 3 }} />
      <Typography variant="h6" sx={{ mb: 1 }}>
        Incoming Messages
      </Typography>
      {device && <ResetAnchorSequenceStatsBtn data={device} />}
      <InfoBox items={info2} sx={{ mb: 3 }} />
      <Typography variant="h6" sx={{ mb: 1 }}>
        Dropped Messages
      </Typography>
      <InfoBox items={info3} sx={{ mb: 3 }} />
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={tab}
          onChange={(event, value) => {
            navigate(`/devices/anchors/${_id}/${value}`);
          }}
        >
          <Tab value="status" label="Anchor Status" />
          <Tab value="counters" label="Anchor Counters" />
          <Tab value="stdout" label="Stdout" />
          <Tab value="events" label="Events" />
        </Tabs>
      </Box>
      <TabContext value={tab}>
        <TabPanel
          value="status"
          index={0}
          sx={{ paddingRight: 0, paddingLeft: 0 }}
        >
          {device && device.siteMasterId && (
            <AnchorConfigurationBox _id={_id} />
          )}
          {device && !device.siteMasterId && (
            <Typography variant="body1" sx={{ textAlign: 'center', p: 2 }}>
              It looks like this anchor's site master hasn't been discovered
              yet. Request an anchor manifest from this anchor's site master
              page to view this anchor's configuration.
            </Typography>
          )}
        </TabPanel>
        <TabPanel
          value="counters"
          index={1}
          sx={{ paddingRight: 0, paddingLeft: 0 }}
        >
          <AnchorCountersTable _id={_id} />
        </TabPanel>
        <TabPanel
          value="stdout"
          index={2}
          sx={{ paddingRight: 0, paddingLeft: 0 }}
        >
          <AnchorStdoutTerm _id={_id} />
        </TabPanel>
        <TabPanel
          value="events"
          index={3}
          sx={{ paddingRight: 0, paddingLeft: 0 }}
        >
          <EventsTab anchorId={_id} />
        </TabPanel>
      </TabContext>
      <EditAnchorModal
        visible={editing}
        onFinish={onFinishEditAnchor}
        onCancel={onCancelEditAnchor}
      />
    </MainPageTemplate>
  );
}

export default ViewAnchorPage;
