import { gql, useMutation } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  MenuItem,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { showMessageAction } from '@theora360/shared/src/redux-store/snackbar-store';
import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { FormContainer, TextFieldElement } from 'react-hook-form-mui';
import { useDispatch } from 'react-redux';

const writeAnchorConfigurationMutation = gql`
  mutation WriteAnchorConfiguration($config: AnchorConfigurationInput!) {
    writeAnchorConfiguration(config: $config)
  }
`;

const TimeDistributionOptions = [
  {
    label: '1',
    value: 1,
  },
  {
    label: '2',
    value: 2,
  },
  {
    label: '3',
    value: 3,
  },
  {
    label: '4',
    value: 4,
  },
  {
    label: '5',
    value: 5,
  },
  {
    label: '6',
    value: 6,
  },
  {
    label: '7',
    value: 7,
  },
  {
    label: '8',
    value: 8,
  },
  {
    label: '9',
    value: 9,
  },
  {
    label: 'Unspecified',
    value: 255,
  },
];

function SelectTimeDistributionElement({
  variant,
  name,
  label,
  disabled,
  required,
  style,
}) {
  return (
    <TextFieldElement
      style={style}
      variant={variant}
      name={name}
      label={label}
      type="text"
      disabled={disabled}
      select
    >
      {TimeDistributionOptions.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </TextFieldElement>
  );
}

function WriteAnchorConfigurationModal({ visible, onCancel, onFinish }) {
  const { data } = visible || {};
  const [loading, setLoading] = useState(false);
  const [writeAnchorConfiguration] = useMutation(
    writeAnchorConfigurationMutation,
  );
  const form = useForm();

  useEffect(() => {
    if (data) {
      if (data.timeDistribution >= 1 && data.timeDistribution <= 9) {
        form.setValue('timeDistribution', data.timeDistribution);
      } else {
        form.setValue('timeDistribution', 255);
      }
      ['A', 'B', 'C', 'D', 'E', 'F'].forEach((slotId) => {
        form.setValue(`lanNumber${slotId}`, data[`lanNumber${slotId}`]);
        form.setValue(`rangingOrder${slotId}`, data[`rangingOrder${slotId}`]);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const dispatch = useDispatch();
  const onSubmit = useCallback(
    async (values) => {
      Object.keys(values).forEach((key) => {
        values[key] = Number(values[key]);
      });
      setLoading(true);
      try {
        await writeAnchorConfiguration({
          variables: {
            config: {
              _id: data._id,
              ...values,
            },
          },
        });
        dispatch(
          showMessageAction({
            _id: 'edit-anchor-config',
            severity: 'success',
            message: 'Anchor configuration saved!',
          }),
        );
        onFinish();
      } catch (err) {
        console.error(err);
        dispatch(
          showMessageAction({
            _id: 'edit-anchor-config',
            severity: 'error',
            message: 'There was an error writing anchor configuration',
          }),
        );
      }
      setLoading(false);
    },
    [writeAnchorConfiguration, data, dispatch, onFinish],
  );

  return (
    <Modal
      open={!!visible}
      onClose={onCancel}
      sx={{
        overflow: 'scroll',
        p: 4,
      }}
    >
      <Box
        sx={{
          backgroundColor: 'white',
          textAlign: 'center',
          borderRadius: 1,
          padding: 4,
          marginTop: 4,
          marginBottom: 4,
          width: 500,
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
      >
        <Typography variant="h4" sx={{ mb: 3 }}>
          Write LAN Assignments
        </Typography>
        <FormContainer formContext={form} onSuccess={onSubmit}>
          <SelectTimeDistributionElement
            style={{ textAlign: 'left', marginBottom: 32, width: '100%' }}
            variant="standard"
            name="timeDistribution"
            label="Time Distribution"
            type="text"
            disabled={loading}
            required
          />
          <Typography variant="h6" sx={{ mb: 1 }}>
            LAN Assignments
          </Typography>
          <Paper>
            <TableContainer>
              <Table sx={{ minWidth: 400 }}>
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Slot</TableCell>
                    <TableCell align="left">Lan Number</TableCell>
                    <TableCell align="left">Ranging Order</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {['A', 'B', 'C', 'D', 'E', 'F'].map((slotId) => {
                    const lName = `lanNumber${slotId}`;
                    const rName = `rangingOrder${slotId}`;
                    return (
                      <TableRow
                        key={slotId}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {slotId}
                        </TableCell>
                        <TableCell align="left">
                          <Controller
                            control={form.control}
                            name={lName}
                            rules={{
                              required: true,
                              validate: (value) => {
                                const n = Number(value);
                                return (
                                  (!Number.isNaN(n) && n >= 0 && n <= 46) ||
                                  n === 999 ||
                                  'Must be a number between 1 and 46'
                                );
                              },
                            }}
                            render={({
                              field: { value, onChange, onBlur, ref },
                              fieldState: { error },
                            }) => (
                              <TextField
                                label={`Lan Number ${slotId}`}
                                name={lName}
                                variant="standard"
                                disabled={loading}
                                value={value}
                                onChange={onChange}
                                onBlur={onBlur}
                                required
                                type="text"
                                error={!!error}
                                helperText={
                                  error ? error.message : '1 - 46 or 999'
                                }
                              />
                            )}
                          />
                        </TableCell>
                        <TableCell align="left">
                          <Controller
                            control={form.control}
                            name={rName}
                            rules={{
                              required: true,
                              validate: (value) => {
                                const n = Number(value);
                                return (
                                  (!Number.isNaN(n) && n >= 0 && n <= 15) ||
                                  n === 255 ||
                                  'Must be a number between 0 and 255'
                                );
                              },
                            }}
                            render={({
                              field: { value, onChange, onBlur, ref },
                              fieldState: { error },
                            }) => (
                              <TextField
                                label={`Ranging Order ${slotId}`}
                                name={rName}
                                variant="standard"
                                disabled={loading}
                                value={value}
                                onChange={onChange}
                                onBlur={onBlur}
                                required
                                type="text"
                                error={!!error}
                                helperText={
                                  error ? error.message : '0 - 15 or 255'
                                }
                              />
                            )}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
          <div style={{ height: 16 }} />
          <Box>
            <LoadingButton type="submit" variant="contained" loading={loading}>
              Send Configuration
            </LoadingButton>
          </Box>
        </FormContainer>
      </Box>
    </Modal>
  );
}

export default WriteAnchorConfigurationModal;
