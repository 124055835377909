import EditIcon from '@mui/icons-material/Edit';
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import useLocale from '@theora360/shared/src/shared/use-locale';
import { formatDuration } from '@theora360/shared/src/shared/utils';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import useAnchorCountersUpdates from './use-anchor-counters';

const dense = false;

function AnchorCountersTable({ _id }) {
  const status = useAnchorCountersUpdates(_id);
  const locale = useLocale();

  const [now, setNow] = useState();
  const updatedTimeout = useRef();
  useEffect(() => {
    updatedTimeout.current = setInterval(() => {
      setNow(new Date().getTime() / 1000);
    }, 1000);
    return () =>
      updatedTimeout.current && clearInterval(updatedTimeout.current);
  }, []);

  const [info1, info2] = useMemo(() => {
    const dateFormat = new Intl.DateTimeFormat(locale, {
      dateStyle: 'short',
      timeStyle: 'medium',
    });
    if (status) {
      const rebootTime =
        new Date(status.ssbWifiFirstEstablished * 1000).getTime() / 1000;
      return [
        [
          {
            label: 'Latest "initial sync"',
            time: dateFormat.format(
              new Date(status.unixMostRecentInitial * 1000),
            ),
            afterReboot: `${formatDuration(
              status.unixMostRecentInitial - rebootTime,
            )}`,
            beforeNow: `${formatDuration(
              now - status.unixMostRecentInitial,
            )} ago`,
          },
          {
            label: 'First precision time sync',
            time: dateFormat.format(new Date(status.unixFirstPTS * 1000)),
            afterReboot: `${formatDuration(status.unixFirstPTS - rebootTime)}`,
            beforeNow: `${formatDuration(now - status.unixFirstPTS)} ago`,
          },
          {
            label: 'Last time Tag ranged',
            time: dateFormat.format(new Date(status.unixMostRecentPTS * 1000)),
            afterReboot: `${formatDuration(
              status.unixMostRecentPTS - rebootTime,
            )}`,
            beforeNow: `${formatDuration(now - status.unixMostRecentPTS)} ago`,
          },
          {
            label: 'Latest config received',
            time: dateFormat.format(new Date(status.unixLatestConfigRx * 1000)),
            afterReboot: `${formatDuration(
              status.unixLatestConfigRx - rebootTime,
            )}`,
            beforeNow: `${formatDuration(now - status.unixLatestConfigRx)} ago`,
          },
          {
            label: 'First POST after RESET',
            time: dateFormat.format(
              new Date(status.ssbWifiFirstEstablished * 1000),
            ),
            afterReboot: `${formatDuration(
              status.ssbWifiFirstEstablished - rebootTime,
            )}`,
            beforeNow: `${formatDuration(
              now - status.ssbWifiFirstEstablished,
            )} ago`,
          },
          {
            label: 'First site master contact',
            time: dateFormat.format(
              new Date(status.ssbFirstHeardSiteMaster * 1000),
            ),
            afterReboot: `${formatDuration(
              status.ssbFirstHeardSiteMaster - rebootTime,
            )}`,
            beforeNow: `${formatDuration(
              now - status.ssbFirstHeardSiteMaster,
            )} ago`,
          },
        ].filter((i) => i),
        [
          {
            label: 'Logical Address',
            value: status.logicalAddr,
          },
          {
            label: 'Build No.',
            value: status.buildNumber,
          },
          {
            label: 'Board ID',
            value: status.boardId,
          },
          {
            label: 'Tags pinged in last epoch',
            value: status.cTagsPingedInLastEpoch,
          },
          {
            label: 'QOS tags in last epoch',
            value: status.cQosTagsInLastEpoch,
          },
          {
            label: 'Tags that answered in last epoch',
            value: status.cTagsAnsweredLastEpoch,
          },
          {
            label: 'Board resets',
            value: status.cBoardResets,
          },
          {
            label: 'Wifi connect & start count',
            value: status.cWifiEstablished,
          },
          {
            label: 'Wifi failed to connect count',
            value: status.cWifiEstablishFail,
          },
          {
            label: 'Latest Wifi connect fail (ssbWifiEstablishFail)',
            value: status.ssbWifiEstablishFail,
          },
          {
            label: 'Latest Wifi connect (ssbWifiLastEstablished)',
            value: status.ssbWifiLastEstablished,
          },
          {
            label: 'Latest Config from SiteMaster (ssbConfigCompleted)',
            value: status.ssbConfigCompleted,
          },
          {
            label: 'First rough time sync (ssbRoughTimeSyncFirst)',
            value: status.ssbRoughTimeSyncFirst,
          },
          {
            label: 'Wait for initial time sync count',
            value: status.cWaitsForInitialSync,
          },
          {
            label: '# of precision time syncs received',
            value: status.cPrecisionTimeSyncRx,
          },
          {
            label: '# of precision time syncs sent',
            value: status.cPrecisionTimeSyncSent,
          },
          {
            label: '# of sequential time syncs received',
            value: status.cSequentialPtsRx,
          },
          {
            label: 'Max sequential time syncs since reset',
            value: status.cMaxSequentialPtsRx,
          },
          {
            label: 'Time syncs missed in a row',
            value: status.cRecoveredGapInPTS,
          },
          {
            label: '# of tags in my LAN',
            value: status.cTagsInLMlan,
          },
          {
            label: '# of Accelerometer Tags in my LAN',
            value: status.cQosTagsInLMlan,
          },
          {
            label: 'Epochs actively ranging since boot',
            value: status.cEpochsInTimingWheel,
          },
          {
            label: 'Epochs inoperable since boot',
            value: status.cEpochsNotOperable,
          },
          {
            label: 'Device clock ticks per millisecond',
            value: status.totalTimeCorrection,
          },
          {
            label: 'Epochs when config was received',
            value: status.cAnchorRxConfigs,
          },
          {
            label: 'Anchor to anchor range initiations',
            value: status.cAnchorToAnchorInits,
          },
          {
            label: 'Successful anchor to anchor range initiations',
            value: status.cAnchorToAnchorInitGood,
          },
          {
            label: 'Anchor to anchor range transponds',
            value: status.cAnchorToAnchorTxpds,
          },
          {
            label: 'Updated At',
            value: dateFormat.format(new Date(status.updatedAt)),
          },
        ].filter((i) => i),
      ];
    }
    return [];
  }, [status, locale, now]);

  return (
    <>
      {status && (
        <>
          <Paper sx={{ mb: 4 }}>
            <TableContainer>
              <Table sx={{ minWidth: 650 }} size={dense ? 'small' : undefined}>
                <TableHead>
                  <TableRow>
                    <TableCell
                      component="th"
                      scope="col"
                      align="left"
                      sx={{ fontWeight: 'bold' }}
                    >
                      Label
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="col"
                      align="left"
                      sx={{ fontWeight: 'bold' }}
                    >
                      Time
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="col"
                      align="left"
                      sx={{ fontWeight: 'bold' }}
                    >
                      After Reboot
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="col"
                      align="left"
                      sx={{ fontWeight: 'bold' }}
                    >
                      Before Now
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {info1.map((row) => (
                    <TableRow
                      key={row.label}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {row.label}
                      </TableCell>
                      <TableCell align="left">{row.time}</TableCell>
                      <TableCell align="left">{row.afterReboot}</TableCell>
                      <TableCell align="left">{row.beforeNow}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
          <Paper>
            <TableContainer>
              <Table sx={{ minWidth: 650 }} size={dense ? 'small' : undefined}>
                <TableHead>
                  <TableRow>
                    <TableCell
                      component="th"
                      scope="col"
                      align="left"
                      sx={{ fontWeight: 'bold' }}
                    >
                      Label
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="col"
                      align="left"
                      sx={{ fontWeight: 'bold' }}
                    >
                      Value
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {info2.map((row) => (
                    <TableRow
                      key={row.label}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {row.label}
                      </TableCell>
                      <TableCell align="left">{row.value}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </>
      )}
    </>
  );
}

export default AnchorCountersTable;
