import { CircularProgress } from '@mui/material';
import React from 'react';
import logo from '../assets/t-logo-lg.png';
import settings from '../settings';

const AppLoading = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        height: '100vh',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <div style={{ position: 'relative' }}>
        <div
          style={{
            background: settings.colors.blue,
            borderRadius: '50%',
            marginBottom: 16,
            height: 100,
            width: 100,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <img style={{ width: 64 }} src={logo} alt="logos" />
        </div>
        <CircularProgress
          size={140}
          thickness={2}
          style={{
            position: 'absolute',
            top: -20,
            left: -20,
          }}
        />
      </div>
    </div>
  );
};

export default AppLoading;
