import { gql, useSubscription } from '@apollo/client';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  createSiteMasterAction,
  deleteSiteMasterAction,
  updateSiteMasterAction,
} from '../../redux-store/site-master-store';

export const SiteMasterAttributes = gql`
  {
    _id
    label
    desc
    campusId
    gatewayId
    address {
      host
      port
    }
    createdAt
    updatedAt
    deletedAt
  }
`;

export const SiteMasterUpdateAttributes = gql`
{
  type
  crud
  id
  new ${SiteMasterAttributes}
}
`;

export const siteMasterUpdatesSubscription = gql`
  subscription SiteMasterUpdates($campusId: ID!) {
    siteMasterUpdates(campusId: $campusId) ${SiteMasterUpdateAttributes}
  }
`;

function useSiteMasterUpdates(campusId) {
  const queryId = campusId || 'default';
  useSubscription(siteMasterUpdatesSubscription, {
    skip: !campusId,
    variables: {
      campusId,
    },
    fetchPolicy: 'no-cache',
    onData: ({ client, data: result }) => {
      try {
        if (result && result.data && result.data.siteMasterUpdates) {
          const updates = result.data.siteMasterUpdates;
          updates.forEach((update) => {
            switch (update.type) {
              case 'SITE_MASTER_CRUD': {
                switch (update.crud) {
                  case 'CREATE':
                    dispatch(createSiteMasterAction(update.new, queryId));
                    break;
                  case 'READ':
                    dispatch(updateSiteMasterAction(update.new, queryId));
                    break;
                  case 'UPDATE':
                    dispatch(updateSiteMasterAction(update.new, queryId));
                    break;
                  case 'DELETE':
                    dispatch(deleteSiteMasterAction(update.id, queryId));
                    break;
                  default:
                    break;
                }
                break;
              }
              default:
                break;
            }
          });
        }
      } catch (err) {
        console.error(err);
      }
    },
  });
  const dispatch = useDispatch();
}

export default useSiteMasterUpdates;
