import { gql, useQuery } from '@apollo/client';
import { NeredeDataAttributes } from './constants';
import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { showMessageAction } from '@theora360/shared/src/redux-store/snackbar-store';
import { anchorColors } from '../demo/constants';
import DatasetRangesPlot from './DatasetRangesPlot';
import { Box, Tab, Tabs } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { TabContext, TabPanel } from '@mui/lab';
import useDataRangeTraces from './use-data-range-traces';
import DataRangesTab from './DataRangesTab';
import DataAnchorLocsTab from './DataAnchorLocsTab';
import DataAnnotationsTab from './DataAnnotationsTab';
import useNeredeDatasetAnnotationUpdates from './use-dataset-annotation-updates';

export const neredeDatasetDataQuery = gql`
  query ViewNeredeData($datasetId: ID!) {
    neredeDatasetData(datasetId: $datasetId) ${NeredeDataAttributes}
  }
`;

function ViewData({ datasetId, tab, campusId }) {
  const result = useQuery(neredeDatasetDataQuery, {
    variables: { datasetId },
  });
  const dispatch = useDispatch();
  useEffect(() => {
    if (result.error) {
      console.error(result.error);
      dispatch(
        showMessageAction({
          _id: 'view-data',
          severity: 'error',
          message: 'There was an fetching dataset data',
        }),
      );
    }
  }, [result, dispatch]);

  const datas = result.data?.neredeDatasetData;

  const navigate = useNavigate();
  useNeredeDatasetAnnotationUpdates(datasetId);

  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={tab}
          onChange={(event, value) => {
            navigate(`/datasets/view/${datasetId}/${value}`);
          }}
        >
          <Tab value="ranges" label="Ranges" />
          <Tab value="anchor-locs" label="Anchor Locations" />
          <Tab value="annotations" label="Annotations" />
        </Tabs>
      </Box>
      <TabContext value={tab}>
        <TabPanel value="ranges" index={0} style={{ padding: 0 }}>
          <DataRangesTab
            datas={datas}
            datasetId={datasetId}
            campusId={campusId}
          />
        </TabPanel>
        <TabPanel value="anchor-locs" index={1} style={{ padding: 0 }}>
          <DataAnchorLocsTab datas={datas} />
        </TabPanel>
        <TabPanel value="annotations" index={2} style={{ padding: 0 }}>
          <DataAnnotationsTab datasetId={datasetId} />
        </TabPanel>
      </TabContext>
    </>
  );
}

export default ViewData;
