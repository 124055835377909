import { gql, useSubscription } from '@apollo/client';
import { useState } from 'react';

export const AnchorCountersAttributes = gql`
  {
    _id
    campusId
    logicalAddr
    buildNumber
    boardId
    cTagsPingedInLastEpoch
    cQosTagsInLastEpoch
    cTagsAnsweredLastEpoch
    cBoardResets
    ssbWifiFirstEstablished
    cWifiEstablished
    cWifiEstablishFail
    ssbWifiEstablishFail
    ssbWifiLastEstablished
    ssbFirstHeardSiteMaster
    ssbConfigCompleted
    ssbRoughTimeSyncFirst
    unixMostRecentInitial
    unixFirstPTS
    unixMostRecentPTS
    unixLatestConfigRx
    cWaitsForInitialSync
    cPrecisionTimeSyncRx
    cPrecisionTimeSyncSent
    cSequentialPtsRx
    cMaxSequentialPtsRx
    cRecoveredGapInPTS
    cTagsInLMlan
    cQosTagsInLMlan
    cEpochsInTimingWheel
    cEpochsNotOperable
    totalTimeCorrection
    cAnchorRxConfigs
    cAnchorToAnchorInits
    cAnchorToAnchorInitGood
    cAnchorToAnchorTxpds
    updatedAt
  }
`;

export const anchorCountersUpdatesSubscription = gql`
  subscription AnchorCountersUpdates($anchorId: ID!) {
    anchorCountersUpdates(anchorId: $anchorId) ${AnchorCountersAttributes}
  }
`;

function useAnchorCountersUpdates(anchorId) {
  const [counters, setCounters] = useState();
  useSubscription(anchorCountersUpdatesSubscription, {
    skip: !anchorId,
    variables: {
      anchorId,
    },
    fetchPolicy: 'no-cache',
    onData: ({ client, data: result }) => {
      try {
        if (result && result.data && result.data.anchorCountersUpdates) {
          const update = result.data.anchorCountersUpdates;
          setCounters(update);
        }
      } catch (err) {
        console.error(err);
      }
    },
  });
  return counters;
}

export default useAnchorCountersUpdates;
