import { Home } from '@mui/icons-material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import GroupIcon from '@mui/icons-material/Group';
import GroupsIcon from '@mui/icons-material/Groups';
import MenuIcon from '@mui/icons-material/Menu';
import {
  Box,
  Divider,
  Drawer as NormalDrawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import { styled, useTheme } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import React, { useMemo, useState } from 'react';
import Helmet from 'react-helmet';
import { Link } from 'react-router-dom';
import AccountButton from './AccountButton';
import SelectCampusMenu from './SelectCampusMenu';
import DevicesIcon from '@mui/icons-material/Devices';
import ApartmentIcon from '@mui/icons-material/Apartment';
import SensorDoorIcon from '@mui/icons-material/SensorDoor';
import StorageIcon from '@mui/icons-material/Storage';
import MovieIcon from '@mui/icons-material/Movie';
import useProfile from '../shared/use-profile';
import TipsAndUpdatesOutlinedIcon from '@mui/icons-material/TipsAndUpdatesOutlined';

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme, open }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: open ? 'flex-end' : 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

const Pages = [
  {
    name: 'Home',
    icon: <Home />,
    path: '/home',
  },
  {
    name: 'Users',
    icon: <GroupIcon />,
    path: '/users',
    permissions: ['list-users:all'],
  },
  {
    name: 'Campuses',
    icon: <ApartmentIcon />,
    path: '/campuses',
    permissions: ['list-campuses:all'],
  },
  {
    name: 'Gateways',
    icon: <SensorDoorIcon />,
    path: '/gateways',
    permissions: ['list-gateways:all'],
  },
  {
    name: 'Devices',
    icon: <DevicesIcon />,
    path: '/devices/anchors',
    permissions: ['list-anchors:all'],
  },
  {
    name: 'Datasets',
    icon: <StorageIcon />,
    path: '/datasets',
    permissions: ['list-nerede-datasets:all'],
  },
  {
    name: 'Neural Networks',
    icon: <TipsAndUpdatesOutlinedIcon />,
    path: '/neural-nets',
    permissions: ['list-neural-nets:all'],
  },
  {
    name: 'Live Data',
    icon: <MovieIcon />,
    path: '/demo/ranges',
  },
];

function MyDrawer() {
  const profile = useProfile();
  const pages = useMemo(() => {
    const permissions = profile.role.permissions;
    return Pages.filter((page) => {
      if (!page.permissions) {
        return true;
      }
      let allowed = false;
      page.permissions.forEach((perm) => {
        if (permissions.includes(perm)) {
          allowed = true;
        }
      });
      return allowed;
    });
  }, [profile]);
  return (
    <div>
      <Divider />
      <List>
        {pages.map((page, index) => (
          <ListItem
            key={page.path}
            disablePadding
            component={Link}
            to={page.path}
            style={{ color: 'inherit' }}
          >
            <ListItemButton>
              <ListItemIcon>{page.icon}</ListItemIcon>
              <ListItemText primary={page.name} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </div>
  );
}

function MainPageTemplate({ children, title }) {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const theme = useTheme();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const handleDrawerOpen = () => {
    setOpen(!open);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Box component="nav">
        <NormalDrawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
            },
          }}
        >
          <MyDrawer />
        </NormalDrawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
          }}
          open={open}
        >
          <DrawerHeader theme={theme} open={open}>
            {open ? (
              <IconButton onClick={handleDrawerOpen}>
                <ChevronLeftIcon />
              </IconButton>
            ) : (
              <IconButton onClick={handleDrawerOpen}>
                <MenuIcon />
              </IconButton>
            )}
          </DrawerHeader>
          <MyDrawer />
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerToggle}
            edge="start"
            sx={{
              marginRight: { xs: 2, sm: 0 },
              ...(open && { display: 'none' }),
              display: { xs: 'block', sm: 'none' },
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h5" component="div" sx={{ flexGrow: 1 }}>
            Theora 360
          </Typography>
          <SelectCampusMenu />
          <AccountButton />
        </Toolbar>
        <Box sx={{ pl: 3, pr: 3, pb: 5 }}>{children}</Box>
      </Box>
      <style jsx>{`
        .logo {
          filter: drop-shadow(0px 4px 1px #aaaaaa);
        }
      `}</style>
    </Box>
  );
}

export default MainPageTemplate;
