import { gql, useSubscription } from '@apollo/client';
import { useDispatch } from 'react-redux';
import {
  createDatasetAction,
  deleteDatasetAction,
  updateDatasetAction,
} from '../../redux-store/dataset-store';
import { NeredeDatasetAttributes } from './constants';

export const DatasetUpdateAttributes = gql`
{
  type
  crud
  id
  new ${NeredeDatasetAttributes}
}
`;

export const neredeDatasetUpdatesSubscription = gql`
  subscription NeredeDatasetUpdates($campusId: ID!) {
    neredeDatasetUpdates(campusId: $campusId) ${DatasetUpdateAttributes}
  }
`;

function useDatasetUpdates(campusId) {
  const queryId = campusId || 'default';
  useSubscription(neredeDatasetUpdatesSubscription, {
    skip: !campusId,
    variables: {
      campusId,
    },
    fetchPolicy: 'no-cache',
    onData: ({ client, data: result }) => {
      try {
        if (result && result.data && result.data.neredeDatasetUpdates) {
          const updates = result.data.neredeDatasetUpdates;
          updates.forEach((update) => {
            switch (update.type) {
              case 'NEREDE_DATASET_CRUD': {
                switch (update.crud) {
                  case 'CREATE':
                    dispatch(createDatasetAction(update.new, queryId));
                    break;
                  case 'READ':
                    dispatch(updateDatasetAction(update.new, queryId));
                    break;
                  case 'UPDATE':
                    dispatch(updateDatasetAction(update.new, queryId));
                    break;
                  case 'DELETE':
                    dispatch(deleteDatasetAction(update.id, queryId));
                    break;
                  default:
                    break;
                }
                break;
              }
              default:
                break;
            }
          });
        }
      } catch (err) {
        console.error(err);
      }
    },
  });
  const dispatch = useDispatch();
}

export default useDatasetUpdates;
