import { Button, Typography } from '@mui/material';
import useLocale from '@theora360/shared/src/shared/use-locale';
import { formatDuration } from '@theora360/shared/src/shared/utils';
import React, { useMemo, useState } from 'react';
import InfoBox from '../../../components/InfoBox';
import RequestFirmwareStatusButton from './RequestFirmwareStatusButton';
import useSiteMasterFirmwareStatusUpdates from './use-site-master-firmware-status';
import SendConfigSystemFeaturesCmdModal from './SendConfigSystemFeaturesCmdModal';

function SiteMasterFirmwareStatusBox({ _id }) {
  const status = useSiteMasterFirmwareStatusUpdates(_id);
  const locale = useLocale();

  const info1 = useMemo(() => {
    const dateFormat = new Intl.DateTimeFormat(locale, {
      dateStyle: 'short',
      timeStyle: 'medium',
    });
    if (status) {
      return [
        {
          label: 'Build No.',
          desc: status.buildNumber,
        },
        {
          label: 'Wifi Build',
          desc: status.wifiBuildTextString,
        },
        status.anchorsInManifest != null && {
          label: '# of Anchors',
          desc: status.anchorsInManifest,
        },
        status.maxAnchorCount != null && {
          label: 'Max Anchors',
          desc: status.maxAnchorCount,
        },
        status.timeSinceStart != null && {
          label: 'Up Time',
          desc: formatDuration(status.timeSinceStart),
        },
        status.wifiSignalStrength && {
          label: 'Wifi Signal Strength',
          desc: `-${status.wifiSignalStrength} dB`,
        },
        status.anchorTcpEnable !== undefined && {
          label: 'TCP is Enabled',
          desc: status.anchorTcpEnable ? 'True' : 'False',
        },
        status.anchorStdout !== undefined && {
          label: 'Anchor Stdout is Enabled',
          desc: status.anchorStdout ? 'True' : 'False',
        },
        status.vt320stream !== undefined && {
          label: 'Site Master VT320 is Enabled',
          desc: status.vt320stream ? 'True' : 'False',
        },
        {
          label: 'Updated At',
          desc: dateFormat.format(new Date(status.updatedAt)),
        },
      ].filter((i) => i);
    }
    return [];
  }, [status, locale]);

  const [editing, setEditing] = useState();
  const onCancelSystemFeatures = () => setEditing(undefined);
  const onSystemFeatures = () => setEditing({ data: { _id, ...status } });
  const onFinishSystemFeatures = () => setEditing(undefined);
  return (
    <>
      <div>
        <RequestFirmwareStatusButton _id={_id} />
        {status && (
          <Button
            variant="outlined"
            sx={{ ml: 2, mb: 2 }}
            onClick={onSystemFeatures}
          >
            Configure System Features
          </Button>
        )}
      </div>
      {status && <InfoBox items={info1} sx={{ mb: 3 }} />}
      <SendConfigSystemFeaturesCmdModal
        visible={editing}
        onFinish={onFinishSystemFeatures}
        onCancel={onCancelSystemFeatures}
      />
    </>
  );
}

export default SiteMasterFirmwareStatusBox;
