import { LoadingButton } from '@mui/lab';
import { Alert, Box, Paper, Snackbar, Typography } from '@mui/material';
import React, { useCallback, useState } from 'react';
import { FormContainer, TextFieldElement } from 'react-hook-form-mui';
import MainPageTemplate from '../../components/MainPageTemplate';
import { gql, useApolloClient, useMutation } from '@apollo/client';
import { useDispatch } from 'react-redux';
import { ProfileAttributes } from '../auth/constants';
import useProfile from '../../shared/use-profile';
import ProfilePhotoFieldElement from '../../components/ProfilePhotoFieldElement';
import { formatPhoneNumber } from '@theora360/shared/src/shared/utils';

const editProfileMutation = gql`
  mutation EditProfile($user: EditProfileInput!) {
    editProfile(user: $user) {
      user ${ProfileAttributes}
    }
  }
`;

function EditProfilePage() {
  const [loading, setLoading] = useState(false);
  const [showMessage, setShowMessage] = useState();
  const [editProfile] = useMutation(editProfileMutation);
  const dispatch = useDispatch();
  const profile = useProfile();
  const onSubmit = useCallback(
    async (_values) => {
      setLoading(true);
      try {
        const { phone: unformattedPhone, ...values } = _values;
        const formattedPhone = formatPhoneNumber(unformattedPhone);
        const resp1 = await editProfile({
          variables: {
            user: {
              _id: profile._id,
              ...values,
              phone: formattedPhone,
            },
          },
        });
        setShowMessage({
          severity: 'success',
          message: 'Profile saved!',
        });
      } catch (err) {
        console.error(err);
        setShowMessage({
          severity: 'error',
          message: 'There was an error saving your profile',
        });
      }
      setLoading(false);
    },
    [editProfile, profile],
  );
  return (
    <MainPageTemplate title="Edit Profile">
      <Typography variant="h2" gutterBottom>
        My Profile
      </Typography>
      <Paper elevation={1} sx={{ width: 400, p: 2, pt: 3, pb: 3 }}>
        <FormContainer
          defaultValues={{
            email: profile.email,
            name: profile.name,
            phone: profile.phone,
            address: {
              address1: profile.address && profile.address.address1,
              address2: profile.address && profile.address.address2,
              city: profile.address && profile.address.city,
              state: profile.address && profile.address.state,
              zipCode: profile.address && profile.address.zipCode,
              country: profile.address && profile.address.country,
            },
          }}
          onSuccess={onSubmit}
        >
          <ProfilePhotoFieldElement
            name="profilePhotoId"
            onSubmit={onSubmit}
            profile={profile}
            fileType="profile-pic"
            tags={['profile-pic']}
          />
          <TextFieldElement
            style={{ width: 300, marginBottom: 32, width: '100%' }}
            variant="standard"
            name="name"
            label="Name"
            type="text"
            disabled={loading}
            required
          />
          <TextFieldElement
            style={{ width: 300, marginBottom: 32, width: '100%' }}
            variant="standard"
            name="email"
            label="Email"
            type="email"
            disabled={loading}
            required
          />
          <TextFieldElement
            style={{ width: 300, marginBottom: 32, width: '100%' }}
            variant="standard"
            name="phone"
            label="Phone"
            type="tel"
            disabled={loading}
            required
          />
          <div>
            <Typography variant="h5">Address</Typography>
            <TextFieldElement
              style={{ width: 300, marginBottom: 32, width: '100%' }}
              variant="standard"
              name="address.address1"
              label="Address Line 1"
              type="text"
              disabled={loading}
              required
            />
            <TextFieldElement
              style={{ width: 300, marginBottom: 32, width: '100%' }}
              variant="standard"
              name="address.address2"
              label="Address Line 2"
              type="text"
              disabled={loading}
            />
            <TextFieldElement
              style={{ width: 300, marginBottom: 32, width: '100%' }}
              variant="standard"
              name="address.city"
              label="City"
              type="text"
              disabled={loading}
              required
            />
            <TextFieldElement
              style={{ width: 300, marginBottom: 32, width: '100%' }}
              variant="standard"
              name="address.state"
              label="State"
              type="text"
              disabled={loading}
              required
            />
            <TextFieldElement
              style={{ width: 300, marginBottom: 32, width: '100%' }}
              variant="standard"
              name="address.zipCode"
              label="Zip Code"
              type="text"
              disabled={loading}
              required
            />
            <TextFieldElement
              style={{ width: 300, marginBottom: 32, width: '100%' }}
              variant="standard"
              name="address.country"
              label="Country"
              type="text"
              disabled={loading}
            />
          </div>
          <Box style={{ mt: 1 }}>
            <LoadingButton type="submit" variant="contained" loading={loading}>
              Save Profile
            </LoadingButton>
          </Box>
        </FormContainer>
      </Paper>
      <Snackbar
        open={!!showMessage}
        autoHideDuration={6000}
        onClose={() => setShowMessage(undefined)}
      >
        <Alert
          onClose={() => setShowMessage(undefined)}
          severity={showMessage && showMessage.severity}
          sx={{ width: '100%' }}
        >
          {showMessage && showMessage.message}
        </Alert>
      </Snackbar>
    </MainPageTemplate>
  );
}

export default EditProfilePage;
