import { gql, useSubscription } from '@apollo/client';
import { useEffect, useRef } from 'react';
import { BehaviorSubject, debounceTime } from 'rxjs';

export const AiTagLocationAttributes = gql`
  {
    _id
    campusId
    tagId
    epochTimestamp
    label
    anchorIds
    distances
    updatedAt
  }
`;

export const aiTagLocationUpdatesSubscription = gql`
  subscription AiTagLocationUpdates($campusId: ID!) {
    aiTagLocationUpdates(campusId: $campusId) ${AiTagLocationAttributes}
  }
`;

function useAiTagLocationUpdates(campusId, setUpdate) {
  const _tagLocations = useRef({});
  const term$ = useRef();
  useEffect(() => {
    term$.current = new BehaviorSubject([]);
    term$.current.pipe(debounceTime(500)).subscribe((term) => {
      console.log('pipe');
      setUpdate(term);
    });
  }, [setUpdate]);
  useSubscription(aiTagLocationUpdatesSubscription, {
    skip: !campusId,
    variables: {
      campusId,
    },
    fetchPolicy: 'no-cache',
    onData: ({ client, data: result }) => {
      try {
        if (result && result.data && result.data.aiTagLocationUpdates) {
          const updates = result.data.aiTagLocationUpdates;
          updates.forEach((update) => {
            _tagLocations.current[update.tagId] = update;
          });
          term$.current?.next(
            Object.values(_tagLocations.current).sort((a, b) =>
              a.tagId.localeCompare(b.tagId),
            ),
          );
        }
      } catch (err) {
        console.error(err);
      }
    },
  });
}

export default useAiTagLocationUpdates;
