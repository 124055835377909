import { gql } from '@apollo/client';
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import useLocale from '@theora360/shared/src/shared/use-locale';
import React, { useMemo, useState } from 'react';
import InfoBox from '../../../components/InfoBox';
import RequestAnchorConfigButton from './RequestAnchorConfigButton';
import useAnchorConfigurationUpdates from './use-anchor-config-updates';
import WriteAnchorConfigurationModal from './WriteAnchorConfigurationModal';
import EditIcon from '@mui/icons-material/Edit';

const dense = false;

function AnchorConfigurationBox({ _id }) {
  const status = useAnchorConfigurationUpdates(_id);
  const locale = useLocale();

  const info1 = useMemo(() => {
    const dateFormat = new Intl.DateTimeFormat(locale, {
      dateStyle: 'short',
      timeStyle: 'medium',
    });
    if (status) {
      return [
        {
          label: 'Message No.',
          desc: status.messageNo,
        },
        {
          label: 'Command Error',
          desc: status.commandError,
        },
        {
          label: 'Response Error',
          desc: status.responseError,
        },
        status.logicalAddress != null && {
          label: 'Logical Address',
          desc: status.logicalAddress,
        },
        status.anchorId != null && {
          label: 'Anchor ID',
          desc: status.anchorId,
        },
        status.timeDistribution && {
          label: 'Time Distribution',
          desc: status.timeDistribution,
        },
        {
          label: 'Updated At',
          desc: dateFormat.format(new Date(status.updatedAt)),
        },
      ].filter((i) => i);
    }
    return [];
  }, [status, locale]);
  const data = useMemo(() => {
    if (status) {
      return ['A', 'B', 'C', 'D', 'E', 'F'].map((i) => ({
        _id: i,
        lanNumber: status[`lanNumber${i}`],
        rangingOrder: status[`rangingOrder${i}`],
      }));
    }
    return [];
  }, [status]);

  const [editing, setEditing] = useState();
  const onCancelEditConfig = () => setEditing(undefined);
  const onEditConfig = () => setEditing({ data: status });
  const onFinishEditConfig = () => setEditing(undefined);
  return (
    <>
      <RequestAnchorConfigButton _id={_id} />
      {status && (
        <>
          <InfoBox items={info1} sx={{ mb: 3 }} />
          <Typography variant="h6" sx={{ mb: 1 }}>
            LAN Assignments
          </Typography>
          <Button
            startIcon={<EditIcon />}
            variant="contained"
            sx={{ mb: 2 }}
            onClick={onEditConfig}
          >
            Edit LAN Assignments
          </Button>
          <Paper>
            <TableContainer>
              <Table sx={{ minWidth: 650 }} size={dense ? 'small' : undefined}>
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Slot</TableCell>
                    <TableCell align="left">Lan Number</TableCell>
                    <TableCell align="left">Ranging Order</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.map((row) => (
                    <TableRow
                      key={row._id}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {row._id}
                      </TableCell>
                      <TableCell align="left">{row.lanNumber}</TableCell>
                      <TableCell align="left">{row.rangingOrder}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </>
      )}
      <WriteAnchorConfigurationModal
        visible={editing}
        onFinish={onFinishEditConfig}
        onCancel={onCancelEditConfig}
      />
    </>
  );
}

export default AnchorConfigurationBox;
