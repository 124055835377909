import { SelectElement, TextFieldElement } from 'react-hook-form-mui';
import React, { useRef } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { MenuItem } from '@mui/material';
import { useManyRemote } from '@theora360/shared/src/shared/use-many-remote';
import settings from '../../../settings';
import { gql } from '@apollo/client';

const RoleAttributes = gql`
  {
    _id
    name
    desc
    permissions
    createdBy
    updatedBy
    createdAt
    updatedAt
    deletedBy
    deletedAt
  }
`;

const allRolesQuery = gql`
  query AllRoles($first: Int, $after: String, $filters: RoleFilters, $sortBy: [SortBy]) {
    allRoles(first: $first, after: $after, filters: $filters, sortBy: $sortBy) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node ${RoleAttributes}
      }
    }
  }
`;

export const extractRoles = (data) => data && data.allRoles;
const queryId = 'list';

function SelectRoleElement({
  variant,
  name,
  label,
  disabled,
  required,
  style,
}) {
  const filters = useRef({});
  const sortBy = useRef([{ key: 'updatedAt', order: 'DESC' }]);
  const {
    error,
    loading,
    data,
    search: dataSearch,
    hasNextPage,
    next,
    refetch,
    reset,
  } = useManyRemote({
    query: allRolesQuery,
    extract: extractRoles,
    first: settings.querySize,
    filters: filters.current,
    sortBy: sortBy.current,
    fetchPolicy: 'network-only',
    queryId: queryId,
  });
  return (
    <TextFieldElement
      style={style}
      variant={variant}
      name={name}
      label={label}
      type="text"
      disabled={disabled}
      select
    >
      {data.map((option) => (
        <MenuItem key={option._id} value={option._id}>
          {option.name}
        </MenuItem>
      ))}
    </TextFieldElement>
  );
}

export default SelectRoleElement;
