import { useCallback, useMemo, useRef } from 'react';
import { anchorColors } from './constants';

function useRangeTraces(tagRangeMatrix, anchorIds) {
  const data = useRef({});
  const zero = useRef(null);
  const rangeTraces = useMemo(() => {
    const allTraces = [];
    tagRangeMatrix.forEach((updates) => {
      let tagId;
      updates.forEach((update) => {
        if (update) {
          tagId = update.tagId;
          if (!data.current[tagId]) {
            data.current[tagId] = {};
          }
          if (!data.current[tagId][update.anchorId]) {
            data.current[tagId][update.anchorId] = {
              zero: 0,
              x: [],
              y: [],
              y2: [],
              lastTimestamp: undefined,
              name: `Anchor ${update.anchorId}`,
            };
          }
          const timestamp = update.timestamp / 1000;
          if (
            data.current[tagId][update.anchorId].lastTimestamp !== timestamp
          ) {
            data.current[tagId][update.anchorId].y.push(update.rawDistance);
            data.current[tagId][update.anchorId].y2.push(update.distance);
            if (zero.current === null) {
              zero.current = timestamp;
            }
            data.current[tagId][update.anchorId].zero = zero.current;
            data.current[tagId][update.anchorId].x.push(
              timestamp - zero.current,
            );
            data.current[tagId][update.anchorId].lastTimestamp = timestamp;
          }
        }
      });

      const traces = anchorIds
        .map(
          (anchorId, index) =>
            data.current[tagId][anchorId] && {
              x: data.current[tagId][anchorId].x,
              y: data.current[tagId][anchorId].y,
              name: `Anchor ${anchorId}`,
              // text: anchorLocs.map((a) => a.label),
              mode: 'lines+markers',
              color: anchorColors[index % anchorColors.length],
              type: 'scatter',
            },
        )
        .filter((a) => a);
      traces.push(
        ...anchorIds
          .map(
            (anchorId, index) =>
              data.current[tagId][anchorId] && {
                x: data.current[tagId][anchorId].x,
                y: data.current[tagId][anchorId].y2,
                name: `Anchor ${anchorId} filtered`,
                // text: anchorLocs.map((a) => a.label),
                mode: 'lines+markers',
                dash: 'dot',
                color: anchorColors[index % anchorColors.length],
                type: 'scatter',
              },
          )
          .filter((a) => a),
      );
      traces.sort((a, b) => a.name.localeCompare(b.name));
      allTraces.push(traces);
    });

    return allTraces;
  }, [anchorIds, tagRangeMatrix]);

  const reset = useCallback(() => {
    data.current = {};
    zero.current = null;
  }, []);

  return [rangeTraces, reset, data.current];
}

export default useRangeTraces;
