import useLocale from '@theora360/shared/src/shared/use-locale';
import React, { useMemo } from 'react';
import InfoBox from '../../../components/InfoBox';
import useTagHealthDataUpdates from './use-tag-health-data';

function TagHealthDataBox({ _id }) {
  const status = useTagHealthDataUpdates(_id);
  const locale = useLocale();

  const info1 = useMemo(() => {
    const dateFormat = new Intl.DateTimeFormat(locale, {
      dateStyle: 'short',
      timeStyle: 'medium',
    });
    if (status) {
      return [
        {
          label: 'LAN No.',
          desc: status.lanNo,
        },
        {
          label: 'Serial No.',
          desc: status.serialNo,
        },
        status.batteryMV != null && {
          label: 'Battery mV',
          desc: `${status.batteryMV} mV`,
        },
        status.runMins != null && {
          label: 'Run Minutes',
          desc: status.runMins,
        },
        status.rangeEpoch16 != null && {
          label: 'Range Epoch 16',
          desc: status.rangeEpoch16,
        },
        status.vibrateCap != null && {
          label: 'Vibrate Capacitor',
          desc: status.vibrateCap,
        },
        status.buttonRoll != null && {
          label: 'Button Roll',
          desc: status.buttonRoll,
        },
        {
          label: 'Gyro',
          desc: `${status.gx}, ${status.gy}, ${status.gz}`,
        },
        {
          label: 'Accel (min/max)',
          desc: `${status.minAccel} / ${status.maxAccel}`,
        },
        {
          label: 'Updated At',
          desc: dateFormat.format(new Date(status.updatedAt)),
        },
      ].filter((i) => i);
    }
    return [];
  }, [status, locale]);
  return <>{status && <InfoBox items={info1} sx={{ mb: 3 }} />}</>;
}

export default TagHealthDataBox;
