import { gql, useMutation } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import { Box, Modal, Typography } from '@mui/material';
import { showMessageAction } from '@theora360/shared/src/redux-store/snackbar-store';
import {
  formatDuration,
  generateUuid,
} from '@theora360/shared/src/shared/utils';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormContainer, TextFieldElement } from 'react-hook-form-mui';
import { useDispatch } from 'react-redux';
import TagsElement from '../../../components/TagsElement';

const updateNeredeAnnotationMutation = gql`
  mutation UpdateNeredeAnnotation($annotation: NeredeAnnotationUpdateInput!) {
    updateNeredeAnnotation(annotation: $annotation) {
      annotation {
        _id
      }
    }
  }
`;

const createNeredeAnnotationMutation = gql`
  mutation CreateNeredeAnnotation($annotation: NeredeAnnotationCreateInput!) {
    createNeredeAnnotation(annotation: $annotation) {
      annotation {
        _id
      }
    }
  }
`;

function SaveAnnotationModal({ visible, onCancel, onFinish }) {
  const { data, campusId, tagId, datasetId, startTimestampMs, endTimestampMs } =
    visible || {};
  const [loading, setLoading] = useState(false);
  const [updateNeredeAnnotation] = useMutation(updateNeredeAnnotationMutation);
  const [createNeredeAnnotation] = useMutation(createNeredeAnnotationMutation);
  const form = useForm();

  useEffect(() => {
    if (data) {
      form.setValue('label', data.label);
      form.setValue('desc', data.desc);
      form.setValue('tags', data.tags);
      form.setValue(
        'tags',
        data.tags.map((t) => ({ title: t })),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const duration = useMemo(() => {
    if (endTimestampMs && startTimestampMs) {
      return (endTimestampMs - startTimestampMs) / 1000;
    }
    return undefined;
  }, [startTimestampMs, endTimestampMs]);

  const dispatch = useDispatch();
  const onSubmit = useCallback(
    async (_values) => {
      const { tags: _tags, ...values } = _values;
      const tags = (_tags || []).map((t) => t.title);
      setLoading(true);
      try {
        if (data) {
          await updateNeredeAnnotation({
            variables: {
              annotation: {
                _id: data._id,
                tags,
                ...values,
              },
            },
          });
        } else {
          await createNeredeAnnotation({
            variables: {
              annotation: {
                _id: generateUuid(),
                campusId,
                tagId,
                datasetId,
                startTimestampMs,
                endTimestampMs,
                duration,
                tags,
                ...values,
              },
            },
          });
        }

        dispatch(
          showMessageAction({
            _id: 'edit-annotation',
            severity: 'success',
            message: 'Annotation saved!',
          }),
        );
        onFinish();
      } catch (err) {
        console.error(err);
        dispatch(
          showMessageAction({
            _id: 'edit-annotation',
            severity: 'error',
            message: 'There was an error saving annotation',
          }),
        );
      }
      setLoading(false);
    },
    [
      updateNeredeAnnotation,
      createNeredeAnnotation,
      data,
      dispatch,
      onFinish,
      campusId,
      tagId,
      datasetId,
      startTimestampMs,
      endTimestampMs,
      duration,
    ],
  );

  return (
    <Modal
      open={!!visible}
      onClose={onCancel}
      sx={{
        overflow: 'scroll',
        p: 4,
      }}
    >
      <Box
        sx={{
          backgroundColor: 'white',
          textAlign: 'center',
          borderRadius: 1,
          padding: 4,
          marginTop: 4,
          marginBottom: 4,
          width: 500,
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
      >
        <Typography variant="h4" sx={{ mb: 3 }}>
          {data ? 'Edit Annotation' : 'Create Annotation'}
        </Typography>
        {duration && (
          <>
            <Typography variant="h6">Duration</Typography>
            <Typography>{formatDuration(duration)}</Typography>
          </>
        )}
        <FormContainer formContext={form} onSuccess={onSubmit}>
          <TextFieldElement
            style={{ marginBottom: 32, width: '100%' }}
            variant="standard"
            name="label"
            label="Label"
            helperText="This is the label used for AI model training.  Should be the same for all annotations associated with a specific thing/location."
            type="text"
            disabled={loading}
            required
          />
          <TextFieldElement
            style={{ marginBottom: 32, width: '100%' }}
            variant="standard"
            name="desc"
            label="Description"
            type="text"
            disabled={loading}
          />
          <TagsElement
            style={{ marginBottom: 32, width: '100%' }}
            variant="standard"
            name="tags"
            label="Tags"
            disabled={loading}
            form={form}
          />
          <div style={{ height: 16 }} />
          <Box>
            <LoadingButton type="submit" variant="contained" loading={loading}>
              Save
            </LoadingButton>
          </Box>
        </FormContainer>
      </Box>
    </Modal>
  );
}

export default SaveAnnotationModal;
