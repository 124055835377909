import { Alert, Snackbar } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { hideMessageAction } from '../redux-store/snackbar-store';

function MySnackbar() {
  const messages = useSelector((store) =>
    Object.values(store.snackbar.default).filter((v) => v),
  );
  const dispatch = useDispatch();
  const [msg, setMsg] = useState();
  useEffect(() => {
    if (!msg && messages.length > 0) {
      setMsg(messages[0]);
    }
  }, [msg, messages]);
  const onClose = () => {
    if (msg) {
      dispatch(hideMessageAction(msg._id));
      setMsg(undefined);
    }
  };
  return (
    <Snackbar
      open={!!msg}
      autoHideDuration={6000}
      onClose={onClose}
      ClickAwayListenerProps={{ onClickAway: () => null }}
    >
      <Alert
        onClose={onClose}
        severity={msg && msg.severity}
        sx={{ width: '100%' }}
      >
        {msg && msg.message}
      </Alert>
    </Snackbar>
  );
}

export default MySnackbar;
