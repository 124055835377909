import { gql, useSubscription } from '@apollo/client';
import { useEffect, useRef, useState } from 'react';
import { BehaviorSubject, debounce, debounceTime, interval, of } from 'rxjs';

export const AnchorRangeAttributes = gql`
  {
    _id
    campusId
    anchorId
    tagId
    rawDistance
    distance
    variance
    kalmanFilter
    timestamp
    lanNo
    updatedAt
  }
`;

export const anchorRangeUpdatesSubscription = gql`
  subscription AnchorRangeUpdates($campusId: ID!) {
    anchorRangeUpdates(campusId: $campusId) ${AnchorRangeAttributes}
  }
`;

function useAnchorRangeUpdates(campusId, setUpdate) {
  const _ranges = useRef({});
  const _tags = useRef({});
  const _anchors = useRef({});
  const term$ = useRef();
  useEffect(() => {
    term$.current = new BehaviorSubject(_ranges.current);
    // term$.current.pipe(debounceTime(500)).subscribe((term) => {
    term$.current.subscribe((term) => {
      setUpdate({
        ranges: term,
        anchorIds: Object.keys(_anchors.current).sort(),
        tagIds: Object.keys(_tags.current).sort(),
      });
    });
  }, [setUpdate]);
  useSubscription(anchorRangeUpdatesSubscription, {
    skip: !campusId,
    variables: {
      campusId,
    },
    fetchPolicy: 'no-cache',
    onData: ({ client, data: result }) => {
      try {
        if (result && result.data && result.data.anchorRangeUpdates) {
          const update = result.data.anchorRangeUpdates;
          _tags.current[update.tagId] = true;
          _anchors.current[update.anchorId] = true;
          _ranges.current = {
            ..._ranges.current,
            [update.tagId]: {
              ..._ranges.current[update.tagId],
              [update.anchorId]: update,
            },
          };
          term$.current?.next(_ranges.current);
        }
      } catch (err) {
        console.error(err);
      }
    },
  });
}

export default useAnchorRangeUpdates;
