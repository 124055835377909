import { SelectElement } from 'react-hook-form-mui';
import { shallowEqual, useSelector } from 'react-redux';
import useSelectedCampusId from '../../../shared/use-selected-campus-id';
import { useManyRemote } from '@theora360/shared/src/shared/use-many-remote';
import { allNeuralNetsQuery, extractNeuralNets } from './NeuralNetsPage';
import settings from '../../../settings';
import { useRef } from 'react';

const queryId = 'select';

function SelectNeuralNetFieldElement({ saving }) {
  const campusId = useSelectedCampusId();
  const filters = useRef({
    campusId,
  });
  const sortBy = useRef([{ key: 'updatedAt', order: 'DESC' }]);
  const {
    error,
    loading,
    data: neuralNets,
    search: dataSearch,
    hasNextPage,
    next,
    refetch,
    reset,
  } = useManyRemote({
    query: allNeuralNetsQuery,
    extract: extractNeuralNets,
    first: settings.querySize,
    filters: filters.current,
    sortBy: sortBy.current,
    fetchPolicy: 'network-only',
    queryId: queryId,
  });
  return (
    <SelectElement
      label="Neural Network"
      name="netId"
      options={neuralNets.map((c) => ({ id: c._id, label: c.name }))}
      variant="standard"
      style={{ marginBottom: 32, width: '100%', textAlign: 'left' }}
      disabled={saving || loading}
      required
    />
  );
}

export default SelectNeuralNetFieldElement;
