import { Card, Toolbar, Typography } from '@mui/material';
import React from 'react';
import logo from '../assets/logo512.png';
import settings from '../settings';
import useProfile from '../shared/use-profile';
import AccountButton from './AccountButton';

function AuthPageTemplate({ children }) {
  const profile = useProfile();
  return (
    <>
      <Toolbar>
        <div style={{ marginRight: 'auto' }} />
        {profile && <AccountButton />}
      </Toolbar>
      <div className="body">
        <Card style={{ padding: '32px 64px' }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                background: settings.colors.blue,
                borderRadius: '50%',
                height: 180,
                width: 180,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginBottom: 8,
              }}
            >
              <img style={{ width: 180 }} src={logo} alt="logos" />
            </div>
          </div>
          <Typography
            variant="h1"
            gutterBottom
            sx={{
              fontSize: 56,
              top: 0,
              position: 'relative',
              mt: 0,
              mb: 4,
              textAlign: 'center',
            }}
          >
            Theora 360
          </Typography>
          {children}
        </Card>
      </div>
      <style jsx>{`
        .body {
          display: flex;
          justify-content: center;
          align-items: center;
          min-height: 100vh;
          padding-top: 64px;
          padding-bottom: 64px;
        }
      `}</style>
    </>
  );
}

export default AuthPageTemplate;
