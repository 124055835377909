import { gql, useSubscription } from '@apollo/client';
import { useState } from 'react';

export const SiteMasterVT320Attributes = gql`
  {
    _id
    campusId
    timestamp
    bytes
    updatedAt
  }
`;

export const siteMasterVT320UpdatesSubscription = gql`
  subscription SiteMasterVT320Updates($siteMasterId: ID!) {
    siteMasterVT320Updates(siteMasterId: $siteMasterId) ${SiteMasterVT320Attributes}
  }
`;

function useSiteMasterVT320Updates(siteMasterId) {
  const [vt320, setVt320] = useState();
  useSubscription(siteMasterVT320UpdatesSubscription, {
    skip: !siteMasterId,
    variables: {
      siteMasterId,
    },
    fetchPolicy: 'no-cache',
    onData: ({ client, data: result }) => {
      try {
        if (result && result.data && result.data.siteMasterVT320Updates) {
          const update = result.data.siteMasterVT320Updates;
          setVt320(update);
        }
      } catch (err) {
        console.error(err);
      }
    },
  });
  return vt320;
}

export default useSiteMasterVT320Updates;
