import queryString from 'query-string';
import React from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router';
import useQueryParams from '../../shared/use-query-params';
import SignInPage from './SignInPage';

function AuthRouter() {
  const query = useQueryParams();
  const location = useLocation();
  const { c, ...params } = query;
  const search = `?${queryString.stringify({
    c,
    p: location.pathname,
    s: JSON.stringify(params),
  })}`;
  return (
    <Routes>
      <Route
        exact
        path="/"
        element={<Navigate to={`/sign-in${location.search}`} />}
      />
      <Route exact path="/sign-in" element={<SignInPage />} />
      {/* <Route exact path="/sign-up" element={<SignUpPage />} />
      <Route exact path="/complete-sign-up" element={<CompleteSignUpPage />} />
      <Route exact path="/complete-sign-in" element={<CompleteSignInPage />} /> */}
      <Route path="*" element={<Navigate to={`/sign-in${search}`} />} />
    </Routes>
  );
}

export default AuthRouter;
