export const SELECT_CAMPUS = 'SELECT_CAMPUS';

export const selectCampusAction = (campusId) => ({
  type: SELECT_CAMPUS,
  payload: {
    campusId,
  },
});

export function selectedCampusId(state = null, action) {
  switch (action.type) {
    case SELECT_CAMPUS: {
      return action.payload.campusId;
    }
    default:
      return state;
  }
}
