import { useMemo, useRef } from 'react';
import Fuse from 'fuse.js';
import { shallowEqual, useSelector } from 'react-redux';
import useGatewayUpdates from '@theora360/shared/src/pages/gateways/use-gateway-updates';

const searchOptions = {
  includeScore: true,
  keys: ['label', 'desc'],
};

function defaultSort(a, b) {
  if (a && a.updatedAt && b && b.updatedAt) {
    return new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime();
  }
  if (a && a.updatedAt) {
    return 1;
  }
  if (b && b.updatedAt) {
    return -1;
  }
  return 0;
}

function useFilterGateways({ campusId, filters, sortBy }) {
  const queryId = campusId || 'default';
  const data = useSelector(
    (store) =>
      (store.gateways[queryId] && Object.values(store.gateways[queryId])) || [],
    shallowEqual,
  );
  const lastResult = useRef();
  const jsonFilters = JSON.stringify(filters);
  const filtered = useMemo(() => {
    let _data = data;
    if (filters.search) {
      const fuse = new Fuse(_data, searchOptions);
      _data = fuse
        .search(filters.search)
        .sort((a, b) => a.score - b.score)
        .map((i) => i.item);
      lastResult.current = _data;
    } else {
      lastResult.current = _data;
    }
    if (sortBy) {
      return lastResult.current.sort(sortBy);
    }
    return lastResult.current.sort(defaultSort);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jsonFilters, data, sortBy]);
  return filtered;
}

export default useFilterGateways;
