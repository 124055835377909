import { gql, useSubscription } from '@apollo/client';
import { useState } from 'react';

export const AnchorLastAttributes = gql`
  {
    _id
    campusId
    sequenceNo
    numPacketsIn1Min
    numPacketsIn1Hr
    numPacketsIn1Day
    numDropPacketsIn1Min
    numDropPacketsIn1Hr
    numDropPacketsIn1Day
    lastAnchorPost
    ipSourceType
    updatedAt
  }
`;

export const anchorLastUpdatesSubscription = gql`
  subscription AnchorLastUpdates($anchorId: ID!) {
    anchorLastUpdates(anchorId: $anchorId) ${AnchorLastAttributes}
  }
`;

function useAnchorLastUpdates(anchorId) {
  const [last, setLast] = useState();
  useSubscription(anchorLastUpdatesSubscription, {
    skip: !anchorId,
    variables: {
      anchorId,
    },
    fetchPolicy: 'no-cache',
    onData: ({ client, data: result }) => {
      try {
        if (result && result.data && result.data.anchorLastUpdates) {
          const update = result.data.anchorLastUpdates;
          setLast(update);
        }
      } catch (err) {
        console.error(err);
      }
    },
  });
  return last;
}

export default useAnchorLastUpdates;
