import { gql } from '@apollo/client';
import {
  Avatar,
  Box,
  Button,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { FormContainer, TextFieldElement } from 'react-hook-form-mui';
import MainPageTemplate from '../../../components/MainPageTemplate';
import settings from '../../../settings';
import useLocale from '@theora360/shared/src/shared/use-locale';
import { useManyRemote } from '@theora360/shared/src/shared/use-many-remote';
import EditIcon from '@mui/icons-material/Edit';
import { Link } from 'react-router-dom';
import { UserAttributes } from './constants';
import GroupIcon from '@mui/icons-material/GroupOutlined';
import { generateUuid } from '@theora360/shared/src/shared/utils';
import AddIcon from '@mui/icons-material/Add';

const dense = false;

export const allUsersQuery = gql`
  query AllUsers($first: Int, $after: String, $filters: UserFilters, $sortBy: [SortBy]) {
    allUsers(first: $first, after: $after, filters: $filters, sortBy: $sortBy) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node ${UserAttributes}
      }
    }
  }
`;

export const extractUsers = (data) => data && data.allUsers;
const queryId = 'list';

function UsersPage() {
  const filters = useRef({});
  const sortBy = useRef([{ key: 'updatedAt', order: 'DESC' }]);
  const {
    error,
    loading,
    data,
    search: dataSearch,
    hasNextPage,
    next,
    refetch,
    reset,
  } = useManyRemote({
    query: allUsersQuery,
    extract: extractUsers,
    first: settings.querySize,
    filters: filters.current,
    sortBy: sortBy.current,
    fetchPolicy: 'network-only',
    queryId: queryId,
  });

  const locale = useLocale();
  const dateFormat = useMemo(() => {
    return new Intl.DateTimeFormat(locale, {
      dateStyle: 'short',
      timeStyle: 'medium',
    });
  }, [locale]);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(settings.pageSize);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  const form = useForm();
  const searchField = form.watch('search', '');
  const onFiltersChange = useEffect(() => {
    dataSearch(searchField);
  }, [dataSearch, searchField]);

  return (
    <MainPageTemplate title="Users">
      <Typography variant="h2" gutterBottom>
        <GroupIcon style={{ height: 40, width: 40 }} /> Users
      </Typography>
      <Box sx={{ mb: 2, display: 'flex', alignItems: 'center' }}>
        <FormContainer formContext={form}>
          <TextFieldElement
            style={{ width: 300, marginBottom: 16 }}
            variant="standard"
            name="search"
            label="Search"
            type="text"
            onChange={(v) => onFiltersChange({ search: v })}
          />
        </FormContainer>
        <Button
          component={Link}
          variant="contained"
          to={`/users/edit/${generateUuid()}`}
          startIcon={<AddIcon />}
          sx={{ ml: 'auto' }}
        >
          New User
        </Button>
      </Box>
      <Paper>
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Photo</TableCell>
                <TableCell align="left">Name</TableCell>
                <TableCell align="left">Role</TableCell>
                <TableCell align="left">Email</TableCell>
                <TableCell align="left">Phone</TableCell>
                <TableCell align="left">Updated At</TableCell>
                <TableCell align="left">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row) => (
                <TableRow
                  key={row.name}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    <Avatar
                      sx={{
                        backgroundColor: settings.colors.theoraMiddleBlue,
                        width: 40,
                        height: 40,
                      }}
                      src={row.primaryThumbnailUrl}
                      alt="Logo"
                    >
                      {row.name && row.name[0]}
                    </Avatar>
                  </TableCell>
                  <TableCell align="left">{row.name}</TableCell>
                  <TableCell align="left">
                    {row.role && row.role.name}
                  </TableCell>
                  <TableCell align="left">{row.email}</TableCell>
                  <TableCell align="left">{row.phone}</TableCell>
                  <TableCell align="left">
                    {dateFormat.format(new Date(row.updatedAt))}
                  </TableCell>
                  <TableCell align="right" width={60}>
                    <IconButton component={Link} to={`/users/edit/${row._id}`}>
                      <EditIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[settings.pageSize, settings.largePage]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </MainPageTemplate>
  );
}

export default UsersPage;
