import { LoadingButton } from '@mui/lab';
import { Box, Button, Modal, Typography } from '@mui/material';
import React, { useCallback, useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { showMessageAction } from '@theora360/shared/src/redux-store/snackbar-store';
import { useDispatch } from 'react-redux';
import StopIcon from '@mui/icons-material/Stop';
import useSelectedCampusId from '../../../shared/use-selected-campus-id';

const stopNeredeDatasetMutation = gql`
  mutation StopNeredeDataset($campusId: ID!) {
    stopNeredeDataset(campusId: $campusId)
  }
`;

function StopRecordingDatasetModal() {
  const campusId = useSelectedCampusId();
  const [deleting, setDeleting] = useState(false);
  const [loading, setLoading] = useState(false);
  const onCancel = () => setDeleting(false);
  const onDelete = () => setDeleting(true);

  const [stopNeredeDataset] = useMutation(stopNeredeDatasetMutation);
  const dispatch = useDispatch();
  const onSubmit = useCallback(async () => {
    setLoading(true);
    try {
      await stopNeredeDataset({
        variables: {
          campusId,
        },
      });
      dispatch(
        showMessageAction({
          _id: 'stop-recording',
          severity: 'success',
          message: 'Dataset recording stopped!',
        }),
      );
    } catch (err) {
      console.error(err);
      dispatch(
        showMessageAction({
          _id: 'stop-recording',
          severity: 'error',
          message: 'There was an error stopping recording',
        }),
      );
      setLoading(false);
    }
  }, [stopNeredeDataset, campusId, dispatch]);
  return (
    <>
      <Modal
        open={deleting}
        onClose={onCancel}
        sx={{
          overflow: 'scroll',
          p: 4,
        }}
      >
        <Box
          sx={{
            backgroundColor: 'white',
            textAlign: 'center',
            borderRadius: 1,
            padding: 4,
            marginTop: 4,
            marginBottom: 4,
            width: 500,
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        >
          <Typography variant="h4" sx={{ mb: 3 }}>
            Stop Recording
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            Are you sure you want to stop this dataset?
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Button sx={{ mr: 4 }} onClick={onCancel}>
              Cancel
            </Button>
            <LoadingButton
              variant="contained"
              loading={loading}
              onClick={onSubmit}
            >
              Stop
            </LoadingButton>
          </Box>
        </Box>
      </Modal>
      <Button startIcon={<StopIcon />} variant="contained" onClick={onDelete}>
        Stop Recording
      </Button>
    </>
  );
}

export default StopRecordingDatasetModal;
