import { Subject } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { tap, map } from 'rxjs/operators';
import { gql } from '@apollo/client';
import { toBase64 } from './utils';

export function doFileUpload({ url, fields, file, onProgress }) {
  const progressSubscriber = new Subject();
  const body = new FormData();
  Object.entries(fields).forEach(([k, v]) => {
    body.append(k, v);
  });
  body.append('Content-Type', file.type);
  body.append('Content-Disposition', `attachment; filename="${file.name}"`);
  body.append('file', file);
  const request$ = ajax({
    url,
    method: 'post',
    body,
    progressSubscriber,
  });
  progressSubscriber.subscribe((event) => onProgress(event));
  return request$
    .pipe(
      tap((response) => console.log(response)),
      map((response) => {
        return {
          ...response,
          text: async () => JSON.stringify(response.response),
        };
      }),
    )
    .toPromise();
}

export const getFileUploadUrlMutation = gql`
  mutation GetFileUploadUrl($request: GetFileUploadUrlRequest!) {
    getFileUploadUrl(request: $request) {
      url
      fields
    }
  }
`;

export const createFileMutation = gql`
  mutation CreateFile($file: CreateFileInput!) {
    createFile(file: $file) {
      file {
        _id
        desc
        s3Key
        size
        filename
        contentType
        tags
        url
        uploaded
        processed
        parentId
        createdBy
        updatedBy
        createdAt
        updatedAt
      }
    }
  }
`;

export async function doUpload({
  client,
  file,
  fileType,
  parentType,
  parentId,
  childId,
  tags,
  onProgress,
}) {
  const s3Key = `${parentType}_${parentId}/${file.name}`;

  let response;
  response = await client.mutate({
    mutation: getFileUploadUrlMutation,
    variables: {
      request: {
        _id: childId,
        s3Key,
        type: fileType,
        parentId,
      },
    },
  });
  if (!response || !response.data || !response.data.getFileUploadUrl) {
    throw new Error('Bad upload URL');
  }

  onProgress(0);
  await doFileUpload({
    url: response.data.getFileUploadUrl.url,
    fields: response.data.getFileUploadUrl.fields,
    file,
    onProgress: (event) => {
      console.log('progress', event);
      try {
        onProgress((event.loaded / event.total) * 100);
      } catch (err) {
        console.error(err);
      }
    },
  });
  setTimeout(() => {
    onProgress(undefined);
  }, 1000);

  response = await client.mutate({
    mutation: createFileMutation,
    variables: {
      file: {
        _id: childId,
        filename: file.name,
        s3Key,
        type: fileType,
        parentId,
        tags,
        uploaded: true,
      },
    },
  });
}
