import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles';
import React, { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import './App.less';
import AppLoading from './components/AppLoading';
import AuthRouter from './pages/auth/AuthRouter';
import MainRouter from './pages/main/MainRouter';
import settings from './settings';
import useAuth from './shared/use-auth';
import { useWindowDimensions } from './shared/window-dimensions';
import { useApolloClient } from '@apollo/client';

const lightTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: settings.colors.darkOrange,
    },
    secondary: {
      main: settings.colors.yellow,
    },
    error: {
      main: settings.colors.red,
    },
    background: {
      default: settings.colors.white,
    },
    subdued: {
      main: 'rgba(0, 0, 0, 0.87)',
    },
  },
  typography: {
    // fontFamily: 'ABeeZee, sans-serif',
    h1: {
      fontFamily: '"Audiowide", sans-serif',
      color: settings.colors.theoraNavyBlue,
      // fontWeight: 'bold',
      letterSpacing: 2,
    },
    h2: {
      fontFamily: '"Audiowide", sans-serif',
      color: settings.colors.theoraNavyBlue,
      // fontWeight: 'bold',
      letterSpacing: 2,
    },
    h3: {
      fontFamily: '"Audiowide", sans-serif',
      color: settings.colors.theoraNavyBlue,
      // fontWeight: 'bold',
      letterSpacing: 2,
    },
    h4: {
      fontFamily: '"Audiowide", sans-serif',
      color: settings.colors.theoraLightBlue,
      // fontWeight: 'bold',
      letterSpacing: 2,
    },
    h5: {
      fontFamily: '"Audiowide", sans-serif',
      color: settings.colors.theoraLightBlue,
      // fontWeight: 'bold',
      letterSpacing: 2,
    },
    h6: {
      fontFamily: '"Audiowide", sans-serif',
      color: settings.colors.theoraLightBlue,
      // fontWeight: 'bold',
      letterSpacing: 1,
    },
  },
});

function App() {
  useWindowDimensions();
  const { loading, profile } = useAuth();
  const client = useApolloClient();
  const theme = useTheme();
  console.log('theme', theme);
  return (
    <>
      <ThemeProvider theme={lightTheme}>
        <CssBaseline />
        {loading ? (
          <AppLoading />
        ) : (
          <Router>{profile ? <MainRouter /> : <AuthRouter />}</Router>
        )}
      </ThemeProvider>
      <style jsx>{``}</style>
    </>
  );
}

export default App;
