import { useMemo } from 'react';
import { anchorColors } from '../demo/constants';

function useDataAnchorLocTraces(datas) {
  return useMemo(() => {
    const allData = {};
    const allAnchorIds = {};
    const allTraces = [];
    let startTimestamp;
    if (datas) {
      datas.forEach((data) => {
        if (data.type === 'AL') {
          if (!startTimestamp) {
            startTimestamp = data.timestampMs;
          }
          allAnchorIds[data.anchorId] = true;
          if (!allData[data.anchorId]) {
            allData[data.anchorId] = {
              anchorId: data.anchorId,
              name: `Anchor ${data.anchorId}`,
              t: [],
              x: [],
              y: [],
              z: [],
            };
          }
          allData[data.anchorId].t.push(data.timestampMs - startTimestamp);
          allData[data.anchorId].x.push(data.anchorLoc[0]);
          allData[data.anchorId].y.push(data.anchorLoc[1]);
          allData[data.anchorId].z.push(data.anchorLoc[2]);
        }
      });
      const anchorIds = Object.keys(allAnchorIds).sort((a, b) =>
        a.localeCompare(b),
      );

      const traces = anchorIds.map((anchorId, index) => ({
        x: allData[anchorId].x,
        y: allData[anchorId].y,
        z: allData[anchorId].z,
        name: allData[anchorId].name,
        mode: 'lines+markers',
        color: anchorColors[index % anchorColors.length],
        type: 'scatter3d',
      }));
      allTraces.push({
        key: 'anchor-locs',
        title: 'Anchor Locations',
        traces,
      });
    }
    return allTraces;
  }, [datas]);
}

export default useDataAnchorLocTraces;
