export const READ_SITE_MASTERS = 'READ_SITE_MASTERS';
export const CREATE_SITE_MASTER = 'CREATE_SITE_MASTER';
export const UPDATE_SITE_MASTER = 'UPDATE_SITE_MASTER';
export const DELETE_SITE_MASTER = 'DELETE_SITE_MASTER';

// eslint-disable-next-line no-shadow
export const readSiteMastersAction = (siteMasters, queryId = 'default') => ({
  type: READ_SITE_MASTERS,
  payload: {
    siteMasters,
    queryId,
  },
});

export const createSiteMasterAction = (siteMaster, queryId = 'default') => ({
  type: CREATE_SITE_MASTER,
  payload: {
    siteMaster,
    queryId,
  },
});

export const updateSiteMasterAction = (siteMaster, queryId = 'default') => ({
  type: UPDATE_SITE_MASTER,
  payload: {
    siteMaster,
    queryId,
  },
});

export const deleteSiteMasterAction = (siteMasterId, queryId = 'default') => ({
  type: DELETE_SITE_MASTER,
  payload: {
    siteMasterId,
    queryId,
  },
});

const initialSiteMasters = { default: {} };

export function siteMasters(state = initialSiteMasters, action) {
  switch (action.type) {
    case READ_SITE_MASTERS: {
      const newState = {
        ...state,
        [action.payload.queryId]: { ...state[action.payload.queryId] },
      };
      action.payload.siteMasters.forEach((p) => {
        newState[action.payload.queryId][p._id] = p;
      });
      return newState;
    }
    case CREATE_SITE_MASTER:
      return {
        ...state,
        [action.payload.queryId]: {
          ...state[action.payload.queryId],
          [action.payload.siteMaster._id]: action.payload.siteMaster,
        },
      };
    case UPDATE_SITE_MASTER: {
      const current =
        (state[action.payload.queryId] &&
          state[action.payload.queryId][action.payload.siteMaster._id]) ||
        undefined;
      return {
        ...state,
        [action.payload.queryId]: {
          ...state[action.payload.queryId],
          [action.payload.siteMaster._id]: {
            ...current,
            ...action.payload.siteMaster,
          },
        },
      };
    }
    case DELETE_SITE_MASTER: {
      const { siteMasterId } = action.payload;
      return Object.entries(state).reduce((prev, [key, val]) => {
        prev[key] = {
          ...val,
          [siteMasterId]: undefined,
        };
        return prev;
      }, {});
    }
    default:
      return state;
  }
}
