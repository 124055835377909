import { gql, useSubscription } from '@apollo/client';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  createTagAction,
  deleteTagAction,
  updateTagAction,
} from '../../redux-store/tag-store';

export const TagAttributes = gql`
  {
    _id
    label
    desc
    campusId
    gatewayId
    createdAt
    updatedAt
    deletedAt
  }
`;

export const TagUpdateAttributes = gql`
{
  type
  crud
  id
  new ${TagAttributes}
}
`;

export const tagUpdatesSubscription = gql`
  subscription TagUpdates($campusId: ID!) {
    tagUpdates(campusId: $campusId) ${TagUpdateAttributes}
  }
`;

function useTagUpdates(campusId) {
  const queryId = campusId || 'default';
  useSubscription(tagUpdatesSubscription, {
    skip: !campusId,
    variables: {
      campusId,
    },
    fetchPolicy: 'no-cache',
    onData: ({ client, data: result }) => {
      try {
        if (result && result.data && result.data.tagUpdates) {
          const updates = result.data.tagUpdates;
          updates.forEach((update) => {
            switch (update.type) {
              case 'TAG_CRUD': {
                switch (update.crud) {
                  case 'CREATE':
                    dispatch(createTagAction(update.new, queryId));
                    break;
                  case 'READ':
                    dispatch(updateTagAction(update.new, queryId));
                    break;
                  case 'UPDATE':
                    dispatch(updateTagAction(update.new, queryId));
                    break;
                  case 'DELETE':
                    dispatch(deleteTagAction(update.id, queryId));
                    break;
                  default:
                    break;
                }
                break;
              }
              default:
                break;
            }
          });
        }
      } catch (err) {
        console.error(err);
      }
    },
  });
  const dispatch = useDispatch();
}

export default useTagUpdates;
