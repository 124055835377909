import { gql, useMutation } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import { Box, Modal, Typography } from '@mui/material';
import { showMessageAction } from '@theora360/shared/src/redux-store/snackbar-store';
import {
  formatDuration,
  generateUuid,
} from '@theora360/shared/src/shared/utils';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormContainer, TextFieldElement } from 'react-hook-form-mui';
import { useDispatch } from 'react-redux';
import TagsElement from '../../../components/TagsElement';
import SelectNeuralNetFieldElement from '../neural-nets/SelectNeuralNetFieldElement';

const updateNeredeAnnotationMutation = gql`
  mutation AddAnnotationToNeuralNet($annotation: NeredeAnnotationUpdateInput!) {
    updateNeredeAnnotation(annotation: $annotation) {
      annotation {
        _id
      }
    }
  }
`;

function onlyUnique(value, index, array) {
  return array.indexOf(value) === index;
}

function AddAnnotationToNeuralNetModal({ visible, onCancel, onFinish }) {
  const { data } = visible || {};
  const [loading, setLoading] = useState(false);
  const [updateNeredeAnnotation] = useMutation(updateNeredeAnnotationMutation);
  const form = useForm();

  const dispatch = useDispatch();
  const onSubmit = useCallback(
    async (_values) => {
      const { netId } = _values;
      setLoading(true);
      try {
        const netIds = (data.netIds || [])
          .concat([netId])
          .filter(onlyUnique)
          .sort();
        await updateNeredeAnnotation({
          variables: {
            annotation: {
              _id: data._id,
              netIds: netIds,
            },
          },
        });
        dispatch(
          showMessageAction({
            _id: 'edit-annotation',
            severity: 'success',
            message: 'Annotation added to neural network!',
          }),
        );
        onFinish();
      } catch (err) {
        console.error(err);
        dispatch(
          showMessageAction({
            _id: 'edit-annotation',
            severity: 'error',
            message: 'There was an error saving annotation',
          }),
        );
      }
      setLoading(false);
    },
    [updateNeredeAnnotation, data, dispatch, onFinish],
  );

  return (
    <Modal
      open={!!visible}
      onClose={onCancel}
      sx={{
        overflow: 'scroll',
        p: 4,
      }}
    >
      <Box
        sx={{
          backgroundColor: 'white',
          textAlign: 'center',
          borderRadius: 1,
          padding: 4,
          marginTop: 4,
          marginBottom: 4,
          width: 500,
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
      >
        <Typography variant="h4" sx={{ mb: 3 }}>
          Add to Neural Network
        </Typography>
        <Typography variant="h6">Label</Typography>
        <Typography>{data?.label}</Typography>
        <Typography variant="h6">Description</Typography>
        <Typography sx={{ mb: 2 }}>{data?.desc}</Typography>
        <Typography sx={{ mb: 2 }}>Select a neural network below</Typography>
        <FormContainer formContext={form} onSuccess={onSubmit}>
          <SelectNeuralNetFieldElement saving={loading} />
          <div style={{ height: 16 }} />
          <Box>
            <LoadingButton type="submit" variant="contained" loading={loading}>
              Save
            </LoadingButton>
          </Box>
        </FormContainer>
      </Box>
    </Modal>
  );
}

export default AddAnnotationToNeuralNetModal;
