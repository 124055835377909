export const READ_GATEWAYS = 'READ_GATEWAYS';
export const CREATE_GATEWAY = 'CREATE_GATEWAY';
export const UPDATE_GATEWAY = 'UPDATE_GATEWAY';
export const DELETE_GATEWAY = 'DELETE_GATEWAY';

// eslint-disable-next-line no-shadow
export const readGatewaysAction = (gateways, queryId = 'default') => ({
  type: READ_GATEWAYS,
  payload: {
    gateways,
    queryId,
  },
});

export const createGatewayAction = (gateway, queryId = 'default') => ({
  type: CREATE_GATEWAY,
  payload: {
    gateway,
    queryId,
  },
});

export const updateGatewayAction = (gateway, queryId = 'default') => ({
  type: UPDATE_GATEWAY,
  payload: {
    gateway,
    queryId,
  },
});

export const deleteGatewayAction = (gatewayId, queryId = 'default') => ({
  type: DELETE_GATEWAY,
  payload: {
    gatewayId,
    queryId,
  },
});

const initialGateways = { default: {} };

export function gateways(state = initialGateways, action) {
  switch (action.type) {
    case READ_GATEWAYS: {
      const newState = {
        ...state,
        [action.payload.queryId]: { ...state[action.payload.queryId] },
      };
      action.payload.gateways.forEach((p) => {
        newState[action.payload.queryId][p._id] = p;
      });
      return newState;
    }
    case CREATE_GATEWAY:
      return {
        ...state,
        [action.payload.queryId]: {
          ...state[action.payload.queryId],
          [action.payload.gateway._id]: action.payload.gateway,
        },
      };
    case UPDATE_GATEWAY: {
      const current =
        (state[action.payload.queryId] &&
          state[action.payload.queryId][action.payload.gateway._id]) ||
        undefined;
      return {
        ...state,
        [action.payload.queryId]: {
          ...state[action.payload.queryId],
          [action.payload.gateway._id]: {
            ...current,
            ...action.payload.gateway,
          },
        },
      };
    }
    case DELETE_GATEWAY: {
      const { gatewayId } = action.payload;
      return Object.entries(state).reduce((prev, [key, val]) => {
        prev[key] = {
          ...val,
          [gatewayId]: undefined,
        };
        return prev;
      }, {});
    }
    default:
      return state;
  }
}
