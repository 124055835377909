import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import React from 'react';

function TagXYZTable({ tagLocations }) {
  return (
    <>
      <Paper sx={{ mb: 2 }}>
        <TableContainer>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell align="left">Tag</TableCell>
                <TableCell align="left">X</TableCell>
                <TableCell align="left">Y</TableCell>
                <TableCell align="left">Z</TableCell>
                <TableCell align="left">+/-</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tagLocations.map((tagLoc, index) => (
                <TableRow
                  key={tagLoc._id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {`Tag (${tagLoc._id})`}
                  </TableCell>
                  <TableCell align="left">
                    <Typography sx={{ fontSize: 18 }}>
                      {`${tagLoc.xyzPos[0].toFixed(3)}m`}
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography sx={{ fontSize: 18 }}>
                      {`${tagLoc.xyzPos[1].toFixed(3)}m`}
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography sx={{ fontSize: 18 }}>
                      {`${tagLoc.xyzPos[2].toFixed(3)}m`}
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography sx={{ fontSize: 18 }}>
                      {`${Math.sqrt(tagLoc.xyzVar[0]).toFixed(3)}m`}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
}

export default TagXYZTable;
