import StraightenIcon from '@mui/icons-material/Straighten';
import {
  Box,
  Checkbox,
  FormControlLabel,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { formatDuration } from '@theora360/shared/src/shared/utils';
import React, { useMemo, useState } from 'react';
import RangesPlot from './RangesPlot';
import { makeDistanceParts } from './utils';

function makeDistance(update, show) {
  const [distanceM, distanceMVar] = makeDistanceParts(update.rawDistance);
  const now = new Date().getTime() / 1000;
  const elapsed = now - new Date(update.updatedAt).getTime() / 1000;
  return [
    show.ticks && `${update.rawDistance.toFixed(2)}`,
    show.ticks && show.meters && '//',
    show.meters && `${distanceM.toFixed(2)}${show.variance ? '' : 'm'}`,
    show.variance && `+/- ${Math.sqrt(Math.abs(distanceMVar)).toFixed(2)}m`,
    show.time && `(${formatDuration(elapsed)} ago)`,
  ]
    .filter((a) => a)
    .join(' ');
}

let renders = 0;

const RangesTab = React.memo(function RangesTab({
  tagIds,
  anchorIds,
  tagRangeMatrix,
  allRangeTraces,
  latestTagRangeTimestamp,
}) {
  console.log('rangesRenders', renders++);
  const [show, setShow] = useState({
    ticks: false,
    meters: true,
    variance: false,
    time: true,
  });

  return (
    <>
      <Typography variant="h5" sx={{ mt: 0, mb: 2 }}>
        <StraightenIcon style={{ height: 40, width: 40 }} /> Ranges
      </Typography>
      <Box sx={{ mb: 2 }}>
        <FormControlLabel
          label="Show ticks"
          control={
            <Checkbox
              checked={show.ticks}
              onChange={(event) =>
                setShow({ ...show, ticks: event.target.checked })
              }
            />
          }
        />
        <FormControlLabel
          label="Show meters"
          control={
            <Checkbox
              checked={show.meters}
              onChange={(event) =>
                setShow({ ...show, meters: event.target.checked })
              }
            />
          }
        />
        <FormControlLabel
          label="Show variance"
          control={
            <Checkbox
              checked={show.variance}
              onChange={(event) =>
                setShow({ ...show, variance: event.target.checked })
              }
            />
          }
        />
        <FormControlLabel
          label="Show time"
          control={
            <Checkbox
              checked={show.time}
              onChange={(event) =>
                setShow({ ...show, time: event.target.checked })
              }
            />
          }
        />
      </Box>
      <Paper>
        <TableContainer>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell align="left">Tag</TableCell>
                {anchorIds.map((anchorId) => (
                  <TableCell key={anchorId} align="left">
                    {`Anchor (${anchorId})`}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {tagRangeMatrix.map((row, index) => (
                <TableRow
                  key={tagIds[index]}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {`Tag (${tagIds[index]})`}
                  </TableCell>
                  {row.map((update, index2) => {
                    if (!update) {
                      return null;
                    }
                    return (
                      <TableCell key={anchorIds[index2]} align="left">
                        {update && (
                          <Typography sx={{ fontSize: 18 }}>
                            {makeDistance(update, show)}
                          </Typography>
                        )}
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      {allRangeTraces.map((rangeTraces, index) => (
        <RangesPlot
          key={tagIds[index]}
          title={`Tag ${tagIds[index]}`}
          rangeTraces={rangeTraces}
          prefix={tagIds[index]}
        />
      ))}
    </>
  );
});

export default RangesTab;
