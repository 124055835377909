import { gql, useSubscription } from '@apollo/client';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  createGatewayAction,
  deleteGatewayAction,
  updateGatewayAction,
} from '../../redux-store/gateway-store';

export const GatewayAttributes = gql`
  {
    _id
    label
    desc
    campusId
    createdAt
    updatedAt
    deletedAt
  }
`;

export const GatewayUpdateAttributes = gql`
{
  type
  crud
  id
  new ${GatewayAttributes}
}
`;

export const gatewayUpdatesSubscription = gql`
  subscription GatewayUpdates($campusId: ID) {
    gatewayUpdates(campusId: $campusId) ${GatewayUpdateAttributes}
  }
`;

function useGatewayUpdates(campusId, adminMode = false) {
  const queryId = campusId || 'default';
  useSubscription(gatewayUpdatesSubscription, {
    skip: !adminMode && !campusId,
    variables: {
      campusId,
    },
    fetchPolicy: 'no-cache',
    onData: ({ client, data: result }) => {
      try {
        if (result && result.data && result.data.gatewayUpdates) {
          const updates = result.data.gatewayUpdates;
          updates.forEach((update) => {
            switch (update.type) {
              case 'GATEWAY_CRUD': {
                switch (update.crud) {
                  case 'CREATE':
                    dispatch(createGatewayAction(update.new, queryId));
                    break;
                  case 'READ':
                    dispatch(updateGatewayAction(update.new, queryId));
                    break;
                  case 'UPDATE':
                    dispatch(updateGatewayAction(update.new, queryId));
                    break;
                  case 'DELETE':
                    dispatch(deleteGatewayAction(update.id, queryId));
                    break;
                  default:
                    break;
                }
                break;
              }
              default:
                break;
            }
          });
        }
      } catch (err) {
        console.error(err);
      }
    },
  });
  const dispatch = useDispatch();
}

export default useGatewayUpdates;
