import { gql, useMutation } from '@apollo/client';
import SendIcon from '@mui/icons-material/Send';
import { Button, CircularProgress } from '@mui/material';
import { showMessageAction } from '@theora360/shared/src/redux-store/snackbar-store';
import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

const requestSiteMasterHaltMutation = gql`
  mutation RequestSiteMasterHalt($_id: ID!) {
    requestSiteMasterHalt(_id: $_id)
  }
`;

function RequestSiteMasterHaltButton({ _id }) {
  const [loading, setLoading] = useState(false);
  const [requestSiteMasterHalt] = useMutation(requestSiteMasterHaltMutation);

  const dispatch = useDispatch();
  const onSubmit = useCallback(async () => {
    setLoading(true);
    try {
      await requestSiteMasterHalt({
        variables: {
          _id,
        },
      });
      dispatch(
        showMessageAction({
          _id: 'req-site-master-halt',
          severity: 'success',
          message: 'Request sent!',
        }),
      );
    } catch (err) {
      console.error(err);
      dispatch(
        showMessageAction({
          _id: 'req-site-master-halt',
          severity: 'error',
          message: 'There was an error sending request.',
        }),
      );
    }
    setLoading(false);
  }, [_id, dispatch, requestSiteMasterHalt]);

  return (
    <Button
      startIcon={
        loading ? <CircularProgress color="inherit" size={16} /> : <SendIcon />
      }
      disabled={loading}
      variant="outlined"
      color="subdued"
      sx={{ mr: 2 }}
      onClick={onSubmit}
    >
      Request Site Master Reset
    </Button>
  );
}

export default RequestSiteMasterHaltButton;
