import StraightenIcon from '@mui/icons-material/Straighten';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import React, { useMemo, useRef } from 'react';
import useSelectedCampusId from '../../../shared/use-selected-campus-id';
import useAnchorNeighborRanges from '../anchors/use-anchor-neighbor-ranges';
import useLocale from '@theora360/shared/src/shared/use-locale';
import AccessAlarmOutlinedIcon from '@mui/icons-material/AccessAlarmOutlined';
import SquareFootOutlinedIcon from '@mui/icons-material/SquareFootOutlined';
import SensorsIcon from '@mui/icons-material/Sensors';

const distanceRatio = {
  mean: 0.002269542079673832,
  variance: 1.8131280016860944e-8,
};

function makeDistance(update, locale) {
  const distanceM = distanceRatio.mean * update.distance;
  const distanceMVar =
    distanceRatio.variance * update.distance * update.distance;
  return `${update.distance.toFixed(2)} // ${distanceM.toFixed(
    2,
  )} +/- ${Math.sqrt(Math.abs(distanceMVar)).toFixed(2)}m`;
}

function makeTime(update, locale) {
  const dateFormat = new Intl.DateTimeFormat(locale, {
    dateStyle: 'short',
    timeStyle: 'short',
  });
  return `${dateFormat.format(new Date(update.updatedAt))}`;
}

function makeActualDistance(update, locale) {
  const distance = update.realDistanceM / distanceRatio.mean;
  return `${distance.toFixed(2)} // ${update.realDistanceM.toFixed(2)}m`;
}

function NeighborToNeighborTable({ makeFunc, mat, anchorIds, neighborIds }) {
  const locale = useLocale();
  return (
    <Paper sx={{ mb: 4 }}>
      <TableContainer>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <TableCell align="left">Neighbor</TableCell>
              {anchorIds.map((anchorId) => (
                <TableCell key={anchorId} align="left">
                  {`Anchor (${anchorId})`}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {mat.map((row, index) => (
              <TableRow
                key={neighborIds[index]}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {`Neighbor (${neighborIds[index]})`}
                </TableCell>
                {row.map((update, index2) => (
                  <TableCell key={anchorIds[index2]} align="left">
                    {update && (
                      <Typography sx={{ fontSize: 18 }}>
                        {makeFunc(update, locale)}
                      </Typography>
                    )}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}

function NeighborRangesTab({ refreshes }) {
  const campusId = useSelectedCampusId();
  const { ranges, neighborIds, anchorIds } = useAnchorNeighborRanges(campusId);
  const locale = useLocale();
  const mat = useMemo(() => {
    const _mat = [];
    neighborIds.forEach(() => {
      _mat.push(Array(anchorIds.length).fill(undefined));
    });
    Object.entries(ranges).forEach(([neighborId, anchors]) => {
      Object.entries(anchors).forEach(([anchorId, update]) => {
        _mat[neighborIds.indexOf(update.neighborId)][
          anchorIds.indexOf(update.anchorId)
        ] = update;
      });
    });
    return _mat;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshes, ranges, neighborIds, anchorIds]);

  return (
    <>
      <Typography variant="h5" sx={{ mt: 0, mb: 2 }}>
        <SensorsIcon style={{ height: 40, width: 40 }} /> Neighbor Ranges
      </Typography>
      <NeighborToNeighborTable
        makeFunc={makeDistance}
        mat={mat}
        anchorIds={anchorIds}
        neighborIds={neighborIds}
      />
      <Typography variant="h5" sx={{ mt: 0, mb: 2 }}>
        <SquareFootOutlinedIcon style={{ height: 40, width: 40 }} /> Actual
        Measured Distances
      </Typography>
      <NeighborToNeighborTable
        makeFunc={makeActualDistance}
        mat={mat}
        anchorIds={anchorIds}
        neighborIds={neighborIds}
      />
      <Typography variant="h5" sx={{ mt: 0, mb: 2 }}>
        <AccessAlarmOutlinedIcon style={{ height: 40, width: 40 }} /> Neighbor
        Ranges Update Times
      </Typography>
      <NeighborToNeighborTable
        makeFunc={makeTime}
        mat={mat}
        anchorIds={anchorIds}
        neighborIds={neighborIds}
      />
    </>
  );
}

export default NeighborRangesTab;
