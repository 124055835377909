import { gql, useMutation } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import { Box, Modal, TextField, Typography } from '@mui/material';
import { GatewayAttributes } from '@theora360/shared/src/pages/gateways/use-gateway-updates';
import { showMessageAction } from '@theora360/shared/src/redux-store/snackbar-store';
import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { FormContainer, TextFieldElement } from 'react-hook-form-mui';
import { useDispatch } from 'react-redux';
import SelectCampusElement from '../campuses/SelectCampusElement';

const updateGatewayMutation = gql`
  mutation UpdateGateway($gateway: GatewayUpdateInput!) {
    updateGateway(gateway: $gateway) {
      gateway ${GatewayAttributes}
    }
  }
`;

const createGatewayMutation = gql`
  mutation CreateGateway($gateway: GatewayCreateInput!) {
    createGateway(gateway: $gateway) {
      gateway ${GatewayAttributes}
    }
  }
`;

export const gatewayQuery = gql`
  query Gateway($_id: ID!) {
    gateway(_id: $_id) ${GatewayAttributes}
  }
`;

function EditGatewayModal({ visible, onCancel, onFinish }) {
  const { data } = visible || {};
  const [loading, setLoading] = useState(false);
  const [updateGateway] = useMutation(updateGatewayMutation);
  const [createGateway] = useMutation(createGatewayMutation);
  const form = useForm();

  useEffect(() => {
    if (data) {
      form.setValue('label', data.label);
      form.setValue('desc', data.desc);
      form.setValue('campusId', data.campusId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const dispatch = useDispatch();
  const onSubmit = useCallback(
    async (_values) => {
      const { ...values } = _values;
      setLoading(true);
      try {
        const resp1 = await updateGateway({
          variables: {
            gateway: {
              _id: data._id,
              ...values,
            },
          },
        });
        dispatch(
          showMessageAction({
            _id: 'edit-gateway',
            severity: 'success',
            message: 'Gateway saved!',
          }),
        );
        onFinish();
      } catch (err) {
        console.error(err);
        dispatch(
          showMessageAction({
            _id: 'edit-gateway',
            severity: 'error',
            message: 'There was an error saving gateway',
          }),
        );
      }
      setLoading(false);
    },
    [updateGateway, data, dispatch, onFinish],
  );

  return (
    <Modal
      open={!!visible}
      onClose={onCancel}
      sx={{
        overflow: 'scroll',
        p: 4,
      }}
    >
      <Box
        sx={{
          backgroundColor: 'white',
          textAlign: 'center',
          borderRadius: 1,
          padding: 4,
          marginTop: 4,
          marginBottom: 4,
          width: 500,
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
      >
        <Typography variant="h4" sx={{ mb: 3 }}>
          Edit Gateway
        </Typography>
        <FormContainer formContext={form} onSuccess={onSubmit}>
          <TextFieldElement
            style={{ width: 300, marginBottom: 32, width: '100%' }}
            variant="standard"
            name="label"
            label="Label"
            type="text"
            disabled={loading}
            required
          />
          <TextFieldElement
            style={{ marginBottom: 32, width: '100%' }}
            variant="standard"
            name="desc"
            label="Description"
            type="text"
            disabled={loading}
          />
          <SelectCampusElement
            style={{
              width: 300,
              marginBottom: 32,
              width: '100%',
              textAlign: 'left',
            }}
            variant="standard"
            name="campusId"
            label="Campus"
            disabled={loading}
          />
          <div style={{ height: 16 }} />
          <Box>
            <LoadingButton type="submit" variant="contained" loading={loading}>
              Save Gateway
            </LoadingButton>
          </Box>
        </FormContainer>
      </Box>
    </Modal>
  );
}

export default EditGatewayModal;
