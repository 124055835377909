import { gql } from '@apollo/client';

export const UserAttributes = gql`
  {
    _id
    username
    roleId
    role {
      _id
      name
    }
    email
    emailVerified
    name
    phone
    primaryThumbnailUrl
    tenantId
    lastSignedInAt
    createdBy
    updatedBy
    createdAt
    updatedAt
    deletedBy
    deletedAt
  }
`;

export const userQuery = gql`
  query User($_id: ID!) {
    user(_id: $_id) ${UserAttributes}
  }
`;
