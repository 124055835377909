import { useMemo, useRef } from 'react';

function useTagRangeMatrix(ranges, tagIds, anchorIds) {
  const now = useRef(0);
  const mat = useMemo(() => {
    const _mat = [];
    tagIds.forEach(() => {
      _mat.push(Array(anchorIds.length).fill(undefined));
    });
    Object.entries(ranges).forEach(([tagId, anchors]) => {
      Object.entries(anchors).forEach(([anchorId, update]) => {
        _mat[tagIds.indexOf(update.tagId)][anchorIds.indexOf(update.anchorId)] =
          update;
        const timestamp = update.timestamp;
        if (timestamp > now.current) {
          now.current = timestamp;
        }
      });
    });
    return _mat;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ranges, tagIds, anchorIds]);
  return [mat, now.current];
}

export default useTagRangeMatrix;
