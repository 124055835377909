import React from 'react';
import useSelectedCampusId from '../../../shared/use-selected-campus-id';
import useFilterAnchors from '../anchors/use-filter-anchors';
import TagLocRangeTable from './TagLocRangeTable';
import TagLocationPlot from './TagLocationPlot';

function TagLocationTab({ tagLocations, tag3dLocationTraces }) {
  const campusId = useSelectedCampusId();
  const anchors = useFilterAnchors({
    campusId,
    filters: {},
  });
  return (
    <>
      <TagLocRangeTable tagLocations={tagLocations} anchors={anchors} />
      <TagLocationPlot
        tagLocations={tagLocations}
        tag3dLocationTraces={tag3dLocationTraces}
      />
    </>
  );
}

export default TagLocationTab;
