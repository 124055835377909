import ScatterPlotIcon from '@mui/icons-material/ScatterPlot';
import { Paper, Typography } from '@mui/material';
import Plotly from 'plotly.js-dist';
import React, { useEffect, useRef } from 'react';

function TrainingLogsPlot({
  prefix = 'net-train',
  height = 600,
  title,
  trainTraces,
}) {
  const firstPlot = useRef();
  useEffect(() => {
    if (trainTraces.length === firstPlot.current) {
      const plotData = {
        x: [...trainTraces.map((s) => s.x)],
        y: [...trainTraces.map((s) => s.y)],
        z: [...trainTraces.map((s) => s.z)],
        text: [...trainTraces.map((s) => s.text)],
        'marker.color': [...trainTraces.map((s) => s.color)],
        'marker.line.color': [...trainTraces.map((s) => s.color)],
        'line.dash': [...trainTraces.map((s) => s.dash)],
      };
      Plotly.update(`${prefix}_myPlot`, plotData);
    } else {
      const plotData = [...trainTraces];
      var layout = {
        uirevision: true,
      };
      Plotly.newPlot(`${prefix}_myPlot`, plotData, layout);
      firstPlot.current = trainTraces.length;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trainTraces]);

  return (
    <>
      <Typography variant="h5" sx={{ mt: 3, mb: 2 }}>
        <ScatterPlotIcon style={{ height: 40, width: 40 }} /> {title}
      </Typography>
      <Paper sx={{ p: 2 }}>
        <div id={`${prefix}_myPlot`} className="plot" style={{ height }} />
      </Paper>
    </>
  );
}

export default TrainingLogsPlot;
