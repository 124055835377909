import { gql } from '@apollo/client';

export const NeuralNetTypes = {
  TRILAT_V1: {
    key: 'TRILAT_V1',
    label: 'Trilateration V1',
    desc: 'Use single epoch of anchor->tag ranges to guess location in system.  Train with labeled sets of ranges.',
  },
};

export const NeuralNetAttributes = gql`
  {
    _id
    campusId
    name
    type
    desc
    tags
    createdBy
    updatedBy
    createdAt
    updatedAt
    deletedBy
    deletedAt
  }
`;

export const NeuralNetTrainingStatuses = {
  PREPARING: {
    key: 'PREPARING',
    label: 'Preparing',
  },
  TRAINING: {
    key: 'TRAINING',
    label: 'Training',
  },
  FINISHING: {
    key: 'FINISHING',
    label: 'Finishing',
  },
  STOPPED: {
    key: 'STOPPED',
    label: 'Stopped',
  },
};

export const SavedModelAttributes = gql`
  {
    _id
    campusId
    name
    fileId
    netId
    notes
    tags
    updatedAt
  }
`;
