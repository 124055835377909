import { Base64 } from 'js-base64';
import React, { useEffect, useRef } from 'react';
import useSiteMasterVT320Updates from './use-site-master-vt320-updates';
// import { XTerm } from 'xterm-for-react';
import { gql, useMutation } from '@apollo/client';
import { showMessageAction } from '@theora360/shared/src/redux-store/snackbar-store';
import { useDispatch } from 'react-redux';
import { Terminal } from 'xterm';
import 'xterm/css/xterm.css';

const sendVT320KeyboardTrafficCmdMutation = gql`
  mutation SendVT320KeyboardTrafficCmd($_id: ID!, $traffic: String!) {
    sendVT320KeyboardTrafficCmd(_id: $_id, traffic: $traffic)
  }
`;

function SiteMasterVT320Term({ _id }) {
  const vt320 = useSiteMasterVT320Updates(_id);
  const [sendVT320KeyboardTrafficCmd] = useMutation(
    sendVT320KeyboardTrafficCmdMutation,
  );
  // const [count, setCount, _count] = useSafeState(0);
  const last = useRef();
  const container = useRef();
  const xtermRef = useRef();
  const once = useRef(true);
  const dispatch = useDispatch();
  useEffect(() => {
    if (once.current) {
      once.current = false;
      xtermRef.current = new Terminal({
        rows: 34,
        cols: 80,
      });
      xtermRef.current.open(container.current);
      xtermRef.current.onBinary((e) => {
        console.log('onBinary', e);
      });
      xtermRef.current.onData(async (e) => {
        // console.log('onData', e, e.length);
        try {
          await sendVT320KeyboardTrafficCmd({
            variables: {
              _id,
              traffic: e,
            },
          });
        } catch (err) {
          console.error(err);
          dispatch(
            showMessageAction({
              _id: 'vt320-traffic',
              severity: 'error',
              message: 'There was an error sending vt320 traffic',
            }),
          );
        }
      });
    }
  }, [_id, dispatch, sendVT320KeyboardTrafficCmd]);
  useEffect(() => {
    if (vt320 && vt320.timestamp !== last.current && xtermRef.current) {
      last.current = vt320.timestamp;
      xtermRef.current.write(Base64.atob(vt320.bytes));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vt320]);

  return (
    <>
      <div ref={container} />
    </>
  );
}

export default React.memo(SiteMasterVT320Term);
