import { gql, useMutation } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import { Box, MenuItem, Modal, TextField, Typography } from '@mui/material';
import { AnchorAttributes } from '@theora360/shared/src/pages/anchors/use-anchor-updates';
import { showMessageAction } from '@theora360/shared/src/redux-store/snackbar-store';
import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { FormContainer, TextFieldElement } from 'react-hook-form-mui';
import { useDispatch } from 'react-redux';
import TagsElement from '../../../components/TagsElement';
import useSelectedCampusId from '../../../shared/use-selected-campus-id';
import { NeredeDatasetAttributes } from './constants';

const updateNeredeDatasetMutation = gql`
  mutation UpdateNeredeDataset($dataset: NeredeDatasetUpdateInput!) {
    updateNeredeDataset(dataset: $dataset) {
      dataset ${NeredeDatasetAttributes}
    }
  }
`;

const startNeredeDatasetMutation = gql`
  mutation StartNeredeDataset($dataset: NeredeDatasetCreateInput!) {
    startNeredeDataset(dataset: $dataset) {
      dataset ${NeredeDatasetAttributes}
    }
  }
`;

const DurationOptions = [
  {
    label: '30 secs',
    value: 30,
  },
  {
    label: '1 min',
    value: 60,
  },
  {
    label: '2 min',
    value: 120,
  },
  {
    label: '4 min',
    value: 160,
  },
  {
    label: '8 min',
    value: 480,
  },
  {
    label: 'Unspecified',
    value: 0,
  },
];

function SelectDurationElement({
  variant,
  name,
  label,
  disabled,
  required,
  style,
}) {
  return (
    <TextFieldElement
      style={style}
      variant={variant}
      name={name}
      label={label}
      type="text"
      disabled={disabled}
      select
    >
      {DurationOptions.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </TextFieldElement>
  );
}

function EditDatasetModal({ visible, onCancel, onFinish }) {
  const { existing, creating } = visible || {};
  const campusId = useSelectedCampusId();
  const [loading, setLoading] = useState(false);
  const [updateNeredeDataset] = useMutation(updateNeredeDatasetMutation);
  const [startNeredeDataset] = useMutation(startNeredeDatasetMutation);
  const form = useForm();

  useEffect(() => {
    form.reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  useEffect(() => {
    if (existing) {
      form.setValue('name', existing.name);
      form.setValue(
        'tags',
        existing.tags.map((t) => ({ title: t })),
      );
      form.setValue('notes', existing.notes);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [existing]);

  const dispatch = useDispatch();
  const onSubmit = useCallback(
    async (_values) => {
      console.log('values', _values);
      const { tags: _tags, ...values } = _values;
      const tags = (_tags || []).map((t) => t.title);
      setLoading(true);
      try {
        if (existing) {
          await updateNeredeDataset({
            variables: {
              dataset: {
                _id: existing._id,
                ...values,
                tags,
              },
            },
          });
          dispatch(
            showMessageAction({
              _id: 'edit-dataset',
              severity: 'success',
              message: 'Dataset saved!',
            }),
          );
        } else if (creating) {
          await startNeredeDataset({
            variables: {
              dataset: {
                _id: creating._id,
                campusId,
                ...values,
                tags,
              },
            },
          });
          dispatch(
            showMessageAction({
              _id: 'edit-dataset',
              severity: 'success',
              message: 'Dataset recording started!',
            }),
          );
        }
        onFinish();
      } catch (err) {
        console.error(err);
        dispatch(
          showMessageAction({
            _id: 'edit-dataset',
            severity: 'error',
            message: 'There was an error saving dataset',
          }),
        );
      }
      setLoading(false);
    },
    [
      updateNeredeDataset,
      existing,
      creating,
      dispatch,
      onFinish,
      campusId,
      startNeredeDataset,
    ],
  );

  return (
    <Modal
      open={!!visible}
      onClose={onCancel}
      sx={{
        overflow: 'scroll',
        p: 4,
      }}
    >
      <Box
        sx={{
          backgroundColor: 'white',
          textAlign: 'center',
          borderRadius: 1,
          padding: 4,
          marginTop: 4,
          marginBottom: 4,
          width: 500,
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
      >
        <Typography variant="h4" sx={{ mb: 3 }}>
          {existing ? 'Edit Dataset' : 'Start Dataset Recording'}
        </Typography>
        <FormContainer formContext={form} onSuccess={onSubmit}>
          <TextFieldElement
            style={{ marginBottom: 32, width: '100%' }}
            variant="standard"
            name="name"
            label="Name"
            type="text"
            disabled={loading}
            required
          />
          <TagsElement
            style={{ marginBottom: 32, width: '100%' }}
            variant="standard"
            name="tags"
            label="Tags"
            disabled={loading}
            form={form}
          />
          <TextFieldElement
            style={{ marginBottom: 32, width: '100%' }}
            variant="standard"
            name="notes"
            label="Notes"
            type="multiline"
            disabled={loading}
          />
          {creating && (
            <SelectDurationElement
              style={{ marginBottom: 32, width: '100%', textAlign: 'left' }}
              variant="standard"
              name="durationSecs"
              label="Duration"
              disabled={loading}
              required
            />
          )}
          <div style={{ height: 16 }} />
          <Box>
            <LoadingButton type="submit" variant="contained" loading={loading}>
              {existing ? 'Save Dataset' : 'Start Recording'}
            </LoadingButton>
          </Box>
        </FormContainer>
      </Box>
    </Modal>
  );
}

export default EditDatasetModal;
