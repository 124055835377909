import React from 'react';
import useSelectedCampusId from '../../../shared/use-selected-campus-id';
import useFilterAnchors from '../anchors/use-filter-anchors';
import AiTagLocTable from './AiTagLocTable';

function AITagLocationTab({ aiTagLocations }) {
  const campusId = useSelectedCampusId();
  const anchors = useFilterAnchors({
    campusId,
    filters: {},
  });
  return (
    <>
      <AiTagLocTable aiTagLocations={aiTagLocations} anchors={anchors} />
    </>
  );
}

export default AITagLocationTab;
