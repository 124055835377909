import { SelectElement } from 'react-hook-form-mui';
import { shallowEqual, useSelector } from 'react-redux';

function SelectCampusFieldElement() {
  const campuses = useSelector(
    (store) =>
      (store.campuses.default && Object.values(store.campuses.default)).filter(
        (c) => c,
      ) || [],
    shallowEqual,
  );
  return (
    <SelectElement
      label="Campus"
      name="campusId"
      options={campuses.map((c) => ({ id: c._id, label: c.label }))}
      variant="standard"
      style={{ marginBottom: 32, width: '100%', textAlign: 'left' }}
    />
  );
}

export default SelectCampusFieldElement;
