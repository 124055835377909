import { useApolloClient } from '@apollo/client';
import { showMessageAction } from '@theora360/shared/src/redux-store/snackbar-store';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { TICKS_TO_METERS, anchorColors, tagColors } from './constants';
import { getEllipse } from './utils';

function use3dLocationTraces(tagLocations, anchors) {
  const dispatch = useDispatch();
  const client = useApolloClient();
  const [traces, setTraces] = useState({
    anchorsTrace: {},
    tagsTrace: {},
    ellipseTraces: [],
    rangeTraces: [],
  });
  useEffect(() => {
    async function doAsyncStuff() {
      const anchorLocs = anchors.filter((a) => a.location);
      const anchorsTrace = {
        x: anchorLocs.map((a) => a.location[0]),
        y: anchorLocs.map((a) => a.location[1]),
        z: anchorLocs.map((a) => a.location[2]),
        name: 'Anchors',
        text: anchorLocs.map((a) => a.label),
        mode: 'markers',
        marker: {
          size: 12,
          line: {
            color: tagColors[0],
            width: 0.5,
          },
          opacity: 0.8,
        },
        type: 'scatter3d',
      };

      const filteredTagLocations = tagLocations.filter((t) => t.xyzPos);
      const tagsTrace = {
        x: filteredTagLocations.map((t) => t.xyzPos[0]),
        y: filteredTagLocations.map((t) => t.xyzPos[1]),
        z: filteredTagLocations.map((t) => t.xyzPos[2]),
        name: 'Tags',
        text: filteredTagLocations.map((t) => t._id),
        mode: 'markers',
        marker: {
          size: 6,
          color: filteredTagLocations.map(
            (a, i) => tagColors[(i + 1) % tagColors.length],
          ),
          line: {
            color: filteredTagLocations.map(
              (a, i) => tagColors[(i + 1) % tagColors.length],
            ),
            width: 0.25,
          },
          opacity: 0.8,
        },
        type: 'scatter3d',
      };

      const ellipseTraces = [];
      await Promise.all(
        filteredTagLocations.map(async (tagLoc, index) => {
          try {
            const sphere = await getEllipse(
              client,
              tagLoc.xyzPos[0],
              tagLoc.xyzPos[1],
              tagLoc.xyzPos[2],
              Math.sqrt(tagLoc.xyzVar[0]),
              Math.sqrt(tagLoc.xyzVar[1]),
              Math.sqrt(tagLoc.xyzVar[2]),
            );
            ellipseTraces.push({
              ...sphere,
              name: `Accuracy ${tagLoc.tagId}`,
              text: tagLoc.tagId,
              color: tagColors[(index + 1) % tagColors.length],
              opacity: 0.5,
              alphahull: 0,
              showlegend: true,
              type: 'mesh3d',
            });
          } catch (err) {
            console.error(err);
            dispatch(
              showMessageAction({
                _id: 'get-tag-ellipse',
                severity: 'error',
                message: 'There was an error generating ellipse',
              }),
            );
          }
        }),
      );
      ellipseTraces.sort((a, b) => a.text.localeCompare(b.text));

      const rangeTraces = [];
      await Promise.all(
        filteredTagLocations.map(
          async (tagLoc) =>
            await Promise.all(
              tagLoc.anchorIds.map(async (anchorId, j) => {
                const anchor = anchors.find((a) => a._id === anchorId);
                if (anchor.location) {
                  try {
                    const sphere = await getEllipse(
                      client,
                      anchor.location[0],
                      anchor.location[1],
                      anchor.location[2],
                      tagLoc.distances[j] * TICKS_TO_METERS,
                      tagLoc.distances[j] * TICKS_TO_METERS,
                      tagLoc.distances[j] * TICKS_TO_METERS,
                    );
                    rangeTraces.push({
                      ...sphere,
                      text: `${tagLoc.tagId} <- ${anchorId}`,
                      name: `${tagLoc.tagId} <- ${anchorId} opaque`,
                      opacity: 1,
                      color: anchorColors[j % anchorColors.length],
                      showlegend: true,
                      visible: 'legendonly',
                      alphahull: 0,
                      type: 'mesh3d',
                    });
                    rangeTraces.push({
                      ...sphere,
                      text: `${tagLoc.tagId} <- ${anchorId}`,
                      name: `${tagLoc.tagId} <- ${anchorId}`,
                      opacity: 0.25,
                      color: anchorColors[j % anchorColors.length],
                      showlegend: true,
                      visible: 'legendonly',
                      alphahull: 0,
                      type: 'mesh3d',
                    });
                  } catch (err) {
                    console.error(err);
                    dispatch(
                      showMessageAction({
                        _id: 'get-ellipse',
                        severity: 'error',
                        message: 'There was an error generating ellipse',
                      }),
                    );
                  }
                }
              }),
            ),
        ),
      );
      rangeTraces.sort((a, b) => a.text.localeCompare(b.text));
      setTraces({
        anchorsTrace,
        tagsTrace,
        ellipseTraces,
        rangeTraces,
      });
    }
    doAsyncStuff();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [anchors, tagLocations, dispatch, client]);

  return traces;
}

export default use3dLocationTraces;
