import { gql, useSubscription } from '@apollo/client';
import { useState } from 'react';

export const SiteMasterAnchorManifestAttributes = gql`
  {
    _id
    campusId
    lastMsgAnchorCount
    lastMsgNumAnchors
    lastMsgCloudSequenceNo
    lastMessageNo
    instructionStatus
    anchors {
      _id
      logicalAddr
      rssi
      secondsSinceRx
      hasIpAddress
      updatedAt
    }
    updatedAt
  }
`;

export const siteMasterAnchorManifestUpdatesSubscription = gql`
  subscription SiteMasterAnchorManifestUpdates($siteMasterId: ID!) {
    siteMasterAnchorManifestUpdates(siteMasterId: $siteMasterId) ${SiteMasterAnchorManifestAttributes}
  }
`;

function useSiteMasterAnchorManifestUpdates(siteMasterId) {
  const [anchormanifest, setAnchorManifest] = useState();
  useSubscription(siteMasterAnchorManifestUpdatesSubscription, {
    skip: !siteMasterId,
    variables: {
      siteMasterId,
    },
    fetchPolicy: 'no-cache',
    onData: ({ client, data: result }) => {
      try {
        if (
          result &&
          result.data &&
          result.data.siteMasterAnchorManifestUpdates
        ) {
          const update = result.data.siteMasterAnchorManifestUpdates;
          setAnchorManifest(update);
        }
      } catch (err) {
        console.error(err);
      }
    },
  });
  return anchormanifest;
}

export default useSiteMasterAnchorManifestUpdates;
