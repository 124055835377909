import { gql } from '@apollo/client';

export const NeredeDatasetAttributes = gql`
  {
    _id
    campusId
    timestampMs
    lastTimestampMs
    name
    tags
    notes
    fileId
    createdBy
    updatedBy
    createdAt
    updatedAt
    deletedAt
    deletedBy
  }
`;
