import { gql, useQuery } from '@apollo/client';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Box, Button } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';

export const nextLogicalAnchorIdQuery = gql`
  query NextLogicalAnchorId($_id: ID!) {
    nextLogicalAnchorId(_id: $_id)
  }
`;

export const prevLogicalAnchorIdQuery = gql`
  query PrevLogicalAnchorId($_id: ID!) {
    prevLogicalAnchorId(_id: $_id)
  }
`;

function NextPrevAnchorButtons({ _id }) {
  const nextResult = useQuery(nextLogicalAnchorIdQuery, {
    variables: { _id },
    fetchPolicy: 'network-only',
  });
  const nextLogicalAnchorId =
    nextResult && nextResult.data && nextResult.data.nextLogicalAnchorId;

  const prevResult = useQuery(prevLogicalAnchorIdQuery, {
    variables: { _id },
    fetchPolicy: 'network-only',
  });
  const prevLogicalAnchorId =
    prevResult && prevResult.data && prevResult.data.prevLogicalAnchorId;

  return (
    <>
      {(prevLogicalAnchorId || nextLogicalAnchorId) && (
        <Box sx={{ display: 'flex' }}>
          {prevLogicalAnchorId && (
            <Button
              variant="outlined"
              sx={{ mr: 2 }}
              startIcon={<ArrowBackIcon />}
              component={Link}
              to={`/devices/anchors/${prevLogicalAnchorId}/status`}
            >
              Previous Anchor
            </Button>
          )}
          {nextLogicalAnchorId && (
            <Button
              variant="outlined"
              sx={{ mr: 2 }}
              startIcon={<ArrowForwardIcon />}
              component={Link}
              to={`/devices/anchors/${nextLogicalAnchorId}/status`}
            >
              Next Anchor
            </Button>
          )}
        </Box>
      )}
    </>
  );
}

export default NextPrevAnchorButtons;
