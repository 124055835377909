import { useCallback, useMemo, useRef } from 'react';

function useTagLocHistory(tagLocations) {
  const data = useRef({});
  const zero = useRef(null);
  const tagLocHistory = useMemo(() => {
    tagLocations.forEach((tagLoc) => {
      if (!data.current[tagLoc.tagId]) {
        data.current[tagLoc.tagId] = {
          details: [],
          t: [],
          epoch: [],
          x: [],
          xVar: [],
          y: [],
          yVar: [],
          z: [],
          zVar: [],
          lastTimestamp: undefined,
        };
      }
      const timestamp = tagLoc.epochTimestamp / 1000.0;
      if (
        data.current[tagLoc.tagId].lastTimestamp !== timestamp &&
        tagLoc.xyzPos
      ) {
        data.current[tagLoc.tagId].details.push({
          t: timestamp,
          a: tagLoc.anchorIds,
          d: tagLoc.distances,
          locs: tagLoc.locations,
        });
        data.current[tagLoc.tagId].x.push(tagLoc.xyzPos[0]);
        data.current[tagLoc.tagId].xVar.push(tagLoc.xyzVar[0]);
        data.current[tagLoc.tagId].y.push(tagLoc.xyzPos[1]);
        data.current[tagLoc.tagId].yVar.push(tagLoc.xyzVar[1]);
        data.current[tagLoc.tagId].z.push(tagLoc.xyzPos[2]);
        data.current[tagLoc.tagId].zVar.push(tagLoc.xyzVar[2]);
        if (zero.current === null) {
          zero.current = timestamp;
        }
        data.current[tagLoc.tagId].t.push(timestamp - zero.current);
        data.current[tagLoc.tagId].epoch.push(timestamp);
        data.current[tagLoc.tagId].lastTimestamp = timestamp;
      }
    });
    return data.current;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tagLocations]);

  const reset = useCallback(() => {
    data.current = {};
    zero.current = null;
  }, []);

  return [tagLocHistory, reset];
}

export default useTagLocHistory;
