import { gql, useMutation } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import { Box, Modal, Typography } from '@mui/material';
import { showMessageAction } from '@theora360/shared/src/redux-store/snackbar-store';
import {
  formatDuration,
  generateUuid,
} from '@theora360/shared/src/shared/utils';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormContainer, TextFieldElement } from 'react-hook-form-mui';
import { useDispatch } from 'react-redux';
import TagsElement from '../../../components/TagsElement';

const updateSavedModelMutation = gql`
  mutation UpdateSavedModel($savedModel: SavedModelUpdateInput!) {
    updateSavedModel(savedModel: $savedModel) {
      savedModel {
        _id
      }
    }
  }
`;

function EditSavedModelModal({ visible, onCancel, onFinish }) {
  const { data } = visible || {};
  const [loading, setLoading] = useState(false);
  const [updateSavedModel] = useMutation(updateSavedModelMutation);
  const form = useForm();

  useEffect(() => {
    if (data) {
      form.setValue('name', data.name);
      form.setValue('notes', data.notes);
      form.setValue('tags', data.tags);
      form.setValue(
        'tags',
        data.tags.map((t) => ({ title: t })),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const dispatch = useDispatch();
  const onSubmit = useCallback(
    async (_values) => {
      const { tags: _tags, ...values } = _values;
      const tags = (_tags || []).map((t) => t.title);
      setLoading(true);
      try {
        await updateSavedModel({
          variables: {
            savedModel: {
              _id: data._id,
              tags,
              ...values,
            },
          },
        });

        dispatch(
          showMessageAction({
            _id: 'edit-saved-model',
            severity: 'success',
            message: 'Annotation saved!',
          }),
        );
        onFinish();
      } catch (err) {
        console.error(err);
        dispatch(
          showMessageAction({
            _id: 'edit-saved-model',
            severity: 'error',
            message: 'There was an error saving savedModel',
          }),
        );
      }
      setLoading(false);
    },
    [updateSavedModel, data, dispatch, onFinish],
  );

  return (
    <Modal
      open={!!visible}
      onClose={onCancel}
      sx={{
        overflow: 'scroll',
        p: 4,
      }}
    >
      <Box
        sx={{
          backgroundColor: 'white',
          textAlign: 'center',
          borderRadius: 1,
          padding: 4,
          marginTop: 4,
          marginBottom: 4,
          width: 500,
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
      >
        <Typography variant="h4" sx={{ mb: 3 }}>
          Saved Model
        </Typography>
        <FormContainer formContext={form} onSuccess={onSubmit}>
          <TextFieldElement
            style={{ marginBottom: 32, width: '100%' }}
            variant="standard"
            name="name"
            label="Name"
            helperText="This is the name used for AI model training.  Should be the same for all savedModels associated with a specific thing/location."
            type="text"
            disabled={loading}
            required
          />
          <TextFieldElement
            style={{ marginBottom: 32, width: '100%' }}
            variant="standard"
            name="notes"
            label="Notes"
            type="text"
            disabled={loading}
          />
          <TagsElement
            style={{ marginBottom: 32, width: '100%' }}
            variant="standard"
            name="tags"
            label="Tags"
            disabled={loading}
            form={form}
          />
          <div style={{ height: 16 }} />
          <Box>
            <LoadingButton type="submit" variant="contained" loading={loading}>
              Save
            </LoadingButton>
          </Box>
        </FormContainer>
      </Box>
    </Modal>
  );
}

export default EditSavedModelModal;
