import { Switch, Typography } from '@mui/material';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import MessageIcon from '@mui/icons-material/Message';

function TrainLogsTerm({ lines }) {
  const bottom = useRef();
  const container = useRef();
  const [autoScroll, setAutoScroll] = useState(true);

  useLayoutEffect(() => {
    if (autoScroll) {
      if (container.current && bottom.current) {
        container.current.scrollTop = bottom.current.offsetHeight;
      }
    }
  }, [lines?.length, autoScroll]);

  return (
    <>
      <Typography variant="h5" sx={{ mt: 3, mb: 2 }}>
        <MessageIcon style={{ height: 40, width: 40 }} /> Training Logs
      </Typography>
      <div
        style={{
          marginBottom: 8,
          display: 'flex',
          flexDirection: 'row-reverse',
        }}
      >
        <map onClick={() => setAutoScroll(!autoScroll)}>
          <Switch
            checked={autoScroll}
            onChange={(e) => {
              setAutoScroll(e.target.checked);
            }}
            sx={{}}
          />
          <span>Autoscroll</span>
        </map>
      </div>
      <div
        ref={container}
        style={{
          height: 600,
          overflow: 'scroll',
          backgroundColor: '#181818',
          color: 'white',
          paddingRight: 16,
          paddingLeft: 16,
          paddingBottom: 16,
          paddingTop: 16,
          borderRadius: 4,
          fontFamily: 'monospace',
          fontSize: 14,
        }}
      >
        <div ref={bottom}>
          {lines &&
            lines.map((line) => (
              <>
                <span>{line}</span>
                <br />
              </>
            ))}
        </div>
        <div />
      </div>
    </>
  );
}

export default TrainLogsTerm;
