import { gql, useMutation } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Modal, Typography } from '@mui/material';
import { showMessageAction } from '@theora360/shared/src/redux-store/snackbar-store';
import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

const deleteSavedModelMutation = gql`
  mutation DeleteSavedModel($_id: ID!) {
    deleteSavedModel(_id: $_id) {
      deletedId
    }
  }
`;

function DeleteSavedModelModal({ deleting, onFinish, onCancel }) {
  const { data } = deleting || {};
  const [loading, setLoading] = useState(false);

  const [deleteSavedModel] = useMutation(deleteSavedModelMutation);
  const dispatch = useDispatch();
  const onSubmit = useCallback(async () => {
    setLoading(true);
    try {
      await deleteSavedModel({
        variables: {
          _id: data._id,
        },
      });
      dispatch(
        showMessageAction({
          _id: 'delete-saved-model',
          severity: 'success',
          message: 'Saved model deleted!',
        }),
      );
      onFinish();
    } catch (err) {
      console.error(err);
      dispatch(
        showMessageAction({
          _id: 'delete-saved-model',
          severity: 'error',
          message: 'There was an error deleting saved model',
        }),
      );
    }
    setLoading(false);
  }, [deleteSavedModel, data, onFinish, dispatch]);
  return (
    <Modal
      open={!!deleting}
      onClose={onCancel}
      sx={{
        overflow: 'scroll',
        p: 4,
      }}
    >
      <Box
        sx={{
          backgroundColor: 'white',
          textAlign: 'center',
          borderRadius: 1,
          padding: 4,
          marginTop: 4,
          marginBottom: 4,
          width: 500,
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
      >
        <Typography variant="h4" sx={{ mb: 3 }}>
          Delete Saved Model
        </Typography>
        <Typography variant="body1" sx={{ mb: 2 }}>
          Are you sure you want to delete this saved model?
        </Typography>
        <Typography variant="body1" sx={{ mb: 4 }}>
          {data && data.name}
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Button sx={{ mr: 4 }} onClick={onCancel}>
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            loading={loading}
            onClick={onSubmit}
          >
            Delete
          </LoadingButton>
        </Box>
      </Box>
    </Modal>
  );
}

export default DeleteSavedModelModal;
