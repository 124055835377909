import DevicesIcon from '@mui/icons-material/Devices';
import { TabContext, TabPanel } from '@mui/lab';
import { Box, Button, Tab, Tabs, Typography } from '@mui/material';
import useLocale from '@theora360/shared/src/shared/use-locale';
import React, { useMemo, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import InfoBox from '../../../components/InfoBox';
import MainPageTemplate from '../../../components/MainPageTemplate';
import useSelectedCampusId from '../../../shared/use-selected-campus-id';
import EditSiteMasterModal from './EditSiteMasterModal';
import SiteMasterAnchorManifestTable from './SiteMasterAnchorManifestTable';
import SiteMasterFirmwareStatusBox from './SiteMasterFirmwareStatusBox';
import useSiteMasterLastUpdates from './use-site-master-last';
import EditIcon from '@mui/icons-material/Edit';
import SiteMasterLanTimeslotsTable from './SiteMasterLanTimeslotsTable';
import DeleteSiteMasterModal from './DeleteSiteMasterModal';
import RequestSiteMasterHaltButton from './RequestSiteMasterHaltButton';
import SiteMasterVT320Term from './SiteMasterVT320Term';

function ViewSiteMasterPage() {
  const navigate = useNavigate();
  const { _id, tab } = useParams();
  const campusId = useSelectedCampusId();
  const device = useSelector(
    (store) =>
      store.siteMasters[campusId] &&
      store.siteMasters[campusId] &&
      store.siteMasters[campusId][_id],
    shallowEqual,
  );
  const locale = useLocale();
  const dateFormat = useMemo(() => {
    return new Intl.DateTimeFormat(locale, {
      dateStyle: 'short',
      timeStyle: 'medium',
    });
  }, [locale]);
  const last = useSiteMasterLastUpdates(_id);
  const info1 = useMemo(() => {
    if (device) {
      return [
        {
          label: 'Label',
          desc: device.label,
        },
        {
          label: 'Description',
          desc: device.desc,
        },
        device.address && {
          label: 'IP Address',
          desc: device.address && device.address.host,
        },
        {
          label: 'Updated At',
          desc: dateFormat.format(new Date(device.updatedAt)),
        },
        last && {
          label: 'Last Message At',
          desc: dateFormat.format(new Date(last.updatedAt)),
        },
      ].filter((i) => i);
    }
    return [];
  }, [device, dateFormat, last]);

  const [editing, setEditing] = useState();
  const onCancelEditSiteMaster = () => setEditing(undefined);
  const onEditSiteMaster = (siteMaster) => setEditing({ data: device });
  const onFinishEditSiteMaster = () => setEditing(undefined);

  return (
    <MainPageTemplate title="Site Master">
      <Typography variant="h3" gutterBottom>
        <DevicesIcon style={{ height: 40, width: 40 }} /> Site Master
      </Typography>
      <Box sx={{ mb: 2, display: 'flex' }}>
        {device && <DeleteSiteMasterModal data={device} />}
        {device && <RequestSiteMasterHaltButton _id={_id} />}
      </Box>
      <Typography variant="h5" sx={{ mb: 1 }}>
        Device Details
      </Typography>
      <Button
        startIcon={<EditIcon />}
        variant="contained"
        sx={{ mb: 2 }}
        onClick={onEditSiteMaster}
      >
        Edit Device Details
      </Button>
      <InfoBox items={info1} sx={{ mb: 3 }} />
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={tab}
          onChange={(event, value) => {
            navigate(`/devices/site-masters/${_id}/${value}`);
          }}
          aria-label="basic tabs example"
        >
          <Tab value="fw-status" label="Firmware Status" />
          <Tab value="anchors" label="Anchor Manifest" />
          <Tab value="tdmas" label="LAN Timeslots" />
          <Tab value="vt320" label="VT320" />
        </Tabs>
      </Box>
      <TabContext value={tab}>
        <TabPanel
          value="fw-status"
          index={0}
          sx={{ paddingRight: 0, paddingLeft: 0 }}
        >
          <SiteMasterFirmwareStatusBox _id={_id} />
        </TabPanel>
        <TabPanel
          value="anchors"
          index={1}
          sx={{ paddingRight: 0, paddingLeft: 0 }}
        >
          <SiteMasterAnchorManifestTable _id={_id} />
        </TabPanel>
        <TabPanel
          value="tdmas"
          index={2}
          sx={{ paddingRight: 0, paddingLeft: 0 }}
        >
          <SiteMasterLanTimeslotsTable _id={_id} />
        </TabPanel>
        <TabPanel
          value="vt320"
          index={3}
          sx={{ paddingRight: 0, paddingLeft: 0 }}
        >
          <SiteMasterVT320Term _id={_id} />
        </TabPanel>
      </TabContext>
      <EditSiteMasterModal
        visible={editing}
        onFinish={onFinishEditSiteMaster}
        onCancel={onCancelEditSiteMaster}
      />
    </MainPageTemplate>
  );
}

export default ViewSiteMasterPage;
