import { gql, useApolloClient } from '@apollo/client';
import DownloadIcon from '@mui/icons-material/Download';
import { Button } from '@mui/material';
import { showMessageAction } from '@theora360/shared/src/redux-store/snackbar-store';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';

const FileNodeAttributes = gql`
  {
    _id
    desc
    s3Key
    filename
    type
    tags
    parentId
    uploaded
    processed
    contentType
    size
    url
    metadata
    createdBy
    updatedBy
    createdAt
    updatedAt
  }
`;

const downloadNeredeDatasetMutation = gql`
  mutation DownloadNeredeDataset($_id: ID!) {
    downloadNeredeDataset(_id: $_id) ${FileNodeAttributes}
  }
`;

function DownloadDatasetButton({ _id }) {
  const dispatch = useDispatch();
  const client = useApolloClient();
  const onDownloadDataset = useCallback(async () => {
    try {
      const result = await client.mutate({
        mutation: downloadNeredeDatasetMutation,
        variables: {
          _id,
        },
        fetchPolicy: 'network-only',
      });
      const url =
        result &&
        result.data &&
        result.data.downloadNeredeDataset &&
        result.data.downloadNeredeDataset.url;
      if (!url) {
        throw new Error('Dataset URL missing');
      }
      let element = document.createElement('a');
      element.setAttribute('href', url);
      element.setAttribute('download', `t360-${_id}.json`);
      element.style.display = 'none';
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    } catch (err) {
      console.error(err);
      dispatch(
        showMessageAction({
          _id: 'download-dataset',
          severity: 'error',
          message: 'There was an error downloading dataset',
        }),
      );
    }
  }, [dispatch, client, _id]);
  return (
    <Button
      color="primary"
      startIcon={<DownloadIcon />}
      onClick={onDownloadDataset}
      sx={{ mr: 2, mb: 1 }}
    >
      Download Dataset
    </Button>
  );
}

export default DownloadDatasetButton;
