import { gql } from '@apollo/client';

const EllipseResult = gql`
  {
    x
    y
    z
  }
`;

const makeEllipseQuery = gql`
  query MakeEllipse($params: EllipseParams!) {
    makeEllipse(params: $params) ${EllipseResult}
  }
`;

export async function getEllipse(client, px, py, pz, rx, ry, rz) {
  const resp = await client.query({
    query: makeEllipseQuery,
    variables: {
      params: {
        px,
        py,
        pz,
        rx,
        ry,
        rz,
      },
    },
  });
  return resp?.data?.makeEllipse;
}

const distanceRatio = {
  mean: 0.002269542079673832,
  variance: 1.8131280016860944e-8,
};

export function makeDistanceParts(distance) {
  const distanceM = distanceRatio.mean * distance;
  const distanceMVar = distanceRatio.variance * distance * distance;
  return [distanceM, distanceMVar];
}
