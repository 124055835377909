import { gql, useQuery } from '@apollo/client';
import StorageIcon from '@mui/icons-material/Storage';
import { Box, Button, Chip, Typography } from '@mui/material';
import useLocale from '@theora360/shared/src/shared/use-locale';
import { formatDuration } from '@theora360/shared/src/shared/utils';
import React, { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import InfoBox from '../../../components/InfoBox';
import MainPageTemplate from '../../../components/MainPageTemplate';
import { NeredeDatasetAttributes } from './constants';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import DeleteDatasetModal from './DeleteDatasetModal';
import EditDatasetModal from './EditDatasetModal';
import settings from '../../../settings';
import DownloadDatasetButton from './DownloadDatasetButton';
import ViewData from './ViewData';

export const neredeDatasetQuery = gql`
  query NeredeDataset($_id: ID!) {
    neredeDataset(_id: $_id) ${NeredeDatasetAttributes}
  }
`;

function ViewDatasetPage() {
  const { _id, _tab } = useParams();
  const {
    loading: fetchLoading,
    error: fetchError,
    data,
    refetch,
  } = useQuery(neredeDatasetQuery, {
    variables: {
      _id,
    },
    fetchPolicy: 'network-only',
  });
  const dataset = useMemo(() => {
    return data && data.neredeDataset;
  }, [data]);

  const locale = useLocale();
  const dateFormat = useMemo(() => {
    return new Intl.DateTimeFormat(locale, {
      dateStyle: 'short',
      timeStyle: 'medium',
    });
  }, [locale]);
  const info1 = useMemo(() => {
    if (dataset) {
      return [
        {
          label: 'Name',
          desc: dataset.name,
        },
        {
          label: 'Start Time',
          desc: dateFormat.format(new Date(dataset.timestampMs * 1000)),
        },
        dataset.lastTimestampMs && {
          label: 'Duration',
          desc: formatDuration(dataset.lastTimestampMs - dataset.timestampMs),
        },
        dataset.tags && {
          label: 'Tags',
          component: (
            <>
              {dataset.tags.map((tag) => (
                <Chip
                  key={tag}
                  label={tag}
                  color="primary"
                  sx={{ mr: 1, mb: 0.5 }}
                />
              ))}
            </>
          ),
        },
        {
          label: 'Updated At',
          desc: dateFormat.format(new Date(dataset.updatedAt)),
        },
        dataset.notes && {
          label: 'Notes',
          desc: dataset.notes,
        },
      ].filter((i) => i);
    }
    return [];
  }, [dataset, dateFormat]);
  const [editing, setEditing] = useState();
  const onCancelEditDataset = () => setEditing(undefined);
  const onEditDataset = () => setEditing({ existing: dataset });
  const onFinishEditDataset = () => {
    refetch();
    setEditing(undefined);
  };
  return (
    <MainPageTemplate title="Dataset">
      <Typography variant="h3" gutterBottom>
        <StorageIcon style={{ height: 40, width: 40 }} /> Dataset
      </Typography>
      <Box sx={{ display: 'flex' }}>
        <DownloadDatasetButton _id={_id} />
        <Button
          color="primary"
          startIcon={<EditIcon />}
          onClick={onEditDataset}
          sx={{ mr: 2, mb: 1 }}
        >
          Edit Dataset
        </Button>
        <DeleteDatasetModal data={dataset} />
      </Box>
      <Typography variant="h6" sx={{ mb: 1 }}>
        Dataset Details
      </Typography>
      <InfoBox items={info1} sx={{ mb: 3 }} />
      <ViewData datasetId={_id} tab={_tab} campusId={dataset?.campusId} />
      <EditDatasetModal
        visible={editing}
        onFinish={onFinishEditDataset}
        onCancel={onCancelEditDataset}
      />
    </MainPageTemplate>
  );
}

export default ViewDatasetPage;
