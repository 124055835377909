import { Box, Grid, Paper, Skeleton, Typography } from '@mui/material';
import React from 'react';

function InfoBox({ items, loading, sx }) {
  return (
    <Paper sx={{ p: 2, ...sx }}>
      <Grid container spacing={2}>
        {loading ? (
          <>
            {Array(8)
              .fill(0)
              .map((item) => (
                <Grid item xs={3}>
                  <Skeleton variant="text" />
                  <Skeleton variant="text" />
                </Grid>
              ))}
          </>
        ) : (
          <>
            {items.map((item, index) => (
              <Grid item xs={3} key={index}>
                <Typography variant="h6">{item.label}</Typography>
                {item.component ? (
                  item.component
                ) : (
                  <Typography variant="body1">{item.desc}</Typography>
                )}
              </Grid>
            ))}
          </>
        )}
      </Grid>
    </Paper>
  );
}

export default InfoBox;
