import { useApolloClient } from '@apollo/client';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { authStateChangedAction } from '../redux-store/auth-store';
import { gql } from '@apollo/client';
import { shallowEqual, useSelector } from 'react-redux';
import { myProfileQuery } from '../pages/auth/constants';

function useAuth() {
  const dispatch = useDispatch();
  const client = useApolloClient();
  useEffect(() => {
    const doAsyncStuff = async () => {
      const token = localStorage.getItem('authToken');
      try {
        if (!token) {
          throw new Error('Not signed in');
        }
        let response;
        response = await client.query({
          query: myProfileQuery,
          fetchPolicy: 'network-only',
        });
        if (!response || !response.data || !response.data.myProfile) {
          throw new Error('Error trying to sign in');
        }
        dispatch(authStateChangedAction(response.data.myProfile));
      } catch (err) {
        console.log('Auth:', err.message);
        localStorage.removeItem('authToken');
        client.close();
        dispatch(authStateChangedAction(null));
        // notification.open({
        //   message: 'Error',
        //   description: err.message,
        // });
      }
    };
    doAsyncStuff();
  }, [dispatch, client]);
  const profile = useSelector((store) => store.profile, shallowEqual);
  const authState = useSelector((store) => store.authState, shallowEqual);
  return {
    loading: authState.isLoading,
    profile,
  };
}

export default useAuth;
