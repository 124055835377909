import { gql, useMutation } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Modal, Typography } from '@mui/material';
import { showMessageAction } from '@theora360/shared/src/redux-store/snackbar-store';
import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

const updateCampusMutation = gql`
  mutation updateCampus($campus: CampusUpdateInput!) {
    updateCampus(campus: $campus) {
      campus {
        _id
      }
    }
  }
`;

function StarSavedModelModal({ visible, onFinish, onCancel }) {
  const { data } = visible || {};
  const [loading, setLoading] = useState(false);

  const [updateCampus] = useMutation(updateCampusMutation);
  const dispatch = useDispatch();
  const onSubmit = useCallback(async () => {
    setLoading(true);
    try {
      await updateCampus({
        variables: {
          campus: {
            _id: data.campusId,
            savedModelId: data._id,
          },
        },
      });
      dispatch(
        showMessageAction({
          _id: 'star-saved-model',
          severity: 'success',
          message: 'Saved model stared!',
        }),
      );
      onFinish();
    } catch (err) {
      console.error(err);
      dispatch(
        showMessageAction({
          _id: 'star-saved-model',
          severity: 'error',
          message: 'There was an error staring saved model',
        }),
      );
    }
    setLoading(false);
  }, [updateCampus, data, onFinish, dispatch]);
  return (
    <Modal
      open={!!visible}
      onClose={onCancel}
      sx={{
        overflow: 'scroll',
        p: 4,
      }}
    >
      <Box
        sx={{
          backgroundColor: 'white',
          textAlign: 'center',
          borderRadius: 1,
          padding: 4,
          marginTop: 4,
          marginBottom: 4,
          width: 500,
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
      >
        <Typography variant="h4" sx={{ mb: 3 }}>
          Star Saved Model
        </Typography>
        <Typography variant="h6" sx={{ mb: 2 }}>
          {data && data.name}
        </Typography>
        <Typography variant="body1" sx={{ mb: 3 }}>
          This action selects this saved model to be used for AI Location on the
          Live Demo page. This will affect the current campus.
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Button sx={{ mr: 4 }} onClick={onCancel}>
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            loading={loading}
            onClick={onSubmit}
          >
            Star
          </LoadingButton>
        </Box>
      </Box>
    </Modal>
  );
}

export default StarSavedModelModal;
