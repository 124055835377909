import { useCallback, useRef, useState } from 'react';
import { anchorColors } from '../demo/constants';

function useTrainingStatus() {
  const data = useRef({
    fit: {
      accuracy: [],
      loss: [],
      steps: [],
    },
    eval: {
      accuracy: [],
      loss: [],
      steps: [],
    },
  });
  const logs = useRef([]);
  const init = () => {
    data.current = {
      fit: {
        accuracy: [],
        loss: [],
        steps: [],
      },
      eval: {
        accuracy: [],
        loss: [],
        steps: [],
      },
    };
    logs.current = [];
  };
  const [status, setStatus] = useState({});
  const onUpdate = useCallback((update) => {
    if (
      update.fitLogs &&
      update.fitLogs.accuracy !== undefined &&
      update.fitLogs.loss !== undefined &&
      update.step
    ) {
      data.current.fit.accuracy.push(update.fitLogs.accuracy);
      data.current.fit.loss.push(update.fitLogs.loss);
      data.current.fit.steps.push(update.step);
    }
    if (
      update.evalLogs &&
      update.evalLogs.accuracy !== undefined &&
      update.evalLogs.loss !== undefined &&
      update.step
    ) {
      data.current.eval.accuracy.push(update.evalLogs.accuracy);
      data.current.eval.loss.push(update.evalLogs.loss);
      data.current.eval.steps.push(update.step);
    }
    if (update.msg) {
      logs.current.push(update.msg);
    }
    const traces = [];
    ['fit', 'eval'].forEach((t1, i) =>
      ['accuracy', 'loss'].forEach((t2, j) => {
        traces.push({
          x: data.current[t1].steps,
          y: data.current[t1][t2],
          name: `${t1} ${t2}`,
          // text: anchorLocs.map((a) => a.label),
          mode: 'lines+markers',
          color: anchorColors[(i * 2 + j) % anchorColors.length],
          type: 'scatter',
        });
      }),
    );

    setStatus({
      step: update.step,
      status: update.status,
      savedModelName: update.savedModelName,
      savedModelId: update.savedModelId,
      traces,
      logs: logs.current,
    });
  }, []);
  return {
    onUpdate,
    status,
    init,
  };
}

export default useTrainingStatus;
