import { gql, useMutation } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import { Box, Modal, Typography } from '@mui/material';
import { showMessageAction } from '@theora360/shared/src/redux-store/snackbar-store';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormContainer, SwitchElement } from 'react-hook-form-mui';
import { useDispatch } from 'react-redux';

const sendConfigSystemFeaturesCmdMutation = gql`
  mutation SendConfigSystemFeaturesCmd($features: ConfigSystemFeaturesInput!) {
    sendConfigSystemFeaturesCmd(features: $features)
  }
`;

function SendConfigSystemFeaturesCmdModal({ visible, onCancel, onFinish }) {
  const { data } = visible || {};
  const [loading, setLoading] = useState(false);
  const [sendConfigSystemFeaturesCmd] = useMutation(
    sendConfigSystemFeaturesCmdMutation,
  );
  const form = useForm();

  useEffect(() => {
    if (data) {
      form.setValue('isTcp', data.anchorTcpEnable);
      form.setValue('sendStdout', data.anchorStdout);
      form.setValue('sendVt320', data.vt320stream);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const dispatch = useDispatch();
  const onSubmit = useCallback(
    async (values) => {
      setLoading(true);
      try {
        await sendConfigSystemFeaturesCmd({
          variables: {
            features: {
              _id: data._id,
              isTcp: values.isTcp ? 1 : 0,
              sendStdout: values.sendStdout ? 1 : 0,
              sendVt320: values.sendVt320 ? 1 : 0,
            },
          },
        });
        dispatch(
          showMessageAction({
            _id: 'config-system-features',
            severity: 'success',
            message: 'Config system features command sent!',
          }),
        );
        onFinish();
      } catch (err) {
        console.error(err);
        dispatch(
          showMessageAction({
            _id: 'config-system-features',
            severity: 'error',
            message:
              'There was an error sending config system features command',
          }),
        );
      }
      setLoading(false);
    },
    [sendConfigSystemFeaturesCmd, data, dispatch, onFinish],
  );

  return (
    <Modal
      open={!!visible}
      onClose={onCancel}
      sx={{
        overflow: 'scroll',
        p: 4,
      }}
    >
      <Box
        sx={{
          backgroundColor: 'white',
          textAlign: 'center',
          borderRadius: 1,
          padding: 4,
          marginTop: 4,
          marginBottom: 4,
          width: 500,
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
      >
        <Typography variant="h4" sx={{ mb: 3 }}>
          Configure System Features
        </Typography>
        <FormContainer formContext={form} onSuccess={onSubmit}>
          <SwitchElement
            style={{ marginBottom: 32, width: '100%', textAlign: 'left' }}
            variant="standard"
            name="isTcp"
            label="Enable TCP"
            disabled={loading}
            required
          />
          <SwitchElement
            style={{ marginBottom: 32, width: '100%', textAlign: 'left' }}
            variant="standard"
            name="sendStdout"
            label="Enable Anchor STDOUT"
            disabled={loading}
            required
          />
          <SwitchElement
            style={{ marginBottom: 32, width: '100%', textAlign: 'left' }}
            variant="standard"
            name="sendVt320"
            label="Enable Anchor VT320"
            disabled={loading}
            required
          />
          <div style={{ height: 16 }} />
          <Box>
            <LoadingButton type="submit" variant="contained" loading={loading}>
              Send System Features Config
            </LoadingButton>
          </Box>
        </FormContainer>
      </Box>
    </Modal>
  );
}

export default SendConfigSystemFeaturesCmdModal;
