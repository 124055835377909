import DevicesIcon from '@mui/icons-material/Devices';
import { TabContext, TabPanel } from '@mui/lab';
import { Box, Tab, Tabs, Typography } from '@mui/material';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import MainPageTemplate from '../../../components/MainPageTemplate';
import AnchorsTab from '../anchors/AnchorsTab';
import GatewaysTab from '../gateways/GatewaysTab';
import SiteMastersTab from '../site-masters/SiteMastersTab';
import TagsTab from '../tags/TagsTab';

function DevicesPage() {
  const navigate = useNavigate();
  const { deviceType } = useParams();
  return (
    <MainPageTemplate title="Devices">
      <Typography variant="h3" gutterBottom>
        <DevicesIcon style={{ height: 40, width: 40 }} /> Devices
      </Typography>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={deviceType}
          onChange={(event, value) => {
            navigate(`/devices/${value}`);
          }}
          aria-label="basic tabs example"
        >
          <Tab value="tags" label="Tags" />
          <Tab value="anchors" label="Anchors" />
          <Tab value="site-masters" label="Site Masters" />
          <Tab value="gateways" label="Gateways" />
        </Tabs>
      </Box>
      <TabContext value={deviceType}>
        <TabPanel value="tags" index={0}>
          <TagsTab />
        </TabPanel>
        <TabPanel value="anchors" index={1}>
          <AnchorsTab />
        </TabPanel>
        <TabPanel value="site-masters" index={2}>
          <SiteMastersTab />
        </TabPanel>
        <TabPanel value="gateways" index={2}>
          <GatewaysTab />
        </TabPanel>
      </TabContext>
    </MainPageTemplate>
  );
}

export default DevicesPage;
