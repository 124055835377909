export const READ_DATASETS = 'READ_DATASETS';
export const CREATE_DATASET = 'CREATE_DATASET';
export const UPDATE_DATASET = 'UPDATE_DATASET';
export const DELETE_DATASET = 'DELETE_DATASET';

// eslint-disable-next-line no-shadow
export const readDatasetsAction = (datasets, queryId = 'default') => ({
  type: READ_DATASETS,
  payload: {
    datasets,
    queryId,
  },
});

export const createDatasetAction = (dataset, queryId = 'default') => ({
  type: CREATE_DATASET,
  payload: {
    dataset,
    queryId,
  },
});

export const updateDatasetAction = (dataset, queryId = 'default') => ({
  type: UPDATE_DATASET,
  payload: {
    dataset,
    queryId,
  },
});

export const deleteDatasetAction = (datasetId, queryId = 'default') => ({
  type: DELETE_DATASET,
  payload: {
    datasetId,
    queryId,
  },
});

const initialDatasets = { default: {} };

export function datasets(state = initialDatasets, action) {
  switch (action.type) {
    case READ_DATASETS: {
      const newState = {
        ...state,
        [action.payload.queryId]: { ...state[action.payload.queryId] },
      };
      action.payload.datasets.forEach((p) => {
        newState[action.payload.queryId][p._id] = p;
      });
      return newState;
    }
    case CREATE_DATASET:
      return {
        ...state,
        [action.payload.queryId]: {
          ...state[action.payload.queryId],
          [action.payload.dataset._id]: action.payload.dataset,
        },
      };
    case UPDATE_DATASET: {
      const current =
        (state[action.payload.queryId] &&
          state[action.payload.queryId][action.payload.dataset._id]) ||
        undefined;
      return {
        ...state,
        [action.payload.queryId]: {
          ...state[action.payload.queryId],
          [action.payload.dataset._id]: {
            ...current,
            ...action.payload.dataset,
          },
        },
      };
    }
    case DELETE_DATASET: {
      const { datasetId } = action.payload;
      return Object.entries(state).reduce((prev, [key, val]) => {
        prev[key] = {
          ...val,
          [datasetId]: undefined,
        };
        return prev;
      }, {});
    }
    default:
      return state;
  }
}
