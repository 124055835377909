import { useApolloClient } from '@apollo/client';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { BehaviorSubject, of } from 'rxjs';
import {
  incrementRemoteQueryAction,
  nextRemoteQueryAction,
  updateRemoteQueryAction,
} from '../redux-store/remote-queries-store';
import autocomplete from './autocomplete';
import { hashCode } from './utils';

export function useManyRemote({
  query,
  extract,
  first,
  filters,
  sortBy = undefined,
  fetchPolicy = 'no-cache',
  queryId = 'default',
}) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const term$ = useRef(null);
  // const [search, setSearch] = useState();
  const nextAfter = useRef();
  const hasNextPage = useRef();
  // const after = useRef();
  // const [after, setAfter] = useState();
  const _data = useRef([]);
  const [data, _setData] = useState(_data.current);
  const setData = useCallback((value) => {
    _data.current = value;
    _setData(value);
  }, []);
  const queryCode = useMemo(() => {
    const inputs = JSON.stringify({
      query,
      extract,
      first,
      filters,
      sortBy,
      fetchPolicy,
    });
    const code = hashCode(inputs);
    return code;
  }, [query, extract, first, filters, sortBy, fetchPolicy]);
  const { refetches, after } = useSelector((store) => {
    return (
      (store.remoteQueries[queryId] &&
        store.remoteQueries[queryId][queryCode]) || { refetches: 0 }
    );
  }, shallowEqual);

  // console.log({ queryId, refetches, after, queryCode });
  const search = useRef('');
  const apolloClient = useApolloClient();
  const last = useRef();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      updateRemoteQueryAction(
        {
          _id: queryCode,
          refetches,
          after,
        },
        queryId,
      ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, queryId, queryCode]);

  useEffect(() => {
    async function doAsyncStuff() {
      setLoading(true);
      setError(null);
      try {
        const result = await apolloClient.query({
          query,
          variables: {
            first,
            after,
            filters: {
              ...filters,
              search: search.current,
            },
            sortBy,
          },
          fetchPolicy,
        });
        const results = result && extract(result.data);
        if (results) {
          hasNextPage.current = results.pageInfo.hasNextPage;
          nextAfter.current = results.pageInfo.endCursor;
          if (after) {
            setData([
              ..._data.current,
              ...results.edges.map((edge) => edge.node),
            ]);
          } else {
            setData(results.edges.map((edge) => edge.node));
          }
        }
      } catch (err) {
        console.error(err);
        setError(err.message);
      }
      setLoading(false);
    }

    const inputs = JSON.stringify({
      refetches,
      after,
      queryCode,
    });
    if (inputs !== last.current) {
      last.current = inputs;
      doAsyncStuff();
    }
  }, [
    filters,
    first,
    setData,
    refetches,
    apolloClient,
    query,
    sortBy,
    extract,
    fetchPolicy,
    queryCode,
    after,
  ]);

  useEffect(() => {
    term$.current = new BehaviorSubject('__init__');
    term$.current
      .pipe(
        autocomplete(100, (term) => {
          if (term != '__init__') {
            hasNextPage.current = null;
            nextAfter.current = null;
            search.current = term;
            dispatch(incrementRemoteQueryAction(queryCode, queryId));
          }
          return of();
        }),
      )
      .subscribe();
  }, [setData, dispatch, queryCode, queryId]);

  const _search = useCallback((term) => {
    if (term !== undefined) {
      term$.current.next(term);
    }
  }, []);

  return {
    data,
    hasNextPage: hasNextPage.current,
    loading,
    error,
    next: () => {
      if (hasNextPage.current) {
        dispatch(nextRemoteQueryAction(queryCode, nextAfter.current, queryId));
      }
    },
    search: _search,
    refetch: () => dispatch(incrementRemoteQueryAction(queryCode, queryId)),
    reset: async () => {
      await apolloClient.resetStore();
      dispatch(incrementRemoteQueryAction(queryCode, queryId));
    },
  };
}
