import React, { useState } from 'react';
import useDataRangeTraces from './use-data-range-traces';
import DatasetRangesPlot from './DatasetRangesPlot';
import SaveAnnotationModal from './SaveAnnotationModal';

function DataRangesTab({ datasetId, campusId, datas }) {
  const rangeTraces = useDataRangeTraces(datas);
  const [annotating, setAnnotating] = useState();
  const onAnnotate = (values) =>
    setAnnotating({ datasetId, campusId, ...values });
  const onCancelAnnotate = () => setAnnotating(undefined);
  const onFinishAnnotate = () => setAnnotating(undefined);
  return (
    <>
      {rangeTraces.map((traces) => (
        <DatasetRangesPlot
          tagId={traces.tagId}
          anchorIds={traces.anchorIds}
          prefix={traces.tagId}
          key={traces.tagId}
          rangeTraces={traces.traces}
          title={traces.title}
          onAnnotate={onAnnotate}
          datasetId={datasetId}
        />
      ))}
      <SaveAnnotationModal
        visible={annotating}
        onCancel={onCancelAnnotate}
        onFinish={onFinishAnnotate}
      />
    </>
  );
}

export default DataRangesTab;
