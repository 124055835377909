import { gql, useSubscription } from '@apollo/client';
import { useEffect, useState } from 'react';

export const NeredeDatasetRecordingAttributes = gql`
  {
    _id
    datasetId
    startedAt
    stoppedAt
  }
`;

export const neredeDatasetRecordingUpdatesSubscription = gql`
  subscription NeredeDatasetRecordingUpdates($campusId: ID!) {
    neredeDatasetRecordingUpdates(campusId: $campusId) ${NeredeDatasetRecordingAttributes}
  }
`;

function useDatasetRecordingUpdates(campusId) {
  const [last, setLast] = useState();
  useEffect(() => {
    setLast(undefined);
  }, [campusId]);
  useSubscription(neredeDatasetRecordingUpdatesSubscription, {
    skip: !campusId,
    variables: {
      campusId,
    },
    fetchPolicy: 'no-cache',
    onData: ({ client, data: result }) => {
      try {
        if (
          result &&
          result.data &&
          result.data.neredeDatasetRecordingUpdates
        ) {
          const update = result.data.neredeDatasetRecordingUpdates;
          setLast(update);
        }
      } catch (err) {
        console.error(err);
      }
    },
  });
  return last;
}

export default useDatasetRecordingUpdates;
