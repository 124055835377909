import React from 'react';
import DatasetAnchorLocsPlot from './DatasetAnchorLocsPlot';
import useDataAnchorLocTraces from './use-data-anchor-loc-traces';

function DataAnchorLocsTab({ datas }) {
  const locTraces = useDataAnchorLocTraces(datas);
  return locTraces.map((traces) => (
    <DatasetAnchorLocsPlot
      prefix={traces.key}
      key={traces.key}
      locTraces={traces.traces}
      title={traces.title}
    />
  ));
}

export default DataAnchorLocsTab;
