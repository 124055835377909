import { gql } from '@apollo/client';

export const ProfileAttributes = gql`
  {
    _id
    username
    name
    email
    phone
    address {
      address1
      address2
      city
      state
      zipCode
      country
    }
    role {
      permissions
    }
    tenantId
    primaryThumbnailUrl
    updatedAt
  }
`;

export const myProfileQuery = gql`
  query MyProfile {
    myProfile ${ProfileAttributes}
  }
`;
