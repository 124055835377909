import { gql, useMutation } from '@apollo/client';
import SendIcon from '@mui/icons-material/Send';
import { Button, CircularProgress } from '@mui/material';
import { showMessageAction } from '@theora360/shared/src/redux-store/snackbar-store';
import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

const requestAnchorConfigurationMutation = gql`
  mutation RequestAnchorConfiguration($_id: ID!) {
    requestAnchorConfiguration(_id: $_id)
  }
`;

function RequestAnchorConfigButton({ _id }) {
  const [loading, setLoading] = useState(false);
  const [requestAnchorConfiguration] = useMutation(
    requestAnchorConfigurationMutation,
  );

  const dispatch = useDispatch();
  const onSubmit = useCallback(async () => {
    setLoading(true);
    try {
      await requestAnchorConfiguration({
        variables: {
          _id,
        },
      });
      dispatch(
        showMessageAction({
          _id: 'req-anchor-config',
          severity: 'success',
          message: 'Request sent!',
        }),
      );
    } catch (err) {
      console.error(err);
      dispatch(
        showMessageAction({
          _id: 'req-anchor-config',
          severity: 'error',
          message: 'There was an error sending request.',
        }),
      );
    }
    setLoading(false);
  }, [_id, dispatch, requestAnchorConfiguration]);

  return (
    <Button
      startIcon={
        loading ? <CircularProgress color="inherit" size={16} /> : <SendIcon />
      }
      disabled={loading}
      variant="contained"
      sx={{ mb: 2 }}
      onClick={onSubmit}
    >
      Request Anchor Configuration
    </Button>
  );
}

export default RequestAnchorConfigButton;
