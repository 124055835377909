import { gql, useSubscription } from '@apollo/client';
import { useRef, useState } from 'react';

export const AnchorNeighborRangeAttributes = gql`
  {
    _id
    anchorId
    neighborId
    campusId
    realDistanceM
    distance
    variance
    updatedAt
  }
`;

export const anchorNeighborRangesSubscription = gql`
  subscription AnchorNeighborRanges($campusId: ID!) {
    anchorNeighborRanges(campusId: $campusId) ${AnchorNeighborRangeAttributes}
  }
`;

function useAnchorNeighborRanges(campusId) {
  const _ranges = useRef({});
  const _neighbors = useRef({});
  const _anchors = useRef({});
  const [ranges, setRanges] = useState(_ranges.current);
  useSubscription(anchorNeighborRangesSubscription, {
    skip: !campusId,
    variables: {
      campusId,
    },
    fetchPolicy: 'no-cache',
    onData: ({ client, data: result }) => {
      try {
        const updates = result?.data?.anchorNeighborRanges;
        if (updates) {
          updates.forEach((update) => {
            _neighbors.current[update.neighborId] = true;
            _anchors.current[update.anchorId] = true;
            _ranges.current = {
              ..._ranges.current,
              [update.anchorId]: {
                ..._ranges.current[update.anchorId],
                [update.neighborId]: update,
              },
            };
          });
          setRanges(_ranges.current);
        }
      } catch (err) {
        console.error(err);
      }
    },
  });
  return {
    ranges,
    anchorIds: Object.keys(_anchors.current).sort(),
    neighborIds: Object.keys(_neighbors.current).sort(),
  };
}

export default useAnchorNeighborRanges;
