import useLocale from '@theora360/shared/src/shared/use-locale';
import React, { useMemo, useState } from 'react';
import settings from '../../../settings';
import { useForm } from 'react-hook-form';
import useFilterDatasetAnnotations from './use-filter-dataset-annotations';
import {
  Box,
  Chip,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import { FormContainer, TextFieldElement } from 'react-hook-form-mui';
import EditIcon from '@mui/icons-material/Edit';
import SaveAnnotationModal from './SaveAnnotationModal';
import { formatDuration } from '@theora360/shared/src/shared/utils';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteAnnotationModal from './DeleteAnnotationModal';
import TipsAndUpdatesOutlinedIcon from '@mui/icons-material/TipsAndUpdatesOutlined';
import AddAnnotationToNeuralNetModal from './AddAnnotationToNeuralNetModal';
import { Link } from 'react-router-dom';

const dense = false;

function DataAnnotationsTab({ datasetId }) {
  const locale = useLocale();
  const dateFormat = useMemo(() => {
    return new Intl.DateTimeFormat(locale, {
      dateStyle: 'short',
      timeStyle: 'medium',
    });
  }, [locale]);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(settings.pageSize);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const form = useForm();
  const searchField = form.watch('search', '');

  const data = useFilterDatasetAnnotations({
    datasetId,
    filters: { search: searchField },
  });
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  const [editing, setEditing] = useState();
  const onCancelEditAnnotation = () => setEditing(undefined);
  const onEditAnnotation = (annotation) => setEditing({ data: annotation });
  const onFinishEditAnnotation = () => setEditing(undefined);
  const [deleting, setDeleting] = useState();
  const onCancelDeleteAnnotation = () => setDeleting(undefined);
  const onDeleteAnnotation = (annotation) => setDeleting(annotation);
  const onFinishDeleteAnnotation = () => setDeleting(undefined);
  const [adding, setAdding] = useState();
  const onCancelAdd = () => setAdding(undefined);
  const onAdd = (annotation) => setAdding({ data: annotation });
  const onFinishAdd = () => setAdding(undefined);

  return (
    <>
      <Box sx={{ mb: 2, display: 'flex', alignItems: 'center' }}>
        <FormContainer formContext={form}>
          <TextFieldElement
            style={{ width: 300, marginBottom: 16 }}
            variant="standard"
            name="search"
            label="Search"
            type="text"
          />
        </FormContainer>
      </Box>
      <Paper>
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="left">Label</TableCell>
                <TableCell align="left">Description</TableCell>
                <TableCell align="left">Tags</TableCell>
                <TableCell align="left">Duration</TableCell>
                <TableCell align="left">Neural Networks</TableCell>
                <TableCell align="left">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row) => (
                <TableRow
                  key={row._id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.label}
                  </TableCell>
                  <TableCell align="left">{row.desc}</TableCell>
                  <TableCell align="left">
                    {row.tags &&
                      row.tags.map((tag) => (
                        <Chip
                          key={tag}
                          label={tag}
                          color="primary"
                          sx={{ mr: 1, mb: 0.5 }}
                        />
                      ))}
                  </TableCell>
                  <TableCell align="left">
                    {formatDuration(row.duration)}
                  </TableCell>
                  {/* <TableCell align="left">
                    {dateFormat.format(new Date(row.updatedAt))}
                  </TableCell> */}
                  <TableCell align="left">
                    {row.neuralNets &&
                      row.neuralNets.map((net) => (
                        <Chip
                          key={net._id}
                          label={net.name}
                          color="secondary"
                          sx={{ mr: 1, mb: 0.5 }}
                          component={Link}
                          to={`/neural-nets/view/${net._id}/annotations`}
                        />
                      ))}
                  </TableCell>
                  <TableCell align="right" width={180}>
                    <IconButton onClick={() => onEditAnnotation(row)}>
                      <EditIcon />
                    </IconButton>
                    <IconButton onClick={() => onAdd(row)}>
                      <TipsAndUpdatesOutlinedIcon />
                    </IconButton>
                    <IconButton onClick={() => onDeleteAnnotation(row)}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[settings.pageSize, settings.largePage]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <SaveAnnotationModal
        visible={editing}
        onFinish={onFinishEditAnnotation}
        onCancel={onCancelEditAnnotation}
      />
      <DeleteAnnotationModal
        deleting={deleting}
        onCancel={onCancelDeleteAnnotation}
        onFinish={onFinishDeleteAnnotation}
      />
      <AddAnnotationToNeuralNetModal
        visible={adding}
        onCancel={onCancelAdd}
        onFinish={onFinishAdd}
      />
    </>
  );
}

export default DataAnnotationsTab;
