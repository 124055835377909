import { gql, useMutation } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import { Box, MenuItem, Modal, Typography } from '@mui/material';
import { showMessageAction } from '@theora360/shared/src/redux-store/snackbar-store';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormContainer, TextFieldElement } from 'react-hook-form-mui';
import { useDispatch } from 'react-redux';

const assignLanTimeslotsMutation = gql`
  mutation AssignLanTimeslots($timeslots: SiteMasterLanTimeslotsInput!) {
    assignLanTimeslots(timeslots: $timeslots)
  }
`;

const TimeslotOptions = [
  {
    label: 'A',
    value: 0,
  },
  {
    label: 'B',
    value: 1,
  },
  {
    label: 'C',
    value: 2,
  },
  {
    label: 'D',
    value: 3,
  },
  {
    label: 'E',
    value: 4,
  },
  {
    label: 'F',
    value: 5,
  },
  {
    label: 'Off',
    value: 255,
  },
];

function SelectTimeslotElement({
  variant,
  name,
  label,
  disabled,
  required,
  style,
}) {
  return (
    <TextFieldElement
      style={style}
      variant={variant}
      name={name}
      label={label}
      type="text"
      disabled={disabled}
      select
    >
      {TimeslotOptions.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </TextFieldElement>
  );
}

function EditLanTimeslotModal({ visible, onCancel, onFinish }) {
  const { data } = visible || {};
  const [loading, setLoading] = useState(false);
  const [assignLanTimeslots] = useMutation(assignLanTimeslotsMutation);
  const form = useForm();

  useEffect(() => {
    if (data) {
      if (data.timeslot >= 0 && data.timeslot <= 5) {
        form.setValue('timeslot', data.timeslot);
      } else {
        form.setValue('timeslot', 255);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const dispatch = useDispatch();
  const onSubmit = useCallback(
    async (values) => {
      const { timeslot } = values;
      const lanTimeslots = Array(100).fill(254);
      lanTimeslots[data.lanNumber - 1] = timeslot;
      setLoading(true);
      try {
        await assignLanTimeslots({
          variables: {
            timeslots: {
              _id: data._id,
              hundreds: 0,
              lanTimeslots,
            },
          },
        });
        dispatch(
          showMessageAction({
            _id: 'edit-lan-timeslots',
            severity: 'success',
            message: 'LAN timeslot saved!',
          }),
        );
        onFinish();
      } catch (err) {
        console.error(err);
        dispatch(
          showMessageAction({
            _id: 'edit-lan-timeslots',
            severity: 'error',
            message: 'There was an error assigning LAN timeslot',
          }),
        );
      }
      setLoading(false);
    },
    [assignLanTimeslots, data, dispatch, onFinish],
  );

  return (
    <Modal
      open={!!visible}
      onClose={onCancel}
      sx={{
        overflow: 'scroll',
        p: 4,
      }}
    >
      <Box
        sx={{
          backgroundColor: 'white',
          textAlign: 'center',
          borderRadius: 1,
          padding: 4,
          marginTop: 4,
          marginBottom: 4,
          width: 500,
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
      >
        <Typography variant="h4" sx={{ mb: 3 }}>
          Assign LAN Timeslot
        </Typography>
        {data && (
          <Typography variant="h5" sx={{ mb: 3 }}>
            {`LAN # ${data.lanNumber}`}
          </Typography>
        )}
        <FormContainer formContext={form} onSuccess={onSubmit}>
          <SelectTimeslotElement
            style={{ marginBottom: 32, width: '100%', textAlign: 'left' }}
            variant="standard"
            name="timeslot"
            label="Timeslot"
            type="text"
            disabled={loading}
            required
          />
          <div style={{ height: 16 }} />
          <Box>
            <LoadingButton type="submit" variant="contained" loading={loading}>
              Assign Timeslot
            </LoadingButton>
          </Box>
        </FormContainer>
      </Box>
    </Modal>
  );
}

export default EditLanTimeslotModal;
