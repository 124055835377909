import { shallowEqual, useSelector } from 'react-redux';
import useSelectedCampusId from './use-selected-campus-id';

function useSelectedCampus() {
  const campusId = useSelectedCampusId();
  const data = useSelector(
    (store) => store.campuses.default && store.campuses.default[campusId],
    shallowEqual,
  );
  return data;
}

export default useSelectedCampus;
