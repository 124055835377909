import React from 'react';
import MainPageTemplate from '../../components/MainPageTemplate';

function DashboardPage() {
  return (
    <MainPageTemplate title="Home">
      <div>Dashboard</div>
    </MainPageTemplate>
  );
}

export default DashboardPage;
