import React, { useCallback, useRef, useState } from 'react';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { Controller, useForm } from 'react-hook-form';
import { TextField } from '@mui/material';

const filter = createFilterOptions();

function TagsElement({
  variant = 'standard',
  name,
  label,
  disabled,
  required = false,
  style,
  form,
  control,
}) {
  // const _options = useRef([]);
  // const ref = useRef();
  // const [options, setOptions] = useState(_options.current);
  // const onKeyDown = useCallback((e, v) => {
  //   console.log('onChange', ref);
  //   if (e.key === 'Enter') {
  //     const newTag = e.target.value.trim();
  //     if (!_options.current.find((i) => i.id === newTag)) {
  //       _options.current = [
  //         ..._options.current,
  //         {
  //           id: newTag,
  //           label: newTag,
  //         },
  //       ];
  //       setOptions(_options.current);
  //     }
  //     e.preventDefault();
  //   }
  //   // setValue(e.target.value);
  //   // setOptions([..._options.current, e.target.value.trim()]);
  // }, []);
  return (
    <Controller
      control={form.control}
      name={name}
      rules={{
        required,
      }}
      defaultValue={[]}
      render={({
        field: { value, onChange, onBlur, ref },
        fieldState: { error },
      }) => (
        <Autocomplete
          value={value}
          multiple
          required={required}
          onChange={(event, newValue) => {
            onChange(
              newValue.reduce((prev, curr) => {
                if (curr.inputValue) {
                  return [...prev, { title: curr.inputValue }];
                }
                return [...prev, curr];
              }, []),
            );
            // console.log('value', value);
            // console.log('newValue', newValue);
            // if (newValue && newValue[0].inputValue) {
            //   onChange([
            //     ...(value || []),
            //     {
            //       title: newValue[0].inputValue,
            //     },
            //   ]);
            // } else {
            //   onChange([...(value || []), newValue[0]]);
            // }
          }}
          filterOptions={(options, params) => {
            const filtered = filter(options, params);

            const { inputValue } = params;
            // Suggest the creation of a new value
            const isExisting = options.some(
              (option) => inputValue === option.title,
            );
            if (inputValue !== '' && !isExisting) {
              filtered.push({
                inputValue,
                title: `Add "${inputValue}"`,
              });
            }

            return filtered;
          }}
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          options={[]}
          getOptionLabel={(option) => {
            // Value selected with enter, right from the input
            if (typeof option === 'string') {
              return option;
            }
            // Add "xxx" option created dynamically
            if (option.inputValue) {
              return option.inputValue;
            }
            // Regular option
            return option.title;
          }}
          renderOption={(props, option) => <li {...props}>{option.title}</li>}
          style={style}
          disabled={disabled}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              variant={variant}
              name={name}
            />
          )}
        />
      )}
    />
  );
}

export default TagsElement;
