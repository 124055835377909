import { LoadingButton } from '@mui/lab';
import { Box, Button, Modal, Typography } from '@mui/material';
import React, { useCallback, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { gql, useMutation } from '@apollo/client';
import { showMessageAction } from '@theora360/shared/src/redux-store/snackbar-store';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const deleteCampusMutation = gql`
  mutation DeleteCampus($_id: ID!) {
    deleteCampus(_id: $_id) {
      deletedId
    }
  }
`;

function DeleteCampusModal({ data }) {
  const [deleting, setDeleting] = useState(false);
  const [loading, setLoading] = useState(false);
  const onCancel = () => setDeleting(false);
  const onDelete = () => setDeleting(true);

  const [deleteCampus] = useMutation(deleteCampusMutation);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const onSubmit = useCallback(async () => {
    setLoading(true);
    try {
      const resp1 = await deleteCampus({
        variables: {
          _id: data._id,
        },
      });
      dispatch(
        showMessageAction({
          _id: 'delete-campus',
          severity: 'success',
          message: 'Campus deleted!',
        }),
      );
      navigate('/campuses');
    } catch (err) {
      console.error(err);
      dispatch(
        showMessageAction({
          _id: 'delete-campus',
          severity: 'error',
          message: 'There was an error deleting campus',
        }),
      );
      setLoading(false);
    }
  }, [deleteCampus, data, navigate, dispatch]);
  return (
    <>
      <Modal
        open={deleting}
        onClose={onCancel}
        sx={{
          overflow: 'scroll',
          p: 4,
        }}
      >
        <Box
          sx={{
            backgroundColor: 'white',
            textAlign: 'center',
            borderRadius: 1,
            padding: 4,
            marginTop: 4,
            marginBottom: 4,
            width: 500,
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        >
          <Typography variant="h4" sx={{ mb: 3 }}>
            Delete Campus
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            Are you sure you want to delete this campus?
          </Typography>
          <Typography variant="body1" sx={{ mb: 4 }}>
            {data && data.label}
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Button sx={{ mr: 4 }} onClick={onCancel}>
              Cancel
            </Button>
            <LoadingButton
              variant="contained"
              loading={loading}
              onClick={onSubmit}
            >
              Delete
            </LoadingButton>
          </Box>
        </Box>
      </Modal>
      <Button
        startIcon={<DeleteIcon />}
        variant="text"
        color="error"
        onClick={onDelete}
      >
        Delete Campus
      </Button>
    </>
  );
}

export default DeleteCampusModal;
