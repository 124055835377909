import DevicesIcon from '@mui/icons-material/Devices';
import { Box, Grid, Paper, Typography } from '@mui/material';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import useSelectedCampusId from '../../../shared/use-selected-campus-id';
import useFilterAnchors from '../anchors/use-filter-anchors';
import useFiltersiteMasters from '../site-masters/use-filter-site-masters';
import useFilterTags from '../tags/use-filter-tags';
import useAnchorLastUpdates from '../anchors/use-anchor-last';
import { formatDuration } from '@theora360/shared/src/shared/utils';
import settings from '../../../settings';
import useSiteMasterLastUpdates from '../site-masters/use-site-master-last';
import useTagLastUpdates from '../tags/use-tag-last';
import { Link } from 'react-router-dom';
import useProfile from '../../../shared/use-profile';
import useSafeState from '../../../shared/use-safe-state';

function AnchorCard({ anchor, refreshes, doLink }) {
  const last = useAnchorLastUpdates(anchor._id);
  const elapsed = useMemo(() => {
    if (last) {
      let a = new Date(last.updatedAt);
      let b = new Date();
      let _elapsed = Math.round((b.getTime() - a.getTime()) / 1000);
      if (_elapsed <= 0) {
        return 'Just now';
      }
      return `${formatDuration(_elapsed)} ago`;
    }
    return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [last, refreshes]);

  return (
    <Grid item xs={4} lg={3}>
      <Paper sx={{ padding: 2 }}>
        <Typography
          component={doLink ? Link : undefined}
          to={`/devices/anchors/${anchor._id}/status`}
          variant="body1"
          sx={{
            color: settings.colors.darkOrange,
            fontSize: 16,
            fontWeight: 600,
          }}
        >{`Anchor (${anchor._id})`}</Typography>
        {elapsed !== undefined && (
          <Typography variant="body1">{elapsed}</Typography>
        )}
      </Paper>
    </Grid>
  );
}

function SiteMasterCard({ siteMaster, refreshes }) {
  const last = useSiteMasterLastUpdates(siteMaster._id);
  const elapsed = useMemo(() => {
    if (last) {
      let a = new Date(last.updatedAt);
      let b = new Date();
      let _elapsed = Math.round((b.getTime() - a.getTime()) / 1000);
      if (_elapsed <= 0) {
        return 'Just now';
      }
      return `${formatDuration(_elapsed)} ago`;
    }
    return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [last, refreshes]);

  return (
    <Grid item xs={4} lg={3}>
      <Paper sx={{ padding: 2 }}>
        <Typography
          variant="body1"
          sx={{
            color: settings.colors.darkOrange,
            fontSize: 16,
            fontWeight: 600,
          }}
        >{`Site Master (${siteMaster._id})`}</Typography>
        {elapsed !== undefined && (
          <Typography variant="body1">{`Last comms: ${elapsed}`}</Typography>
        )}
      </Paper>
    </Grid>
  );
}

function TagCard({ tag, refreshes, doLink }) {
  const last = useTagLastUpdates(tag._id);
  const elapsed = useMemo(() => {
    if (last) {
      let a = new Date(last.updatedAt);
      let b = new Date();
      let _elapsed = Math.round((b.getTime() - a.getTime()) / 1000);
      if (_elapsed <= 0) {
        return 'Just now';
      }
      return `${formatDuration(_elapsed)} ago`;
    }
    return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [last, refreshes]);

  return (
    <Grid item xs={4} lg={3}>
      <Paper sx={{ padding: 2 }}>
        <Typography
          component={doLink ? Link : undefined}
          to={`/devices/tags/${tag._id}/status`}
          variant="body1"
          sx={{
            color: settings.colors.darkOrange,
            fontSize: 16,
            fontWeight: 600,
          }}
        >{`Tag (${tag._id})`}</Typography>
        {elapsed !== undefined && (
          <Typography variant="body1">{elapsed}</Typography>
        )}
      </Paper>
    </Grid>
  );
}

function DevicesTab() {
  const [refreshes, setRefreshes, _refreshes] = useSafeState(0);
  useEffect(() => {
    const i = setInterval(() => {
      setRefreshes(_refreshes.current + 1);
    }, 1000);
    return () => clearInterval(i);
  }, [setRefreshes, _refreshes]);

  const campusId = useSelectedCampusId();
  const anchors = useFilterAnchors({
    campusId,
    filters: {},
  });

  // const siteMasters = useFiltersiteMasters({
  //   campusId,
  //   filters: {},
  // });
  const tags = useFilterTags({
    campusId,
    filters: {},
  });
  const profile = useProfile();
  const doAnchorLinks =
    profile?.role?.permissions?.includes('list-anchors:all');
  const doTagLinks = profile?.role?.permissions?.includes('list-tags:all');

  return (
    <Box style={{ marginBottom: 32 }}>
      <Typography variant="h5" sx={{ mt: 0, mb: 2 }}>
        <DevicesIcon style={{ height: 40, width: 40 }} /> Devices
      </Typography>
      <Grid container spacing={2}>
        {anchors.map((anchor) => (
          <AnchorCard
            key={anchor._id}
            anchor={anchor}
            refreshes={refreshes}
            doLink={doAnchorLinks}
          />
        ))}
        {/* {siteMasters.map((siteMaster) => (
          <SiteMasterCard siteMaster={siteMaster} refreshes={refreshes} />
        ))} */}
        {tags.map((tag) => (
          <TagCard
            key={tag._id}
            tag={tag}
            refreshes={refreshes}
            doLink={doTagLinks}
          />
        ))}
      </Grid>
    </Box>
  );
}

export default DevicesTab;
