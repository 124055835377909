import EditIcon from '@mui/icons-material/Edit';
import {
  Box,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import useLocale from '@theora360/shared/src/shared/use-locale';
import React, { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormContainer, TextFieldElement } from 'react-hook-form-mui';
import { Link } from 'react-router-dom';
import settings from '../../../settings';
import useSelectedCampusId from '../../../shared/use-selected-campus-id';
import EditSiteMasterModal from './EditSiteMasterModal';
import useFilterSiteMasters from './use-filter-site-masters';
import VisibilityIcon from '@mui/icons-material/Visibility';

const dense = false;

function SiteMastersTab() {
  const locale = useLocale();
  const dateFormat = useMemo(() => {
    return new Intl.DateTimeFormat(locale, {
      dateStyle: 'short',
      timeStyle: 'medium',
    });
  }, [locale]);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(settings.pageSize);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const form = useForm();
  const searchField = form.watch('search', '');

  const campusId = useSelectedCampusId();
  const data = useFilterSiteMasters({
    campusId,
    filters: { search: searchField },
  });
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  const [editing, setEditing] = useState();
  const onCancelEditSiteMaster = () => setEditing(undefined);
  const onEditSiteMaster = (siteMaster) => setEditing({ data: siteMaster });
  const onFinishEditSiteMaster = () => setEditing(undefined);

  return (
    <>
      <Box sx={{ mb: 2, display: 'flex', alignItems: 'center' }}>
        <FormContainer formContext={form}>
          <TextFieldElement
            style={{ width: 300, marginBottom: 16 }}
            variant="standard"
            name="search"
            label="Search"
            type="text"
          />
        </FormContainer>
      </Box>
      <Paper>
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="left">Label</TableCell>
                <TableCell align="left">IP Address</TableCell>
                <TableCell align="left">Description</TableCell>
                <TableCell align="left">Updated At</TableCell>
                <TableCell align="left">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row) => (
                <TableRow
                  key={row._id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.label}
                  </TableCell>
                  <TableCell align="left">
                    {row.address && row.address.host}
                  </TableCell>
                  <TableCell align="left">{row.desc}</TableCell>
                  <TableCell align="left">
                    {dateFormat.format(new Date(row.updatedAt))}
                  </TableCell>
                  <TableCell align="right" width={120}>
                    <IconButton
                      component={Link}
                      to={`/devices/site-masters/${row._id}/fw-status`}
                      sx={{ mr: 1 }}
                    >
                      <VisibilityIcon />
                    </IconButton>
                    <IconButton onClick={() => onEditSiteMaster(row)}>
                      <EditIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[settings.pageSize, settings.largePage]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <EditSiteMasterModal
        visible={editing}
        onFinish={onFinishEditSiteMaster}
        onCancel={onCancelEditSiteMaster}
      />
    </>
  );
}

export default SiteMastersTab;
