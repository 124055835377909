import { useMemo } from 'react';
import { anchorColors } from '../demo/constants';

function useDataRangeTraces(datas) {
  return useMemo(() => {
    const allData = {};
    const allTagIds = {};
    const allAnchorIds = {};
    const allTraces = [];
    let startTimestamp;
    if (datas) {
      datas.forEach((data) => {
        if (data.type === 'AR') {
          if (!startTimestamp) {
            startTimestamp = data.timestampMs / 1000;
          }
          allTagIds[data.tagId] = true;
          allAnchorIds[data.anchorId] = true;
          if (!allData[data.tagId]) {
            allData[data.tagId] = {
              tagId: data.tagId,
              name: `Tag ${data.tagId}`,
              anchors: {},
            };
          }
          if (!allData[data.tagId][data.anchorId]) {
            allData[data.tagId][data.anchorId] = {
              anchorId: data.anchorId,
              name: `Anchor ${data.anchorId}`,
              t: [],
              x: [],
              y: [],
            };
          }
          allData[data.tagId][data.anchorId].x.push(
            data.timestampMs / 1000 - startTimestamp,
          );
          allData[data.tagId][data.anchorId].y.push(data.anchorRange);
          allData[data.tagId][data.anchorId].t.push(data.timestampMs);
        }
      });
      const tagIds = Object.keys(allTagIds).sort((a, b) => a.localeCompare(b));
      const anchorIds = Object.keys(allAnchorIds).sort((a, b) =>
        a.localeCompare(b),
      );

      tagIds.forEach((tagId) => {
        const traces = anchorIds.map((anchorId, index) => ({
          t: allData[tagId]?.[anchorId]?.t,
          x: allData[tagId]?.[anchorId]?.x,
          y: allData[tagId]?.[anchorId]?.y,
          name: allData[tagId]?.[anchorId]?.name,
          mode: 'lines+markers',
          color: anchorColors[index % anchorColors.length],
          type: 'scatter',
        }));
        allTraces.push({
          tagId,
          anchorIds,
          title: allData[tagId].name,
          traces,
        });
      });
    }
    return allTraces;
  }, [datas]);
}

export default useDataRangeTraces;
