import StraightenIcon from '@mui/icons-material/Straighten';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { formatDuration } from '@theora360/shared/src/shared/utils';
import React, { useMemo } from 'react';

function AiTagLocTable({ aiTagLocations, anchors, refreshes }) {
  const anchorIds = useMemo(
    () => anchors.filter((a) => a).map((a) => a._id),
    [anchors],
  );
  const mat = useMemo(() => {
    const _mat = [];
    const now = new Date().getTime() / 1000;
    aiTagLocations.forEach((tagLoc) => {
      const timestamp = tagLoc.epochTimestamp / 1000.0;
      _mat.push({
        tagId: tagLoc.tagId,
        epochTimestamp: timestamp,
        elapsed: now - timestamp,
        updatedAt: tagLoc.updatedAt,
        items: Array(anchorIds.length).fill(undefined),
        label: tagLoc.label,
      });
    });
    aiTagLocations.forEach((tagLoc, i) => {
      tagLoc.anchorIds.forEach((anchorId, j) => {
        _mat[i].items[anchorIds.indexOf(anchorId)] = {
          distance: tagLoc.distances?.[j],
        };
      });
    });
    return _mat;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshes, aiTagLocations, anchorIds]);

  return (
    <>
      <Typography variant="h5" sx={{ mt: 0, mb: 2 }}>
        <StraightenIcon style={{ height: 40, width: 40 }} /> Tag Ranges
      </Typography>
      <Paper>
        <TableContainer>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell align="left">Tag</TableCell>
                <TableCell align="left">Time</TableCell>
                {anchorIds.map((anchorId) => (
                  <TableCell key={anchorId} align="left">
                    {`Anchor (${anchorId})`}
                  </TableCell>
                ))}
                <TableCell align="left">Tag Location</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {mat.map((row, index) => (
                <TableRow
                  key={row.tagId}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {`Tag (${row.tagId})`}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {`${formatDuration(row.elapsed)} ago`}
                  </TableCell>
                  {row.items.map((update, index2) => (
                    <TableCell key={anchorIds[index2]} align="left">
                      {update && (
                        <Typography sx={{ fontSize: 18 }}>
                          {update.distance}
                        </Typography>
                      )}
                    </TableCell>
                  ))}
                  <TableCell component="th" scope="row">
                    <Typography sx={{ fontWeight: 600, fontSize: 18 }}>
                      {row.label}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
}

export default AiTagLocTable;
