export const tagColors = [
  '#ff4d4f',
  '#bae637',
  '#ff7a45',
  '#73d13d',
  '#ffa940',
  '#36cfc9',
  '#ffc53d',
  '#40a9ff',
  '#ffec3d',
  '#597ef7',
  '#9254de',
  '#f759ab',
  '#a8071a',
  '#ad2102',
];

export const anchorColors = [
  '#73d13d',
  '#36cfc9',
  '#4096ff',
  '#597ef7',
  '#9254de',
  '#f759ab',
  '#389e0d',
  '#08979c',
  '#0958d9',
  '#1d39c4',
  '#531dab',
  '#c41d7f',
];

const SPEED_OF_LIGHT = 299702547;
const DWT_TIME_UNITS = 1.0 / 499.2e6 / 128.0;
export const TICKS_TO_METERS = (DWT_TIME_UNITS * SPEED_OF_LIGHT) / 2;
