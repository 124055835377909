import React, { useEffect, useRef, useState } from 'react';
import useAnchorStdoutUpdates from './use-anchor-stdout-updates';
import useSafeState from '../../../shared/use-safe-state';
import { Switch, Typography } from '@mui/material';

function AnchorStdoutTerm({ _id }) {
  const stdout = useAnchorStdoutUpdates(_id);
  const [count, setCount, _count] = useSafeState(0);
  const last = useRef();
  const lines = useRef([]);
  const bottom = useRef();
  const container = useRef();
  const [autoScroll, setAutoScroll] = useState(true);
  useEffect(() => {
    if (stdout && stdout.timestamp !== last.current) {
      last.current = stdout.timestamp;
      stdout.lines.forEach((line) => {
        lines.current.push(line);
      });
      setCount(_count.current + 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stdout]);

  useEffect(() => {
    if (autoScroll) {
      if (container.current && bottom.current) {
        container.current.scrollTop = bottom.current.offsetHeight;
      }
    }
  }, [count, autoScroll]);

  return (
    <>
      <div
        style={{
          marginBottom: 8,
          display: 'flex',
          flexDirection: 'row-reverse',
        }}
      >
        <map onClick={() => setAutoScroll(!autoScroll)}>
          <Switch
            checked={autoScroll}
            onChange={(e) => {
              setAutoScroll(e.target.checked);
            }}
            sx={{}}
          />
          <span>Autoscroll</span>
        </map>
      </div>
      <div
        ref={container}
        style={{
          height: 600,
          overflow: 'scroll',
          backgroundColor: '#181818',
          color: 'white',
          paddingRight: 16,
          paddingLeft: 16,
          paddingBottom: 16,
          paddingTop: 16,
          borderRadius: 4,
          fontFamily: 'monospace',
          fontSize: 14,
        }}
      >
        <div ref={bottom}>
          {lines.current.map((line) => (
            <>
              <span>{line}</span>
              <br />
            </>
          ))}
        </div>
        <div />
      </div>
    </>
  );
}

export default AnchorStdoutTerm;
