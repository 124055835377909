import { gql } from '@apollo/client';
import EditIcon from '@mui/icons-material/Edit';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import {
  Box,
  Chip,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import useLocale from '@theora360/shared/src/shared/use-locale';
import { useManyRemote } from '@theora360/shared/src/shared/use-many-remote';
import { formatDuration } from '@theora360/shared/src/shared/utils';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormContainer, TextFieldElement } from 'react-hook-form-mui';
import settings from '../../../settings';
import SaveAnnotationModal from '../datasets/SaveAnnotationModal';
import { NeredeAnnotationAttributes } from '../datasets/constants';
import RemoveAnnotationFromNeuralNetModal from './RemoveAnnotationFromNeuralNetModal';
import StorageIcon from '@mui/icons-material/Storage';
import { Link } from 'react-router-dom';

export const allNeredeAnnotationsQuery = gql`
  query AllNeredeAnnotations($first: Int, $after: String, $filters: NeredeAnnotationFilters, $sortBy: [SortBy]) {
    allNeredeAnnotations(first: $first, after: $after, filters: $filters, sortBy: $sortBy) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node ${NeredeAnnotationAttributes}
      }
    }
  }
`;

export const extractAnnotations = (data) => data && data.allNeredeAnnotations;
const dense = false;

function NetAnnotationsTab({ netId, neuralNet }) {
  const filters = useRef({
    netId,
  });
  const sortBy = useRef([{ key: 'updatedAt', order: 'DESC' }]);
  const {
    error,
    loading,
    data,
    search: dataSearch,
    hasNextPage,
    next,
    refetch,
    reset,
  } = useManyRemote({
    query: allNeredeAnnotationsQuery,
    extract: extractAnnotations,
    first: settings.querySize,
    filters: filters.current,
    sortBy: sortBy.current,
    fetchPolicy: 'network-only',
    queryId: netId,
  });

  const locale = useLocale();
  const dateFormat = useMemo(() => {
    return new Intl.DateTimeFormat(locale, {
      dateStyle: 'short',
      timeStyle: 'medium',
    });
  }, [locale]);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(settings.pageSize);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const form = useForm();
  const searchField = form.watch('search', '');
  useEffect(() => {
    if (searchField) {
      dataSearch(searchField);
    }
  }, [dataSearch, searchField]);

  // const data = useFilterNetAnnotations({
  //   netId,
  //   filters: { search: searchField },
  // });
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  const [editing, setEditing] = useState();
  const onCancelEdit = () => setEditing(undefined);
  const onEdit = (annotation) => setEditing({ data: annotation });
  const onFinishEdit = () => setEditing(undefined);
  const [removing, setRemoving] = useState();
  const onCancelRemove = () => setRemoving(undefined);
  const onRemove = (annotation) => setRemoving({ annotation, neuralNet });
  const onFinishRemove = () => {
    setRemoving(undefined);
    refetch();
  };

  return (
    <>
      <Box sx={{ mt: 2, mb: 2, display: 'flex', alignItems: 'center' }}>
        <FormContainer formContext={form}>
          <TextFieldElement
            style={{ width: 300, marginBottom: 16 }}
            variant="standard"
            name="search"
            label="Search"
            type="text"
          />
        </FormContainer>
      </Box>
      <Paper>
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="left">Label</TableCell>
                <TableCell align="left">Description</TableCell>
                <TableCell align="left">Tags</TableCell>
                <TableCell align="left">Duration</TableCell>
                <TableCell align="left">Updated At</TableCell>
                <TableCell align="left">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row) => (
                <TableRow
                  key={row._id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.label}
                  </TableCell>
                  <TableCell align="left">{row.desc}</TableCell>
                  <TableCell align="left">
                    {row.tags &&
                      row.tags.map((tag) => (
                        <Chip
                          key={tag}
                          label={tag}
                          color="primary"
                          sx={{ mr: 1, mb: 0.5 }}
                        />
                      ))}
                  </TableCell>
                  <TableCell align="left">
                    {formatDuration(row.duration)}
                  </TableCell>
                  <TableCell align="left">
                    {dateFormat.format(new Date(row.updatedAt))}
                  </TableCell>
                  <TableCell align="right" width={180}>
                    <IconButton
                      component={Link}
                      to={`/datasets/view/${row.datasetId}/annotations`}
                    >
                      <StorageIcon />
                    </IconButton>
                    <IconButton onClick={() => onEdit(row)}>
                      <EditIcon />
                    </IconButton>
                    <IconButton onClick={() => onRemove(row)}>
                      <RemoveCircleOutlineOutlinedIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[settings.pageSize, settings.largePage]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <SaveAnnotationModal
        visible={editing}
        onFinish={onFinishEdit}
        onCancel={onCancelEdit}
      />
      <RemoveAnnotationFromNeuralNetModal
        visible={removing}
        onCancel={onCancelRemove}
        onFinish={onFinishRemove}
      />
    </>
  );
}

export default NetAnnotationsTab;
