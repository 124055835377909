import { gql, useSubscription } from '@apollo/client';
import { useState } from 'react';

export const TagLastAttributes = gql`
  {
    _id
    campusId
    updatedAt
  }
`;

export const tagLastUpdatesSubscription = gql`
  subscription TagLastUpdates($tagId: ID!) {
    tagLastUpdates(tagId: $tagId) ${TagLastAttributes}
  }
`;

function useTagLastUpdates(tagId) {
  const [last, setLast] = useState();
  useSubscription(tagLastUpdatesSubscription, {
    skip: !tagId,
    variables: {
      tagId,
    },
    fetchPolicy: 'no-cache',
    onData: ({ client, data: result }) => {
      try {
        if (result && result.data && result.data.tagLastUpdates) {
          const update = result.data.tagLastUpdates;
          setLast(update);
        }
      } catch (err) {
        console.error(err);
      }
    },
  });
  return last;
}

export default useTagLastUpdates;
