import { gql, useMutation } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import { Box, Modal, Typography } from '@mui/material';
import { showMessageAction } from '@theora360/shared/src/redux-store/snackbar-store';
import { generateUuid } from '@theora360/shared/src/shared/utils';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  FormContainer,
  SelectElement,
  TextFieldElement,
} from 'react-hook-form-mui';
import { useDispatch } from 'react-redux';
import { NeuralNetTypes } from './constants';
import TagsElement from '../../../components/TagsElement';

const updateNeuralNetMutation = gql`
  mutation UpdateNeuralNet($neuralNet: NeuralNetUpdateInput!) {
    updateNeuralNet(neuralNet: $neuralNet) {
      neuralNet {
        _id
      }
    }
  }
`;

const createNeuralNetMutation = gql`
  mutation CreateNeuralNet($neuralNet: NeuralNetCreateInput!) {
    createNeuralNet(neuralNet: $neuralNet) {
      neuralNet {
        _id
      }
    }
  }
`;

function CreateNeuralNetModal({ visible, onCancel, onFinish }) {
  const { data, campusId } = visible || {};
  const [loading, setLoading] = useState(false);
  const [updateNeuralNet] = useMutation(updateNeuralNetMutation);
  const [createNeuralNet] = useMutation(createNeuralNetMutation);
  const form = useForm();

  useEffect(() => {
    if (data) {
      form.setValue('name', data.name);
      form.setValue('type', data.type);
      form.setValue('desc', data.desc);
      form.setValue(
        'tags',
        data.tags.map((t) => ({ title: t })),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const dispatch = useDispatch();
  const onSubmit = useCallback(
    async (_values) => {
      const { tags: _tags, ...values } = _values;
      const tags = (_tags || []).map((t) => t.title);
      setLoading(true);
      let netId;
      try {
        if (data) {
          netId = data._id;
          await updateNeuralNet({
            variables: {
              neuralNet: {
                _id: data._id,
                tags,
                ...values,
              },
            },
          });
        } else {
          netId = generateUuid();
          await createNeuralNet({
            variables: {
              neuralNet: {
                _id: netId,
                campusId,
                tags,
                ...values,
              },
            },
          });
        }

        dispatch(
          showMessageAction({
            _id: 'edit-neural-net',
            severity: 'success',
            message: 'Neural Network saved!',
          }),
        );
        onFinish(netId);
      } catch (err) {
        console.error(err);
        dispatch(
          showMessageAction({
            _id: 'edit-neural-net',
            severity: 'error',
            message: 'There was an error saving neuralNet',
          }),
        );
      }
      setLoading(false);
    },
    [updateNeuralNet, createNeuralNet, data, dispatch, onFinish, campusId],
  );

  return (
    <Modal
      open={!!visible}
      onClose={onCancel}
      sx={{
        overflow: 'scroll',
        p: 4,
      }}
    >
      <Box
        sx={{
          backgroundColor: 'white',
          textAlign: 'center',
          borderRadius: 1,
          padding: 4,
          marginTop: 4,
          marginBottom: 4,
          width: 500,
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
      >
        <Typography variant="h4" sx={{ mb: 3 }}>
          {data ? 'Edit Neural Network' : 'Create Neural Network'}
        </Typography>
        <FormContainer formContext={form} onSuccess={onSubmit}>
          <TextFieldElement
            style={{ marginBottom: 32, width: '100%' }}
            variant="standard"
            name="name"
            label="Name"
            type="text"
            disabled={loading}
            required
          />
          <SelectElement
            label="Type"
            name="type"
            options={Object.values(NeuralNetTypes).map((c) => ({
              id: c.key,
              label: c.label,
            }))}
            variant="standard"
            style={{ marginBottom: 32, width: '100%', textAlign: 'left' }}
            required
            disabled={loading}
          />
          <TextFieldElement
            style={{ marginBottom: 32, width: '100%' }}
            variant="standard"
            name="desc"
            label="Description"
            type="text"
            disabled={loading}
          />
          <TagsElement
            style={{ marginBottom: 32, width: '100%' }}
            variant="standard"
            name="tags"
            label="Tags"
            disabled={loading}
            form={form}
          />
          <div style={{ height: 16 }} />
          <Box>
            <LoadingButton type="submit" variant="contained" loading={loading}>
              Save
            </LoadingButton>
          </Box>
        </FormContainer>
      </Box>
    </Modal>
  );
}

export default CreateNeuralNetModal;
