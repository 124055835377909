import short from 'short-uuid';
import { phone } from 'phone';

export function generateUuid() {
  const translator = short();
  return translator.new();
}

export const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const hashCode = (s) =>
  Array.from(s).reduce((prev, curr) => {
    const hash = (prev << 5) - prev + curr.charCodeAt(0);
    return hash & hash;
  }, 0);

export function formatPhoneNumber(unformattedPhone) {
  let formattedPhone;
  if (unformattedPhone) {
    formattedPhone = phone(unformattedPhone);
    if (formattedPhone.isValid) {
      return formattedPhone.phoneNumber;
    }
  }
  return null;
}

export function formatDuration(seconds) {
  var time = { year: 31536000, day: 86400, hour: 3600, minute: 60, second: 1 },
    res = [];

  if (seconds < 1) return '0 seconds';

  for (var key in time) {
    if (seconds >= time[key]) {
      var val = Math.floor(seconds / time[key]);
      res.push((val += val > 1 ? ' ' + key + 's' : ' ' + key));
      seconds = seconds % time[key];
    }
  }

  return res.length > 1
    ? res.join(', ').replace(/,([^,]*)$/, ' and' + '$1')
    : res[0];
}
