import { gql, useSubscription } from '@apollo/client';

export const NeuralNetTrainingAttributes = gql`
  {
    _id
    msg
    status
    step
    fitLogs
    evalLogs
    savedModelId
    savedModelName
    updatedAt
  }
`;

export const neuralNetTrainingUpdatesSubscription = gql`
  subscription NeuralNetTrainingUpdates($netId: ID!) {
    neuralNetTrainingUpdates(netId: $netId) ${NeuralNetTrainingAttributes}
  }
`;

function useNeuralNetTrainingUpdates(netId, setUpdate) {
  useSubscription(neuralNetTrainingUpdatesSubscription, {
    skip: !netId,
    variables: {
      netId,
    },
    fetchPolicy: 'no-cache',
    onData: ({ client, data: result }) => {
      try {
        if (result && result.data && result.data.neuralNetTrainingUpdates) {
          const update = result.data.neuralNetTrainingUpdates;
          setUpdate(update);
        }
      } catch (err) {
        console.error(err);
      }
    },
  });
}

export default useNeuralNetTrainingUpdates;
