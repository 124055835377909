import { gql, useMutation } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import { Box, Modal, TextField, Typography } from '@mui/material';
import { AnchorAttributes } from '@theora360/shared/src/pages/anchors/use-anchor-updates';
import { showMessageAction } from '@theora360/shared/src/redux-store/snackbar-store';
import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { FormContainer, TextFieldElement } from 'react-hook-form-mui';
import { useDispatch } from 'react-redux';
import SelectCampusFieldElement from '../../../components/SelectCampusFieldElement';

const updateAnchorMutation = gql`
  mutation UpdateAnchor($anchor: AnchorUpdateInput!) {
    updateAnchor(anchor: $anchor) {
      anchor ${AnchorAttributes}
    }
  }
`;

const createAnchorMutation = gql`
  mutation CreateAnchor($anchor: AnchorCreateInput!) {
    createAnchor(anchor: $anchor) {
      anchor ${AnchorAttributes}
    }
  }
`;

export const anchorQuery = gql`
  query Anchor($_id: ID!) {
    anchor(_id: $_id) ${AnchorAttributes}
  }
`;

function EditAnchorModal({ visible, onCancel, onFinish }) {
  const { data } = visible || {};
  const [loading, setLoading] = useState(false);
  const [updateAnchor] = useMutation(updateAnchorMutation);
  const [createAnchor] = useMutation(createAnchorMutation);
  const form = useForm();
  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray(
    {
      control: form.control,
      name: 'location',
    },
  );

  useEffect(() => {
    if (data) {
      form.setValue('label', data.label);
      form.setValue('desc', data.desc);
      form.setValue('campusId', data.campusId);
      if (data.location) {
        form.setValue('location', [
          { value: data.location[0] },
          { value: data.location[1] },
          { value: data.location[2] },
        ]);
      } else {
        form.setValue('location', [{ value: 0 }, { value: 0 }, { value: 0 }]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const dispatch = useDispatch();
  const onSubmit = useCallback(
    async (_values) => {
      const { location, ...values } = _values;
      setLoading(true);
      try {
        const resp1 = await updateAnchor({
          variables: {
            anchor: {
              _id: data._id,
              location: [
                parseFloat(location[0].value),
                parseFloat(location[1].value),
                parseFloat(location[2].value),
              ],
              ...values,
            },
          },
        });
        dispatch(
          showMessageAction({
            _id: 'edit-anchor',
            severity: 'success',
            message: 'Anchor saved!',
          }),
        );
        onFinish();
      } catch (err) {
        console.error(err);
        dispatch(
          showMessageAction({
            _id: 'edit-anchor',
            severity: 'error',
            message: 'There was an error saving anchor',
          }),
        );
      }
      setLoading(false);
    },
    [updateAnchor, data, dispatch, onFinish],
  );

  return (
    <Modal
      open={!!visible}
      onClose={onCancel}
      sx={{
        overflow: 'scroll',
        p: 4,
      }}
    >
      <Box
        sx={{
          backgroundColor: 'white',
          textAlign: 'center',
          borderRadius: 1,
          padding: 4,
          marginTop: 4,
          marginBottom: 4,
          width: 500,
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
      >
        <Typography variant="h4" sx={{ mb: 3 }}>
          Edit Anchor
        </Typography>
        <FormContainer formContext={form} onSuccess={onSubmit}>
          <SelectCampusFieldElement />
          <TextFieldElement
            style={{ marginBottom: 32, width: '100%' }}
            variant="standard"
            name="label"
            label="Label"
            type="text"
            disabled={loading}
            required
          />
          <TextFieldElement
            style={{ marginBottom: 32, width: '100%' }}
            variant="standard"
            name="desc"
            label="Description"
            type="text"
            disabled={loading}
          />
          <div style={{ display: 'flex' }}>
            {fields.map((field, index) => (
              <Controller
                key={field.id}
                control={form.control}
                name={`location.${index}.value`}
                rules={{
                  required: true,
                  validate: (value) =>
                    !Number.isNaN(Number(value)) || 'Must be a valid number',
                }}
                render={({
                  field: { value, onChange, onBlur, ref },
                  fieldState: { error },
                }) => (
                  <TextField
                    style={{
                      flex: 1,
                      marginBottom: 16,
                      marginRight: 8,
                      textAlign: 'left',
                    }}
                    label={`${['X', 'Y', 'Z'][index]}`}
                    name={`location.${index}.value`}
                    variant="standard"
                    disabled={loading}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    required
                    type="text"
                    error={!!error}
                    helperText={error ? error.message : undefined}
                  />
                )}
              />
            ))}
          </div>
          <div style={{ height: 16 }} />
          <Box>
            <LoadingButton type="submit" variant="contained" loading={loading}>
              Save Anchor
            </LoadingButton>
          </Box>
        </FormContainer>
      </Box>
    </Modal>
  );
}

export default EditAnchorModal;
