export const READ_ANCHORS = 'READ_ANCHORS';
export const CREATE_ANCHOR = 'CREATE_ANCHOR';
export const UPDATE_ANCHOR = 'UPDATE_ANCHOR';
export const DELETE_ANCHOR = 'DELETE_ANCHOR';

// eslint-disable-next-line no-shadow
export const readAnchorsAction = (anchors, queryId = 'default') => ({
  type: READ_ANCHORS,
  payload: {
    anchors,
    queryId,
  },
});

export const createAnchorAction = (anchor, queryId = 'default') => ({
  type: CREATE_ANCHOR,
  payload: {
    anchor,
    queryId,
  },
});

export const updateAnchorAction = (anchor, queryId = 'default') => ({
  type: UPDATE_ANCHOR,
  payload: {
    anchor,
    queryId,
  },
});

export const deleteAnchorAction = (anchorId, queryId = 'default') => ({
  type: DELETE_ANCHOR,
  payload: {
    anchorId,
    queryId,
  },
});

const initialAnchors = { default: {} };

export function anchors(state = initialAnchors, action) {
  switch (action.type) {
    case READ_ANCHORS: {
      const newState = {
        ...state,
        [action.payload.queryId]: { ...state[action.payload.queryId] },
      };
      action.payload.anchors.forEach((p) => {
        newState[action.payload.queryId][p._id] = p;
      });
      return newState;
    }
    case CREATE_ANCHOR:
      return {
        ...state,
        [action.payload.queryId]: {
          ...state[action.payload.queryId],
          [action.payload.anchor._id]: action.payload.anchor,
        },
      };
    case UPDATE_ANCHOR: {
      const current =
        (state[action.payload.queryId] &&
          state[action.payload.queryId][action.payload.anchor._id]) ||
        undefined;
      return {
        ...state,
        [action.payload.queryId]: {
          ...state[action.payload.queryId],
          [action.payload.anchor._id]: {
            ...current,
            ...action.payload.anchor,
          },
        },
      };
    }
    case DELETE_ANCHOR: {
      const { anchorId } = action.payload;
      return Object.entries(state).reduce((prev, [key, val]) => {
        prev[key] = {
          ...val,
          [anchorId]: undefined,
        };
        return prev;
      }, {});
    }
    default:
      return state;
  }
}
