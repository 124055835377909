import { LoadingButton } from '@mui/lab';
import { Box, Button, Modal, Tooltip, Typography } from '@mui/material';
import { showMessageAction } from '@theora360/shared/src/redux-store/snackbar-store';
import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormContainer, TextFieldElement } from 'react-hook-form-mui';
import { useDispatch } from 'react-redux';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { stackoverflowLight } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import useFilterAnchors from '../anchors/use-filter-anchors';
import useSelectedCampusId from '../../../shared/use-selected-campus-id';
import { TICKS_TO_METERS } from './constants';

function DemoExportButton({
  tagIds,
  anchorIds,
  anchorRangeData,
  tagLocHistory,
}) {
  const campusId = useSelectedCampusId();
  const anchors = useFilterAnchors({
    campusId,
    filters: {},
  });
  const form = useForm();
  const [loading, setLoading] = useState(false);
  const [exporting, setExporting] = useState();
  const onCancelExport = () => setExporting(undefined);
  const onExport = () => {
    const d = new Date();
    form.setValue(
      'filename',
      `${d.getFullYear()}-${d.getMonth()}-${d.getDate()}_${d.getHours()}:${d.getMinutes()}.json`,
    );
    setExporting(true);
  };
  const dispatch = useDispatch();
  const onFinishExport = useCallback(
    (values) => {
      setLoading(true);
      try {
        const data = {
          ticksToMeters: TICKS_TO_METERS,
          tags: tagIds.reduce((prev, tagId) => {
            prev[tagId] = {
              tagId,
              anchors: anchorIds.reduce((prev1, anchorId) => {
                if (anchorRangeData[tagId]?.[anchorId]) {
                  prev1[anchorId] = {
                    location: anchors.find((a) => a._id === anchorId)?.location,
                    anchorId,
                    t: anchorRangeData[tagId][anchorId].x,
                    epoch: anchorRangeData[tagId][anchorId].x.map(
                      (x) => x + anchorRangeData[tagId][anchorId].zero,
                    ),
                    raw: anchorRangeData[tagId][anchorId].y,
                    filtered: anchorRangeData[tagId][anchorId].y2,
                  };
                }
                return prev1;
              }, {}),
            };
            return prev;
          }, {}),
          locations: tagLocHistory,
        };
        const jsonStr = JSON.stringify(data, null, 2);

        let element = document.createElement('a');
        element.setAttribute(
          'href',
          'data:text/plain;charset=utf-8,' + encodeURIComponent(jsonStr),
        );
        element.setAttribute('download', values.filename);

        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
      } catch (err) {
        console.log(err);
        dispatch(
          showMessageAction({
            _id: 'export-json',
            severity: 'error',
            message: 'There was an error exporting to JSON',
          }),
        );
      }
      setLoading(false);
      setExporting(false);
    },
    [tagIds, anchorIds, tagLocHistory, dispatch, anchorRangeData, anchors],
  );

  return (
    <>
      <Tooltip title="Export the data traces currently displayed to JSON">
        <Button variant="outlined" onClick={onExport} sx={{ mr: 2 }}>
          Export
        </Button>
      </Tooltip>
      <Modal
        open={exporting}
        onClose={onCancelExport}
        sx={{
          overflow: 'scroll',
          p: 4,
        }}
      >
        <Box
          sx={{
            backgroundColor: 'white',
            textAlign: 'center',
            borderRadius: 1,
            padding: 4,
            marginTop: 4,
            marginBottom: 4,
            width: 600,
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        >
          <Typography variant="h4" sx={{ mb: 3 }}>
            Export to JSON
          </Typography>
          <FormContainer formContext={form} onSuccess={onFinishExport}>
            <TextFieldElement
              style={{ marginBottom: 32, width: '100%' }}
              variant="standard"
              name="filename"
              label="Filename"
              helperText="A memorable filename for this dataset"
              type="text"
              disabled={loading}
              required
            />

            <Typography variant="h5" sx={{ mb: 3 }}>
              Data Format
            </Typography>
            <Box style={{ textAlign: 'left' }}>
              <SyntaxHighlighter
                language="javascript"
                style={stackoverflowLight}
              >
                {`{
    ticksToMeters,  // meters = rangeInTicks * ticksToMeters
    tags: {
        "tag123": {
            tagId: "tag123",
            anchors: {
                "anchor123": {
                    anchorId: "anchor123",
                    location: [x, y, z],
                    t: [],  // Seconds since first epoch
                    epoch: [],  // Epoch timestamps
                    raw: [],  // Raw range values
                    filtered: [],  // Filtered range values
                },
                ...
            },
            locations: {
                details: [
                    {
                        t: 123456789,  // Epoch timestamp for this details object
                        a: [],  // Anchor Ids
                        d: [],  // The filtered ranges converted to meters
                        l: [],  // Anchor locations
                    }
                ],
                t: [],  // Seconds since first epoch
                epoch: [],  // Epoch timestamps
                x: [],
                xVar: [],
                y: [],
                yVar: [],
                z: [],
                zVar: [],
            }
        },
        ...
    }
}`}
              </SyntaxHighlighter>
            </Box>
            <div style={{ height: 16 }} />
            <Box>
              <LoadingButton
                type="submit"
                variant="contained"
                loading={loading}
              >
                Export
              </LoadingButton>
            </Box>
          </FormContainer>
        </Box>
      </Modal>
    </>
  );
}

export default DemoExportButton;
