import {
  Box,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import useLocale from '@theora360/shared/src/shared/use-locale';
import { formatDuration } from '@theora360/shared/src/shared/utils';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import InfoBox from '../../../components/InfoBox';
import settings from '../../../settings';
import RequestAnchorManifestButton from './RequestAnchorManifestButton';
import useSiteMasterAnchorManifestUpdates from './use-site-master-anchor-manifest';
import VisibilityIcon from '@mui/icons-material/Visibility';
import RequestEraseLanAssignmentsButton from './RequestEraseLanAssignmentsButton';

const dense = false;

function SiteMasterAnchorManifestTable({ _id }) {
  const manifest = useSiteMasterAnchorManifestUpdates(_id);
  const locale = useLocale();
  const dateFormat = useMemo(() => {
    return new Intl.DateTimeFormat(locale, {
      dateStyle: 'short',
      timeStyle: 'medium',
    });
  }, [locale]);
  const info1 = useMemo(() => {
    if (manifest) {
      return [
        {
          label: 'Anchor Count',
          desc: manifest.lastMsgAnchorCount,
        },
        {
          label: '# Anchors in Last Message',
          desc: manifest.lastMsgNumAnchors,
        },
        manifest.lastMsgCloudSequenceNo != null && {
          label: 'Cloud Sequence No.',
          desc: manifest.lastMsgCloudSequenceNo,
        },
        manifest.lastMessageNo != null && {
          label: 'Message No.',
          desc: manifest.lastMessageNo,
        },
        manifest.instructionStatus != null && {
          label: 'Instruction Status',
          desc: manifest.instructionStatus,
        },
        {
          label: 'Updated At',
          desc: dateFormat.format(new Date(manifest.updatedAt)),
        },
      ].filter((i) => i);
    }
    return [];
  }, [manifest, dateFormat]);

  const data = (manifest && manifest.anchors) || [];
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(settings.pageSize);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  return (
    <>
      <Box sx={{ display: 'flex', mb: 2 }}>
        <RequestAnchorManifestButton _id={_id} />
        <RequestEraseLanAssignmentsButton _id={_id} />
      </Box>
      {manifest && (
        <>
          <InfoBox items={info1} sx={{ mb: 3 }} />
          <Typography variant="h6" sx={{ mb: 1 }}>
            Anchors
          </Typography>
          <Paper>
            <TableContainer>
              <Table sx={{ minWidth: 650 }} size={dense ? 'small' : undefined}>
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Logical Address</TableCell>
                    <TableCell align="left">Anchor ID</TableCell>
                    <TableCell align="left">Last Heard</TableCell>
                    <TableCell align="left">RSSI</TableCell>
                    <TableCell align="left">IP Assigned</TableCell>
                    <TableCell align="left">Updated At</TableCell>
                    <TableCell align="left">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.map((row) => (
                    <TableRow
                      key={row._id}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {row.logicalAddr}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row._id}
                      </TableCell>
                      <TableCell align="left">
                        {`${row.secondsSinceRx} s`}
                      </TableCell>
                      <TableCell align="left">{row.rssi}</TableCell>
                      <TableCell align="left">
                        {row.hasIpAddress ? 'Yes' : 'No'}
                      </TableCell>
                      <TableCell align="left">
                        {dateFormat.format(new Date(row.updatedAt))}
                      </TableCell>
                      <TableCell align="right" width={60}>
                        <IconButton
                          component={Link}
                          to={`/devices/anchors/${row._id}/status`}
                          sx={{ mr: 1 }}
                        >
                          <VisibilityIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                  {emptyRows > 0 && (
                    <TableRow
                      style={{
                        height: (dense ? 33 : 53) * emptyRows,
                      }}
                    >
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[settings.pageSize, settings.largePage]}
              component="div"
              count={data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </>
      )}
    </>
  );
}

export default SiteMasterAnchorManifestTable;
