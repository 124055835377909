import { gql, useSubscription } from '@apollo/client';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  createAnchorAction,
  deleteAnchorAction,
  updateAnchorAction,
} from '../../redux-store/anchor-store';

export const AnchorAttributes = gql`
  {
    _id
    label
    desc
    campusId
    gatewayId
    siteMasterId
    address {
      host
      port
    }
    location
    createdAt
    updatedAt
    deletedAt
  }
`;

export const AnchorUpdateAttributes = gql`
{
  type
  crud
  id
  new ${AnchorAttributes}
}
`;

export const anchorUpdatesSubscription = gql`
  subscription AnchorUpdates($campusId: ID!) {
    anchorUpdates(campusId: $campusId) ${AnchorUpdateAttributes}
  }
`;

function useAnchorUpdates(campusId) {
  const queryId = campusId || 'default';
  useSubscription(anchorUpdatesSubscription, {
    skip: !campusId,
    variables: {
      campusId,
    },
    fetchPolicy: 'no-cache',
    onData: ({ client, data: result }) => {
      try {
        if (result && result.data && result.data.anchorUpdates) {
          const updates = result.data.anchorUpdates;
          updates.forEach((update) => {
            switch (update.type) {
              case 'ANCHOR_CRUD': {
                switch (update.crud) {
                  case 'CREATE':
                    dispatch(createAnchorAction(update.new, queryId));
                    break;
                  case 'READ':
                    dispatch(updateAnchorAction(update.new, queryId));
                    break;
                  case 'UPDATE':
                    dispatch(updateAnchorAction(update.new, queryId));
                    break;
                  case 'DELETE':
                    dispatch(deleteAnchorAction(update.id, queryId));
                    break;
                  default:
                    break;
                }
                break;
              }
              default:
                break;
            }
          });
        }
      } catch (err) {
        console.error(err);
      }
    },
  });
  const dispatch = useDispatch();
}

export default useAnchorUpdates;
