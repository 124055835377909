import { gql, useSubscription } from '@apollo/client';
import { useEffect, useRef } from 'react';
import { BehaviorSubject, debounceTime } from 'rxjs';

export const TagLocationAttributes = gql`
  {
    _id
    campusId
    tagId
    epochTimestamp
    xyzPos
    xyzVar
    xyzUnfiltered
    anchorIds
    distances
    rawDistances
    locations
    updatedAt
  }
`;

export const tagLocationUpdatesSubscription = gql`
  subscription TagLocationUpdates($campusId: ID!) {
    tagLocationUpdates(campusId: $campusId) ${TagLocationAttributes}
  }
`;

function useTagLocationUpdates(campusId, setUpdate) {
  const _tagLocations = useRef({});
  const term$ = useRef();
  useEffect(() => {
    term$.current = new BehaviorSubject([]);
    // term$.current.pipe(debounceTime(500)).subscribe((term) => {
    term$.current.subscribe((term) => {
      setUpdate(term);
    });
  }, [setUpdate]);
  useSubscription(tagLocationUpdatesSubscription, {
    skip: !campusId,
    variables: {
      campusId,
    },
    fetchPolicy: 'no-cache',
    onData: ({ client, data: result }) => {
      try {
        if (result && result.data && result.data.tagLocationUpdates) {
          const updates = result.data.tagLocationUpdates;
          updates.forEach((update) => {
            _tagLocations.current[update.tagId] = update;
          });
          term$.current?.next(
            Object.values(_tagLocations.current).sort((a, b) =>
              a.tagId.localeCompare(b.tagId),
            ),
          );
        }
      } catch (err) {
        console.error(err);
      }
    },
  });
}

export default useTagLocationUpdates;
