export const READ_TAGS = 'READ_TAGS';
export const CREATE_TAG = 'CREATE_TAG';
export const UPDATE_TAG = 'UPDATE_TAG';
export const DELETE_TAG = 'DELETE_TAG';

// eslint-disable-next-line no-shadow
export const readTagsAction = (tags, queryId = 'default') => ({
  type: READ_TAGS,
  payload: {
    tags,
    queryId,
  },
});

export const createTagAction = (tag, queryId = 'default') => ({
  type: CREATE_TAG,
  payload: {
    tag,
    queryId,
  },
});

export const updateTagAction = (tag, queryId = 'default') => ({
  type: UPDATE_TAG,
  payload: {
    tag,
    queryId,
  },
});

export const deleteTagAction = (tagId, queryId = 'default') => ({
  type: DELETE_TAG,
  payload: {
    tagId,
    queryId,
  },
});

const initialTags = { default: {} };

export function tags(state = initialTags, action) {
  switch (action.type) {
    case READ_TAGS: {
      const newState = {
        ...state,
        [action.payload.queryId]: { ...state[action.payload.queryId] },
      };
      action.payload.tags.forEach((p) => {
        newState[action.payload.queryId][p._id] = p;
      });
      return newState;
    }
    case CREATE_TAG:
      return {
        ...state,
        [action.payload.queryId]: {
          ...state[action.payload.queryId],
          [action.payload.tag._id]: action.payload.tag,
        },
      };
    case UPDATE_TAG: {
      const current =
        (state[action.payload.queryId] &&
          state[action.payload.queryId][action.payload.tag._id]) ||
        undefined;
      return {
        ...state,
        [action.payload.queryId]: {
          ...state[action.payload.queryId],
          [action.payload.tag._id]: {
            ...current,
            ...action.payload.tag,
          },
        },
      };
    }
    case DELETE_TAG: {
      const { tagId } = action.payload;
      return Object.entries(state).reduce((prev, [key, val]) => {
        prev[key] = {
          ...val,
          [tagId]: undefined,
        };
        return prev;
      }, {});
    }
    default:
      return state;
  }
}
