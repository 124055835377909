import { gql } from '@apollo/client';

export const NeredeDatasetAttributes = gql`
  {
    _id
    campusId
    timestampMs
    lastTimestampMs
    name
    tags
    notes
    fileId
    createdBy
    updatedBy
    createdAt
    updatedAt
    deletedAt
    deletedBy
  }
`;

export const NeredeDataAttributes = gql`
  {
    _id
    type
    campusId
    timestampMs
    datasetId
    ax
    ay
    az
    gx
    gy
    gz
    tagId
    tagLoc
    tagLocVar
    anchorId
    neighborId
    anchorRange
    aToNDistance
    aToNRealDistanceM
    lanNo
    anchorLoc
    step
    maxAccel
    minAccel
    batteryMV
  }
`;

export const NeredeAnnotationAttributes = gql`
  {
    _id
    campusId
    label
    tagId
    datasetId
    startTimestampMs
    endTimestampMs
    duration
    netIds
    neuralNets {
      _id
      name
    }
    desc
    tags
    createdBy
    updatedBy
    createdAt
    updatedAt
  }
`;
