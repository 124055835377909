import {
  Chip,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import SportsMartialArtsIcon from '@mui/icons-material/SportsMartialArts';
import useLocale from '@theora360/shared/src/shared/use-locale';

const Heights = {
  sinking: {
    key: 'alert',
    label: 'Fallen',
    // key: 'sinking',
    // label: 'Sinking',
    upperLimit: -0.5,
    lowerLImit: -10,
  },
  falling: {
    key: 'alert',
    label: 'Fallen',
    // key: 'falling',
    // label: 'Fallen',
    upperLimit: 0.4,
    lowerLImit: -0.5,
  },
  sitting: {
    key: 'normal',
    label: 'Normal',
    // key: 'sitting',
    // label: 'Sitting',
    upperLimit: 1,
    lowerLImit: 0.4,
  },
  standing: {
    // key: 'standing',
    // label: 'Standing',
    key: 'normal',
    label: 'Normal',
    upperLimit: 2,
    lowerLImit: 1,
  },
  flying: {
    // key: 'flying',
    // label: 'Flying',
    key: 'normal',
    label: 'Normal',
    upperLimit: 10,
    lowerLImit: 2,
  },
};

function getHeightStatus(zPos) {
  let height;
  Object.values(Heights).forEach((h) => {
    if (zPos > h.lowerLImit && zPos <= h.upperLimit) {
      height = h;
    }
  });
  return height;
}

const getHeightStatusLabel = (height) => height && height.label;

function TagFallDetectTable({ tagLocations, tagRangeMatrix }) {
  // console.log('tagLocations', tagLocations);
  // console.log('tagRangeMatrix', tagRangeMatrix);

  const [comms, setComms] = useState({});
  const latestEpoch = useRef({});
  const latestUpdate = useRef({});
  useEffect(() => {
    tagLocations.forEach((tagLoc) => {
      const latest = latestEpoch.current[tagLoc.tagId];
      if (tagLoc.epochTimestamp !== latest) {
        latestUpdate.current[tagLoc.tagId] = new Date().getTime();
      }
      latestEpoch.current[tagLoc.tagId] = tagLoc.epochTimestamp;
    });
  }, [tagLocations]);
  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date().getTime();
      const _comms = Object.entries(latestUpdate.current).reduce(
        (prev, [tagId, latest]) => {
          let status;
          const elapsed = now - latest;
          if (elapsed < 2000) {
            status = {
              key: 'GOOD',
              label: 'Good',
              color: 'success',
            };
          } else if (elapsed < 4000) {
            status = {
              key: 'LATE',
              label: 'Late',
              color: 'warning',
            };
          } else if (elapsed < 6000) {
            status = {
              key: 'EXPIRED',
              label: 'Expired',
              color: 'error',
            };
          } else {
            status = {
              key: 'NO_SIGNAL',
              label: 'No Signal',
              color: 'error',
            };
          }
          prev[tagId] = status;
          return prev;
        },
        {},
      );
      setComms(_comms);
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const locale = useLocale();
  const dateFormat = new Intl.DateTimeFormat(locale, {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false,
    fractionalSecondDigits: 3,
  });
  return (
    <>
      <Typography variant="h5" sx={{ mt: 0, mb: 2 }}>
        <SportsMartialArtsIcon style={{ height: 40, width: 40 }} /> Fall Detect
        Status
      </Typography>
      <Paper sx={{ mb: 2 }}>
        <TableContainer>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell align="left">Tag</TableCell>
                <TableCell align="left">Timestamp</TableCell>
                <TableCell align="left">Number of Anchors</TableCell>
                <TableCell align="left">Status</TableCell>
                <TableCell align="left">Z Position</TableCell>
                <TableCell align="left">Fall Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tagLocations.map((tagLoc, index) => (
                <TableRow
                  key={tagLoc._id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    <Typography sx={{ fontSize: 18 }}>
                      {`Tag (${tagLoc._id})`}
                    </Typography>
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <Typography sx={{ fontSize: 18 }}>
                      {tagLoc.epochTimestamp &&
                        dateFormat.format(new Date(tagLoc.epochTimestamp))}
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography sx={{ fontSize: 18 }}>
                      {`${tagLoc.anchorIds.length}`}
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography sx={{ fontSize: 18 }}>
                      {comms[tagLoc.tagId] && (
                        <Chip
                          color={comms[tagLoc.tagId].color}
                          label={`${comms[tagLoc.tagId].label}`}
                        />
                      )}
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography sx={{ fontSize: 18 }}>
                      {tagLoc.xyzPos && `${tagLoc.xyzPos[2].toFixed(3)}m`}
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography sx={{ fontSize: 18 }}>
                      {comms[tagLoc.tagId]?.key === 'NO_SIGNAL'
                        ? 'Fallen (No Signal)'
                        : tagLoc.xyzPos &&
                          getHeightStatusLabel(
                            getHeightStatus(tagLoc.xyzPos[2]),
                          )}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
}

export default TagFallDetectTable;
