import { gql, useSubscription } from '@apollo/client';
import {
  createEventAction,
  deleteEventAction,
  updateEventAction,
} from '@theora360/shared/src/redux-store/event-store';
import { useDispatch } from 'react-redux';

export const EventAttributes = gql`
  {
    _id
    campusId
    anchorId
    siteMasterId
    type
    timestamp
    message
    createdBy
    updatedBy
    createdAt
    updatedAt
  }
`;

export const EventUpdateAttributes = gql`
{
  type
  crud
  id
  new ${EventAttributes}
}
`;

export const eventUpdatesSubscription = gql`
  subscription EventUpdates($campusId: ID!, $anchorId: ID, $siteMasterId: ID) {
    eventUpdates(campusId: $campusId, anchorId: $anchorId, siteMasterId: $siteMasterId) ${EventUpdateAttributes}
  }
`;

function useEventUpdates(campusId, filters) {
  const queryId = campusId || 'default';
  useSubscription(eventUpdatesSubscription, {
    skip: !campusId,
    variables: {
      campusId,
      ...filters,
    },
    fetchPolicy: 'no-cache',
    onData: ({ client, data: result }) => {
      try {
        if (result && result.data && result.data.eventUpdates) {
          const updates = result.data.eventUpdates;
          updates.forEach((update) => {
            switch (update.type) {
              case 'NEREDE_EVENT_CRUD': {
                switch (update.crud) {
                  case 'CREATE':
                    dispatch(createEventAction(update.new, queryId));
                    break;
                  case 'READ':
                    dispatch(updateEventAction(update.new, queryId));
                    break;
                  case 'UPDATE':
                    dispatch(updateEventAction(update.new, queryId));
                    break;
                  case 'DELETE':
                    dispatch(deleteEventAction(update.id, queryId));
                    break;
                  default:
                    break;
                }
                break;
              }
              default:
                break;
            }
          });
        }
      } catch (err) {
        console.error(err);
      }
    },
  });
  const dispatch = useDispatch();
}

export default useEventUpdates;
