export const READ_ANNOTATIONS = 'READ_ANNOTATIONS';
export const CREATE_ANNOTATION = 'CREATE_ANNOTATION';
export const UPDATE_ANNOTATION = 'UPDATE_ANNOTATION';
export const DELETE_ANNOTATION = 'DELETE_ANNOTATION';

// eslint-disable-next-line no-shadow
export const readAnnotationsAction = (annotations, queryId = 'default') => ({
  type: READ_ANNOTATIONS,
  payload: {
    annotations,
    queryId,
  },
});

export const createAnnotationAction = (annotation, queryId = 'default') => ({
  type: CREATE_ANNOTATION,
  payload: {
    annotation,
    queryId,
  },
});

export const updateAnnotationAction = (annotation, queryId = 'default') => ({
  type: UPDATE_ANNOTATION,
  payload: {
    annotation,
    queryId,
  },
});

export const deleteAnnotationAction = (annotationId, queryId = 'default') => ({
  type: DELETE_ANNOTATION,
  payload: {
    annotationId,
    queryId,
  },
});

const initialAnnotations = { default: {} };

export function annotations(state = initialAnnotations, action) {
  switch (action.type) {
    case READ_ANNOTATIONS: {
      const newState = {
        ...state,
        [action.payload.queryId]: { ...state[action.payload.queryId] },
      };
      action.payload.annotations.forEach((p) => {
        newState[action.payload.queryId][p._id] = p;
      });
      return newState;
    }
    case CREATE_ANNOTATION:
      return {
        ...state,
        [action.payload.queryId]: {
          ...state[action.payload.queryId],
          [action.payload.annotation._id]: action.payload.annotation,
        },
      };
    case UPDATE_ANNOTATION: {
      const current =
        (state[action.payload.queryId] &&
          state[action.payload.queryId][action.payload.annotation._id]) ||
        undefined;
      return {
        ...state,
        [action.payload.queryId]: {
          ...state[action.payload.queryId],
          [action.payload.annotation._id]: {
            ...current,
            ...action.payload.annotation,
          },
        },
      };
    }
    case DELETE_ANNOTATION: {
      const { annotationId } = action.payload;
      return Object.entries(state).reduce((prev, [key, val]) => {
        prev[key] = {
          ...val,
          [annotationId]: undefined,
        };
        return prev;
      }, {});
    }
    default:
      return state;
  }
}
