import { gql, useSubscription } from '@apollo/client';
import { useEffect, useState } from 'react';

export const AnchorConfigurationAttributes = gql`
  {
    _id
    campusId
    messageNo
    commandError
    responseError
    logicalAddress
    anchorId
    lanNumberA
    lanNumberB
    lanNumberC
    lanNumberD
    lanNumberE
    lanNumberF
    rangingOrderA
    rangingOrderB
    rangingOrderC
    rangingOrderD
    rangingOrderE
    rangingOrderF
    timeDistribution
    updatedAt
  }
`;

export const anchorConfigurationUpdatesSubscription = gql`
  subscription AnchorConfigurationUpdates($anchorId: ID!) {
    anchorConfigurationUpdates(anchorId: $anchorId) ${AnchorConfigurationAttributes}
  }
`;

function useAnchorConfigurationUpdates(anchorId) {
  const [anchorconfiguration, setAnchorConfiguration] = useState();
  useEffect(() => {
    setAnchorConfiguration(undefined);
  }, [anchorId]);
  useSubscription(anchorConfigurationUpdatesSubscription, {
    skip: !anchorId,
    variables: {
      anchorId,
    },
    fetchPolicy: 'no-cache',
    onData: ({ client, data: result }) => {
      console.log('result', result);
      try {
        if (result && result.data && result.data.anchorConfigurationUpdates) {
          const update = result.data.anchorConfigurationUpdates;
          setAnchorConfiguration(update);
        }
      } catch (err) {
        console.error(err);
      }
    },
  });
  return anchorconfiguration;
}

export default useAnchorConfigurationUpdates;
