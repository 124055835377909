export const READ_CAMPUSES = 'READ_CAMPUSES';
export const CREATE_CAMPUS = 'CREATE_CAMPUS';
export const UPDATE_CAMPUS = 'UPDATE_CAMPUS';
export const DELETE_CAMPUS = 'DELETE_CAMPUS';

// eslint-disable-next-line no-shadow
export const readCampusesAction = (campuses, queryId = 'default') => ({
  type: READ_CAMPUSES,
  payload: {
    campuses,
    queryId,
  },
});

export const createCampusAction = (campus, queryId = 'default') => ({
  type: CREATE_CAMPUS,
  payload: {
    campus,
    queryId,
  },
});

export const updateCampusAction = (campus, queryId = 'default') => ({
  type: UPDATE_CAMPUS,
  payload: {
    campus,
    queryId,
  },
});

export const deleteCampusAction = (campusId, queryId = 'default') => ({
  type: DELETE_CAMPUS,
  payload: {
    campusId,
    queryId,
  },
});

const initialCampuses = { default: {} };

export function campuses(state = initialCampuses, action) {
  switch (action.type) {
    case READ_CAMPUSES: {
      const newState = {
        ...state,
        [action.payload.queryId]: { ...state[action.payload.queryId] },
      };
      action.payload.campuses.forEach((p) => {
        newState[action.payload.queryId][p._id] = p;
      });
      return newState;
    }
    case CREATE_CAMPUS:
      return {
        ...state,
        [action.payload.queryId]: {
          ...state[action.payload.queryId],
          [action.payload.campus._id]: action.payload.campus,
        },
      };
    case UPDATE_CAMPUS: {
      const current =
        (state[action.payload.queryId] &&
          state[action.payload.queryId][action.payload.campus._id]) ||
        undefined;
      return {
        ...state,
        [action.payload.queryId]: {
          ...state[action.payload.queryId],
          [action.payload.campus._id]: {
            ...current,
            ...action.payload.campus,
          },
        },
      };
    }
    case DELETE_CAMPUS: {
      const { campusId } = action.payload;
      return Object.entries(state).reduce((prev, [key, val]) => {
        prev[key] = {
          ...val,
          [campusId]: undefined,
        };
        return prev;
      }, {});
    }
    default:
      return state;
  }
}
