export const SHOW_MESSAGE = 'SHOW_MESSAGE';
export const HIDE_MESSAGE = 'HIDE_MESSAGE';

// eslint-disable-next-line no-shadow
export const showMessageAction = (message, queryId = 'default') => ({
  type: SHOW_MESSAGE,
  payload: {
    message,
    queryId,
  },
});

export const hideMessageAction = (messageId, queryId = 'default') => ({
  type: HIDE_MESSAGE,
  payload: {
    messageId,
    queryId,
  },
});

const initialSnackbar = { default: {} };

export function snackbar(state = initialSnackbar, action) {
  switch (action.type) {
    case SHOW_MESSAGE:
      return {
        ...state,
        [action.payload.queryId]: {
          ...state[action.payload.queryId],
          [action.payload.message._id]: action.payload.message,
        },
      };
    case HIDE_MESSAGE: {
      const current =
        (state[action.payload.queryId] &&
          state[action.payload.queryId][action.payload.messageId]) ||
        undefined;
      return {
        ...state,
        [action.payload.queryId]: {
          ...state[action.payload.queryId],
          [action.payload.messageId]: undefined,
        },
      };
    }
    default:
      return state;
  }
}
