export const READ_EVENTS = 'READ_EVENTS';
export const CREATE_EVENT = 'CREATE_EVENT';
export const UPDATE_EVENT = 'UPDATE_EVENT';
export const DELETE_EVENT = 'DELETE_EVENT';

// eslint-disable-next-line no-shadow
export const readEventsAction = (events, queryId = 'default') => ({
  type: READ_EVENTS,
  payload: {
    events,
    queryId,
  },
});

export const createEventAction = (event, queryId = 'default') => ({
  type: CREATE_EVENT,
  payload: {
    event,
    queryId,
  },
});

export const updateEventAction = (event, queryId = 'default') => ({
  type: UPDATE_EVENT,
  payload: {
    event,
    queryId,
  },
});

export const deleteEventAction = (eventId, queryId = 'default') => ({
  type: DELETE_EVENT,
  payload: {
    eventId,
    queryId,
  },
});

const initialEvents = { default: {} };

export function events(state = initialEvents, action) {
  switch (action.type) {
    case READ_EVENTS: {
      const newState = {
        ...state,
        [action.payload.queryId]: { ...state[action.payload.queryId] },
      };
      action.payload.events.forEach((p) => {
        newState[action.payload.queryId][p._id] = p;
      });
      return newState;
    }
    case CREATE_EVENT:
      return {
        ...state,
        [action.payload.queryId]: {
          ...state[action.payload.queryId],
          [action.payload.event._id]: action.payload.event,
        },
      };
    case UPDATE_EVENT: {
      const current =
        (state[action.payload.queryId] &&
          state[action.payload.queryId][action.payload.event._id]) ||
        undefined;
      return {
        ...state,
        [action.payload.queryId]: {
          ...state[action.payload.queryId],
          [action.payload.event._id]: {
            ...current,
            ...action.payload.event,
          },
        },
      };
    }
    case DELETE_EVENT: {
      const { eventId } = action.payload;
      return Object.entries(state).reduce((prev, [key, val]) => {
        prev[key] = {
          ...val,
          [eventId]: undefined,
        };
        return prev;
      }, {});
    }
    default:
      return state;
  }
}
