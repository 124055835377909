import { gql } from '@apollo/client';
import AddIcon from '@mui/icons-material/Add';
import TipsAndUpdatesOutlinedIcon from '@mui/icons-material/TipsAndUpdatesOutlined';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {
  Box,
  Button,
  Chip,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import useLocale from '@theora360/shared/src/shared/use-locale';
import { useManyRemote } from '@theora360/shared/src/shared/use-many-remote';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormContainer, TextFieldElement } from 'react-hook-form-mui';
import { Link, useNavigate } from 'react-router-dom';
import MainPageTemplate from '../../../components/MainPageTemplate';
import settings from '../../../settings';
import useSelectedCampusId from '../../../shared/use-selected-campus-id';
import CreateNeuralNetModal from './CreateNeuralNetModal';
import { NeuralNetAttributes } from './constants';

const dense = false;

export const allNeuralNetsQuery = gql`
  query AllNeuralNets($first: Int, $after: String, $filters: NeuralNetFilters, $sortBy: [SortBy]) {
    allNeuralNets(first: $first, after: $after, filters: $filters, sortBy: $sortBy) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node ${NeuralNetAttributes}
      }
    }
  }
`;

export const extractNeuralNets = (data) => data && data.allNeuralNets;
const queryId = 'list';

function NeuralNetsPage() {
  const campusId = useSelectedCampusId();
  const filters = useRef({
    campusId,
  });
  const sortBy = useRef([{ key: 'updatedAt', order: 'DESC' }]);
  const {
    error,
    loading,
    data,
    search: dataSearch,
    hasNextPage,
    next,
    refetch,
    reset,
  } = useManyRemote({
    query: allNeuralNetsQuery,
    extract: extractNeuralNets,
    first: settings.querySize,
    filters: filters.current,
    sortBy: sortBy.current,
    fetchPolicy: 'network-only',
    queryId: queryId,
  });

  const locale = useLocale();
  const dateFormat = useMemo(() => {
    return new Intl.DateTimeFormat(locale, {
      dateStyle: 'short',
      timeStyle: 'medium',
    });
  }, [locale]);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(settings.pageSize);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  const form = useForm();
  const searchField = form.watch('search', '');
  const onFiltersChange = useEffect(() => {
    dataSearch(searchField);
  }, [dataSearch, searchField]);

  const navigate = useNavigate();
  const [creating, setCreating] = useState();
  const onCreate = () => setCreating({ campusId });
  const onCancelCreate = () => setCreating(undefined);
  const onFinishCreate = (newId) => {
    setCreating(undefined);
    navigate(`/neural-nets/view/${newId}/annotations`);
  };

  return (
    <MainPageTemplate title="Neural Networks">
      <Typography variant="h2" gutterBottom>
        <TipsAndUpdatesOutlinedIcon style={{ height: 40, width: 40 }} /> Neural
        Networks
      </Typography>
      <Box sx={{ mb: 2, display: 'flex' }}>
        <Button variant="contained" startIcon={<AddIcon />} onClick={onCreate}>
          Create a Neural Network
        </Button>
      </Box>
      <Box sx={{ mb: 2, display: 'flex', alignItems: 'center' }}>
        <FormContainer formContext={form}>
          <TextFieldElement
            style={{ width: 300, marginBottom: 16 }}
            variant="standard"
            name="search"
            label="Search"
            type="text"
            onChange={(v) => onFiltersChange({ search: v })}
          />
        </FormContainer>
      </Box>
      <Paper>
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell align="left">Type</TableCell>
                <TableCell align="left">Description</TableCell>
                <TableCell align="left">Tags</TableCell>
                <TableCell align="left">Updated At</TableCell>
                <TableCell align="left">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row) => (
                <TableRow
                  key={row._id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.name}
                  </TableCell>
                  <TableCell align="left">{row.type}</TableCell>
                  <TableCell align="left">{row.desc}</TableCell>
                  <TableCell align="left">
                    {row.tags &&
                      row.tags.map((tag) => (
                        <Chip
                          key={tag}
                          label={tag}
                          color="primary"
                          sx={{ mr: 1, mb: 0.5 }}
                        />
                      ))}
                  </TableCell>
                  <TableCell align="left">
                    {dateFormat.format(new Date(row.updatedAt))}
                  </TableCell>
                  <TableCell align="right" width={120}>
                    <IconButton
                      component={Link}
                      to={`/neural-nets/view/${row._id}/annotations`}
                      sx={{ mr: 1 }}
                    >
                      <VisibilityIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[settings.pageSize, settings.largePage]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      {hasNextPage && (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Button variant="outlined" onClick={() => next()}>
            Load More
          </Button>
        </Box>
      )}
      <CreateNeuralNetModal
        visible={creating}
        onCancel={onCancelCreate}
        onFinish={onFinishCreate}
      />
    </MainPageTemplate>
  );
}

export default NeuralNetsPage;
