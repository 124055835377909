import { gql } from '@apollo/client';
import EditIcon from '@mui/icons-material/Edit';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import StorageIcon from '@mui/icons-material/Storage';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {
  Box,
  Button,
  Chip,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import useLocale from '@theora360/shared/src/shared/use-locale';
import { useManyRemote } from '@theora360/shared/src/shared/use-many-remote';
import {
  formatDuration,
  generateUuid,
} from '@theora360/shared/src/shared/utils';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormContainer, TextFieldElement } from 'react-hook-form-mui';
import { Link } from 'react-router-dom';
import MainPageTemplate from '../../../components/MainPageTemplate';
import settings from '../../../settings';
import useSelectedCampusId from '../../../shared/use-selected-campus-id';
import { NeredeDatasetAttributes } from './constants';
import EditDatasetModal from './EditDatasetModal';
import StopRecordingDatasetModal from './StopRecordingDatasetModal';
import useDatasetRecordingUpdates from './use-dataset-recording';

const dense = false;

export const allNeredeDatasetsQuery = gql`
  query AllNeredeDatasets($first: Int, $after: String, $filters: NeredeDatasetFilters, $sortBy: [SortBy]) {
    allNeredeDatasets(first: $first, after: $after, filters: $filters, sortBy: $sortBy) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node ${NeredeDatasetAttributes}
      }
    }
  }
`;

export const extractNeredeDatasets = (data) => data && data.allNeredeDatasets;
const queryId = 'list';

function DatasetsPage() {
  const campusId = useSelectedCampusId();
  const filters = useRef({
    campusId,
  });
  const sortBy = useRef([{ key: 'updatedAt', order: 'DESC' }]);
  const {
    error,
    loading,
    data,
    search: dataSearch,
    hasNextPage,
    next,
    refetch,
    reset,
  } = useManyRemote({
    query: allNeredeDatasetsQuery,
    extract: extractNeredeDatasets,
    first: settings.querySize,
    filters: filters.current,
    sortBy: sortBy.current,
    fetchPolicy: 'network-only',
    queryId: queryId,
  });

  const recording = useDatasetRecordingUpdates(campusId);
  const isRecording = useMemo(() => {
    if (recording) {
      if (
        recording.stoppedAt &&
        new Date().getTime() < recording.stoppedAt * 1000
      ) {
        return true;
      }
    }
    return false;
  }, [recording]);

  const locale = useLocale();
  const dateFormat = useMemo(() => {
    return new Intl.DateTimeFormat(locale, {
      dateStyle: 'short',
      timeStyle: 'medium',
    });
  }, [locale]);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(settings.pageSize);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  const form = useForm();
  const searchField = form.watch('search', '');
  const onFiltersChange = useEffect(() => {
    dataSearch(searchField);
  }, [dataSearch, searchField]);

  const [editing, setEditing] = useState();
  const onCancelEditDataset = () => setEditing(undefined);
  const onEditDataset = (dataset) => setEditing({ existing: dataset });
  const onStartDataset = () =>
    setEditing({
      creating: {
        _id: generateUuid(),
      },
    });
  const onFinishEditDataset = () => {
    refetch();
    setEditing(undefined);
  };

  return (
    <MainPageTemplate title="Datasets">
      <Typography variant="h2" gutterBottom>
        <StorageIcon style={{ height: 40, width: 40 }} /> Datasets
      </Typography>
      <Box sx={{ mb: 2, display: 'flex' }}>
        {isRecording ? (
          <StopRecordingDatasetModal />
        ) : (
          <Button
            variant="contained"
            startIcon={<FiberManualRecordIcon />}
            onClick={onStartDataset}
          >
            Record New Dataset
          </Button>
        )}
      </Box>
      <Box sx={{ mb: 2, display: 'flex', alignItems: 'center' }}>
        <FormContainer formContext={form}>
          <TextFieldElement
            style={{ width: 300, marginBottom: 16 }}
            variant="standard"
            name="search"
            label="Search"
            type="text"
            onChange={(v) => onFiltersChange({ search: v })}
          />
        </FormContainer>
      </Box>
      <Paper>
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell align="left">Start Time</TableCell>
                <TableCell align="left">Duration</TableCell>
                <TableCell align="left">Tags</TableCell>
                <TableCell align="left">Updated At</TableCell>
                <TableCell align="left">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row) => (
                <TableRow
                  key={row._id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.name}
                  </TableCell>
                  <TableCell align="left">
                    {dateFormat.format(new Date(row.timestampMs))}
                  </TableCell>
                  <TableCell align="left">
                    {row.lastTimestampMs &&
                      formatDuration(row.lastTimestampMs - row.timestampMs)}
                  </TableCell>
                  <TableCell align="left">
                    {row.tags &&
                      row.tags.map((tag) => (
                        <Chip
                          key={tag}
                          label={tag}
                          color="primary"
                          sx={{ mr: 1, mb: 0.5 }}
                        />
                      ))}
                  </TableCell>
                  <TableCell align="left">
                    {dateFormat.format(new Date(row.updatedAt))}
                  </TableCell>
                  <TableCell align="right" width={120}>
                    <IconButton
                      component={Link}
                      to={`/datasets/view/${row._id}/ranges`}
                      sx={{ mr: 1 }}
                    >
                      <VisibilityIcon />
                    </IconButton>
                    <IconButton onClick={() => onEditDataset(row)}>
                      <EditIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[settings.pageSize, settings.largePage]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      {hasNextPage && (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Button variant="outlined" onClick={() => next()}>
            Load More
          </Button>
        </Box>
      )}
      <EditDatasetModal
        visible={editing}
        onFinish={onFinishEditDataset}
        onCancel={onCancelEditDataset}
      />
    </MainPageTemplate>
  );
}

export default DatasetsPage;
