import { gql, useSubscription } from '@apollo/client';
import { useState } from 'react';

export const TagHealthDataAttributes = gql`
  {
    _id
    campusId
    lanNo
    serialNo
    batteryMV
    resetHours
    runMins
    wifiOpsSecs
    rangeEpoch16
    vibrateCap
    buttonSecs
    buttonRoll
    gx
    gy
    gz
    maxAccel
    minAccel
    updatedAt
  }
`;

export const tagHealthDataUpdatesSubscription = gql`
  subscription TagHealthDataUpdates($tagId: ID!) {
    tagHealthDataUpdates(tagId: $tagId) ${TagHealthDataAttributes}
  }
`;

function useTagHealthDataUpdates(tagId) {
  const [firmwarestatus, setFirmwareStatus] = useState();
  useSubscription(tagHealthDataUpdatesSubscription, {
    skip: !tagId,
    variables: {
      tagId,
    },
    fetchPolicy: 'no-cache',
    onData: ({ client, data: result }) => {
      try {
        if (result && result.data && result.data.tagHealthDataUpdates) {
          const update = result.data.tagHealthDataUpdates;
          setFirmwareStatus(update);
        }
      } catch (err) {
        console.error(err);
      }
    },
  });
  return firmwarestatus;
}

export default useTagHealthDataUpdates;
