import { gql, useQuery } from '@apollo/client';
import EditIcon from '@mui/icons-material/Edit';
import TipsAndUpdatesOutlinedIcon from '@mui/icons-material/TipsAndUpdatesOutlined';
import { Box, Button, Chip, Tab, Tabs, Typography } from '@mui/material';
import useLocale from '@theora360/shared/src/shared/use-locale';
import React, { useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import InfoBox from '../../../components/InfoBox';
import MainPageTemplate from '../../../components/MainPageTemplate';
import CreateNeuralNetModal from './CreateNeuralNetModal';
import DeleteNeuralNetModal from './DeleteNeuralNetModal';
import { NeuralNetAttributes, NeuralNetTypes } from './constants';
import { TabContext, TabPanel } from '@mui/lab';
import NetAnnotationsTab from './NetAnnotationsTab';
import NetTrainTab from './NetTrainTab';
import useTrainingStatus from './use-training-status';
import useNeuralNetTrainingUpdates from './use-neural-net-training-updates';
import NetSavedModelsTab from './NetSavedModelsTab';

export const neuralNetQuery = gql`
  query NeuralNet($_id: ID!) {
    neuralNet(_id: $_id) ${NeuralNetAttributes}
  }
`;

function TrainingUpdatesSubscription({ netId, paused, setUpdate }) {
  useNeuralNetTrainingUpdates(paused ? undefined : netId, setUpdate);
  return null;
}

function ViewNeuralNetPage() {
  const { _id, _tab } = useParams();
  const {
    loading: fetchLoading,
    error: fetchError,
    data,
    refetch,
  } = useQuery(neuralNetQuery, {
    variables: {
      _id,
    },
    fetchPolicy: 'network-only',
  });
  const neuralNet = useMemo(() => {
    return data && data.neuralNet;
  }, [data]);

  const navigate = useNavigate();
  const locale = useLocale();
  const dateFormat = useMemo(() => {
    return new Intl.DateTimeFormat(locale, {
      dateStyle: 'short',
      timeStyle: 'medium',
    });
  }, [locale]);
  const info1 = useMemo(() => {
    if (neuralNet) {
      return [
        {
          label: 'Name',
          desc: neuralNet.name,
        },
        {
          label: 'Type',
          desc: NeuralNetTypes[neuralNet.type]?.label,
        },
        {
          label: 'Description',
          desc: neuralNet.desc,
        },
        neuralNet.tags && {
          label: 'Tags',
          component: (
            <>
              {neuralNet.tags.map((tag) => (
                <Chip
                  key={tag}
                  label={tag}
                  color="primary"
                  sx={{ mr: 1, mb: 0.5 }}
                />
              ))}
            </>
          ),
        },
        {
          label: 'Updated At',
          desc: dateFormat.format(new Date(neuralNet.updatedAt)),
        },
      ].filter((i) => i);
    }
    return [];
  }, [neuralNet, dateFormat]);
  const [editing, setEditing] = useState();
  const onCancelEdit = () => setEditing(undefined);
  const onEdit = () => setEditing({ data: neuralNet });
  const onFinishEdit = () => {
    refetch();
    setEditing(undefined);
  };
  const {
    onUpdate: onTrainUpdate,
    status: trainStatus,
    init: initTraining,
  } = useTrainingStatus();
  return (
    <MainPageTemplate title="NeuralNet">
      <Typography variant="h3" gutterBottom>
        <TipsAndUpdatesOutlinedIcon style={{ height: 40, width: 40 }} /> Neural
        Network
      </Typography>
      <TrainingUpdatesSubscription netId={_id} setUpdate={onTrainUpdate} />
      <Box sx={{ display: 'flex' }}>
        <Button
          color="primary"
          startIcon={<EditIcon />}
          onClick={onEdit}
          sx={{ mr: 2, mb: 1 }}
        >
          Edit Neural Network
        </Button>
        <DeleteNeuralNetModal data={neuralNet} />
      </Box>
      <Typography variant="h6" sx={{ mb: 1 }}>
        Neural Network Details
      </Typography>
      <InfoBox items={info1} sx={{ mb: 3 }} />
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={_tab}
          onChange={(event, value) => {
            navigate(`/neural-nets/view/${_id}/${value}`);
          }}
        >
          <Tab value="annotations" label="Annotations" />
          <Tab value="saved-models" label="Saved Models" />
          <Tab value="train" label="Train" />
        </Tabs>
      </Box>
      <TabContext value={_tab}>
        <TabPanel value="annotations" index={0} style={{ padding: 0 }}>
          <NetAnnotationsTab netId={_id} neuralNet={neuralNet} />
        </TabPanel>
        <TabPanel value="saved-models" index={1} style={{ padding: 0 }}>
          <NetSavedModelsTab netId={_id} neuralNet={neuralNet} />
        </TabPanel>
        <TabPanel value="train" index={1} style={{ padding: 0 }}>
          <NetTrainTab
            neuralNet={neuralNet}
            netId={_id}
            status={trainStatus}
            initTraining={initTraining}
          />
        </TabPanel>
      </TabContext>
      <CreateNeuralNetModal
        visible={editing}
        onFinish={onFinishEdit}
        onCancel={onCancelEdit}
      />
    </MainPageTemplate>
  );
}

export default ViewNeuralNetPage;
