import { gql, useSubscription } from '@apollo/client';
import { useDispatch } from 'react-redux';
import { NeredeAnnotationAttributes } from './constants';
import {
  createAnnotationAction,
  deleteAnnotationAction,
  updateAnnotationAction,
} from '../../../redux-store/annotations-store';

export const AnnotationUpdateAttributes = gql`
{
  type
  crud
  id
  new ${NeredeAnnotationAttributes}
}
`;

export const neredeDatasetAnnotationUpdatesSubscription = gql`
  subscription NeredeDatasetAnnotationUpdates($datasetId: ID!) {
    neredeDatasetAnnotationUpdates(datasetId: $datasetId) ${AnnotationUpdateAttributes}
  }
`;

function useNeredeDatasetAnnotationUpdates(datasetId) {
  const queryId = datasetId || 'default';
  useSubscription(neredeDatasetAnnotationUpdatesSubscription, {
    skip: !datasetId,
    variables: {
      datasetId,
    },
    fetchPolicy: 'no-cache',
    onData: ({ client, data: result }) => {
      try {
        if (
          result &&
          result.data &&
          result.data.neredeDatasetAnnotationUpdates
        ) {
          const updates = result.data.neredeDatasetAnnotationUpdates;
          updates.forEach((update) => {
            switch (update.type) {
              case 'NEREDE_ANNOTATION_CRUD': {
                switch (update.crud) {
                  case 'CREATE':
                    dispatch(createAnnotationAction(update.new, queryId));
                    break;
                  case 'READ':
                    dispatch(createAnnotationAction(update.new, queryId));
                    break;
                  case 'UPDATE':
                    dispatch(updateAnnotationAction(update.new, queryId));
                    break;
                  case 'DELETE':
                    dispatch(deleteAnnotationAction(update.id, queryId));
                    break;
                  default:
                    break;
                }
                break;
              }
              default:
                break;
            }
          });
        }
      } catch (err) {
        console.error(err);
      }
    },
  });
  const dispatch = useDispatch();
}

export default useNeredeDatasetAnnotationUpdates;
