import React from 'react';
import TagFallDetectTable from './TagFallDetectTable';
import ZAxisPlot from './ZAxisPlot';

function FallDetectionTab({ tagLocations, zPosTraces, tagRangeMatrix }) {
  return (
    <>
      <TagFallDetectTable
        tagLocations={tagLocations}
        tagRangeMatrix={tagRangeMatrix}
      />
      <ZAxisPlot zPosTraces={zPosTraces} prefix="zPos" />
    </>
  );
}

export default FallDetectionTab;
