import { gql, useMutation, useQuery } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import { Alert, Box, Paper, Snackbar, Typography } from '@mui/material';
import { GatewayAttributes } from '@theora360/shared/src/pages/gateways/use-gateway-updates';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormContainer, TextFieldElement } from 'react-hook-form-mui';
import { shallowEqual, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import MainPageTemplate from '../../../components/MainPageTemplate';
import SensorDoorIcon from '@mui/icons-material/SensorDoor';
import SelectCampusElement from '../campuses/SelectCampusElement';
import { useDispatch } from 'react-redux';
import { showMessageAction } from '@theora360/shared/src/redux-store/snackbar-store';
import DeleteGatewayModal from './DeleteGatewayModal';

const updateGatewayMutation = gql`
  mutation UpdateGateway($gateway: GatewayUpdateInput!) {
    updateGateway(gateway: $gateway) {
      gateway ${GatewayAttributes}
    }
  }
`;

const createGatewayMutation = gql`
  mutation CreateGateway($gateway: GatewayCreateInput!) {
    createGateway(gateway: $gateway) {
      gateway ${GatewayAttributes}
    }
  }
`;

export const gatewayQuery = gql`
  query Gateway($_id: ID!) {
    gateway(_id: $_id) ${GatewayAttributes}
  }
`;

function EditGatewayPage() {
  const [loading, setLoading] = useState(false);
  const [updateGateway] = useMutation(updateGatewayMutation);
  const [createGateway] = useMutation(createGatewayMutation);
  const { _id } = useParams();
  const form = useForm();

  const {
    loading: fetchLoading,
    error: fetchError,
    data: fetched,
    refetch,
  } = useQuery(gatewayQuery, {
    variables: {
      _id,
    },
    fetchPolicy: 'network-only',
  });

  const data = fetched && fetched.gateway;

  // const data = useSelector(
  //   (store) => store.gateways.default && store.gateways.default[_id],
  //   shallowEqual,
  // );

  useEffect(() => {
    if (data) {
      form.setValue('campusId', data.campusId);
      form.setValue('label', data.label);
      form.setValue('desc', data.desc);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const onSubmit = useCallback(
    async (values) => {
      setLoading(true);
      try {
        if (data) {
          const resp1 = await updateGateway({
            variables: {
              gateway: {
                _id,
                ...values,
              },
            },
          });
        } else {
          const resp1 = await createGateway({
            variables: {
              gateway: {
                _id,
                ...values,
              },
            },
          });
        }
        dispatch(
          showMessageAction({
            _id: 'edit-gateway',
            severity: 'success',
            message: 'Gateway saved!',
          }),
        );
        navigate('/gateways');
      } catch (err) {
        console.error(err);
        dispatch(
          showMessageAction({
            _id: 'edit-gateway',
            severity: 'error',
            message: 'There was an error saving gateway',
          }),
        );
        setLoading(false);
      }
    },
    [createGateway, updateGateway, navigate, _id, data, dispatch],
  );

  return (
    <MainPageTemplate title="Edit Gateway">
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Box>
          <Typography variant="h3" sx={{ mb: 4, mt: 4 }}>
            <SensorDoorIcon style={{ height: 40, width: 40 }} /> Edit Gateway
          </Typography>
          <Paper
            elevation={1}
            sx={{ width: 400, p: 4, pt: 5, pb: 5, textAlign: 'center' }}
          >
            <FormContainer formContext={form} onSuccess={onSubmit}>
              <TextFieldElement
                style={{ width: 300, marginBottom: 32, width: '100%' }}
                variant="standard"
                name="label"
                label="Label"
                type="text"
                disabled={loading}
                required
              />
              <TextFieldElement
                style={{ width: 300, marginBottom: 32, width: '100%' }}
                variant="standard"
                name="desc"
                label="Description"
                type="text"
                disabled={loading}
              />
              <SelectCampusElement
                style={{
                  width: 300,
                  marginBottom: 32,
                  width: '100%',
                  textAlign: 'left',
                }}
                variant="standard"
                name="campusId"
                label="Campus"
                disabled={loading}
              />
              <div style={{ height: 16 }} />
              <Box>
                <LoadingButton
                  type="submit"
                  variant="contained"
                  loading={loading}
                >
                  Save Gateway
                </LoadingButton>
              </Box>
            </FormContainer>
          </Paper>
          <div style={{ textAlign: 'center', padding: 16 }}>
            <DeleteGatewayModal data={data} />
          </div>
        </Box>
      </Box>
    </MainPageTemplate>
  );
}

export default EditGatewayPage;
