import EditIcon from '@mui/icons-material/Edit';
import GroupIcon from '@mui/icons-material/GroupOutlined';
import {
  Avatar,
  Box,
  Button,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import useLocale from '@theora360/shared/src/shared/use-locale';
import React, { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { FormContainer, TextFieldElement } from 'react-hook-form-mui';
import { shallowEqual, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import MainPageTemplate from '../../../components/MainPageTemplate';
import settings from '../../../settings';
import ApartmentIcon from '@mui/icons-material/Apartment';
import AddIcon from '@mui/icons-material/Add';
import { generateUuid } from '@theora360/shared/src/shared/utils';
import useFilterCampuses from './use-filter-campuses';

const dense = false;

function CampusesPage() {
  const locale = useLocale();
  const dateFormat = useMemo(() => {
    return new Intl.DateTimeFormat(locale, {
      dateStyle: 'short',
      timeStyle: 'medium',
    });
  }, [locale]);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(settings.pageSize);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const form = useForm();
  const searchField = form.watch('search', '');

  const data = useFilterCampuses({
    filters: { search: searchField },
  });

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  return (
    <MainPageTemplate title="Campuses">
      <Typography variant="h3" gutterBottom>
        <ApartmentIcon style={{ height: 40, width: 40 }} /> Campuses
      </Typography>
      <Box sx={{ mb: 2, display: 'flex', alignItems: 'center' }}>
        <FormContainer formContext={form}>
          <TextFieldElement
            style={{ width: 300, marginBottom: 16 }}
            variant="standard"
            name="search"
            label="Search"
            type="text"
          />
        </FormContainer>
        <Button
          component={Link}
          variant="contained"
          to={`/campuses/edit/${generateUuid()}`}
          startIcon={<AddIcon />}
          sx={{ ml: 'auto' }}
        >
          New Campus
        </Button>
      </Box>
      <Paper>
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Photo</TableCell>
                <TableCell align="left">Label</TableCell>
                <TableCell align="left">Description</TableCell>
                <TableCell align="left">Updated At</TableCell>
                <TableCell align="left">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row) => (
                <TableRow
                  key={row._id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    <Avatar
                      sx={{
                        backgroundColor: settings.colors.theoraMiddleBlue,
                        width: 40,
                        height: 40,
                      }}
                      src={row.primaryThumbnailUrl}
                      alt="Logo"
                    >
                      {row.label && row.label[0]}
                    </Avatar>
                  </TableCell>
                  <TableCell align="left">{row.label}</TableCell>
                  <TableCell align="left">{row.desc}</TableCell>
                  <TableCell align="left">
                    {dateFormat.format(new Date(row.updatedAt))}
                  </TableCell>
                  <TableCell align="right" width={60}>
                    <IconButton
                      component={Link}
                      to={`/campuses/edit/${row._id}`}
                    >
                      <EditIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[settings.pageSize, settings.largePage]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </MainPageTemplate>
  );
}

export default CampusesPage;
