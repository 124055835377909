import { gql } from '@apollo/client';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {
  Box,
  Chip,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import useLocale from '@theora360/shared/src/shared/use-locale';
import { useManyRemote } from '@theora360/shared/src/shared/use-many-remote';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormContainer, TextFieldElement } from 'react-hook-form-mui';
import settings from '../../../settings';
import DeleteSavedModelModal from './DeleteSavedModelModal';
import EditSavedModelModal from './EditSavedModelModal';
import { SavedModelAttributes } from './constants';
import useSelectedCampus from '../../../shared/use-selected-campus';
import StarIcon from '@mui/icons-material/Star';
import StarSavedModelModal from './StarSavedModelModal';

export const allSavedModelsQuery = gql`
  query AllSavedModels($first: Int, $after: String, $filters: SavedModelFilters, $sortBy: [SortBy]) {
    allSavedModels(first: $first, after: $after, filters: $filters, sortBy: $sortBy) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node ${SavedModelAttributes}
      }
    }
  }
`;

export const extractSavedModels = (data) => data && data.allSavedModels;
const dense = false;

function NetSavedModelsTab({ netId, neuralNet }) {
  const filters = useRef({
    netId,
  });
  const sortBy = useRef([{ key: 'updatedAt', order: 'DESC' }]);
  const {
    error,
    loading,
    data,
    search: dataSearch,
    hasNextPage,
    next,
    refetch,
    reset,
  } = useManyRemote({
    query: allSavedModelsQuery,
    extract: extractSavedModels,
    first: settings.querySize,
    filters: filters.current,
    sortBy: sortBy.current,
    fetchPolicy: 'network-only',
    queryId: netId,
  });

  const locale = useLocale();
  const dateFormat = useMemo(() => {
    return new Intl.DateTimeFormat(locale, {
      dateStyle: 'short',
      timeStyle: 'medium',
    });
  }, [locale]);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(settings.pageSize);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const form = useForm();
  const searchField = form.watch('search', '');
  useEffect(() => {
    if (searchField) {
      dataSearch(searchField);
    }
  }, [dataSearch, searchField]);

  // const data = useFilterNetAnnotations({
  //   netId,
  //   filters: { search: searchField },
  // });
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  const [editing, setEditing] = useState();
  const onCancelEdit = () => setEditing(undefined);
  const onEdit = (savedModel) => setEditing({ data: savedModel });
  const onFinishEdit = () => {
    refetch();
    setEditing(undefined);
  };
  const [deleting, setDeleting] = useState();
  const onCancelDelete = () => setDeleting(undefined);
  const onDelete = (savedModel) => setDeleting({ data: savedModel });
  const onFinishDelete = () => {
    setDeleting(undefined);
    refetch();
  };

  const [staring, setStaring] = useState();
  const onCancelStar = () => setStaring(undefined);
  const onStar = (savedModel) => setStaring({ data: savedModel });
  const onFinishStar = () => {
    setStaring(undefined);
    refetch();
  };
  const campus = useSelectedCampus();

  return (
    <>
      <Box sx={{ mt: 2, display: 'flex', alignItems: 'center' }}>
        <FormContainer formContext={form} onSuccess={() => {}}>
          <TextFieldElement
            style={{ width: 300, marginBottom: 16 }}
            variant="standard"
            name="search"
            label="Search"
            type="text"
          />
        </FormContainer>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        <StarIcon color="secondary" sx={{ mr: 1 }} />
        <Typography>= Currently in use</Typography>
      </Box>
      <Paper>
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="left">Name</TableCell>
                <TableCell align="left">Tags</TableCell>
                <TableCell align="left">Notes</TableCell>
                <TableCell align="left">Updated At</TableCell>
                <TableCell align="left">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row) => (
                <TableRow
                  key={row._id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      {row._id === campus?.savedModelId && (
                        <StarIcon color="secondary" sx={{ mr: 1 }} />
                      )}
                      <Typography>{row.name}</Typography>
                    </Box>
                  </TableCell>

                  <TableCell align="left">
                    {row.tags &&
                      row.tags.map((tag) => (
                        <Chip
                          key={tag}
                          label={tag}
                          color="primary"
                          sx={{ mr: 1, mb: 0.5 }}
                        />
                      ))}
                  </TableCell>
                  <TableCell align="left">{row.notes}</TableCell>
                  <TableCell align="left">
                    {dateFormat.format(new Date(row.updatedAt))}
                  </TableCell>
                  <TableCell align="right" width={180}>
                    <IconButton onClick={() => onStar(row)}>
                      <StarIcon />
                    </IconButton>
                    <IconButton onClick={() => onEdit(row)}>
                      <EditIcon />
                    </IconButton>
                    <IconButton onClick={() => onDelete(row)}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[settings.pageSize, settings.largePage]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <EditSavedModelModal
        visible={editing}
        onFinish={onFinishEdit}
        onCancel={onCancelEdit}
      />
      <DeleteSavedModelModal
        deleting={deleting}
        onCancel={onCancelDelete}
        onFinish={onFinishDelete}
      />
      <StarSavedModelModal
        visible={staring}
        onFinish={onFinishStar}
        onCancel={onCancelStar}
      />
    </>
  );
}

export default NetSavedModelsTab;
