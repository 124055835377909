import { gql, useMutation } from '@apollo/client';
import ApartmentIcon from '@mui/icons-material/Apartment';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Paper, Typography } from '@mui/material';
import { CampusAttributes } from '@theora360/shared/src/pages/campuses/use-my-campus-updates';
import { showMessageAction } from '@theora360/shared/src/redux-store/snackbar-store';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormContainer, TextFieldElement } from 'react-hook-form-mui';
import { useDispatch } from 'react-redux';
import { shallowEqual, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import MainPageTemplate from '../../../components/MainPageTemplate';
import ProfilePhotoFieldElement from '../../../components/ProfilePhotoFieldElement';
import DeleteCampusModal from './DeleteCampusModal';

const updateCampusMutation = gql`
  mutation UpdateCampus($campus: CampusUpdateInput!) {
    updateCampus(campus: $campus) {
      campus ${CampusAttributes}
    }
  }
`;

const createCampusMutation = gql`
  mutation CreateCampus($campus: CampusCreateInput!) {
    createCampus(campus: $campus) {
      campus ${CampusAttributes}
    }
  }
`;

function EditCampusPage() {
  const [loading, setLoading] = useState(false);
  const [updateCampus] = useMutation(updateCampusMutation);
  const [createCampus] = useMutation(createCampusMutation);
  const { _id } = useParams();
  const form = useForm();

  const data = useSelector(
    (store) => store.campuses.default && store.campuses.default[_id],
    shallowEqual,
  );

  useEffect(() => {
    if (data) {
      form.setValue('label', data.label);
      form.setValue('desc', data.desc);
      form.setValue('primaryPhotoId', data.primaryPhotoId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const onSubmit = useCallback(
    async (values) => {
      setLoading(true);
      try {
        if (data) {
          const resp1 = await updateCampus({
            variables: {
              campus: {
                _id,
                ...values,
              },
            },
          });
        } else {
          const resp1 = await createCampus({
            variables: {
              campus: {
                _id,
                ...values,
              },
            },
          });
        }
        dispatch(
          showMessageAction({
            _id: 'edit-campus',
            severity: 'success',
            message: 'Campus saved!',
          }),
        );
        navigate('/campuses');
      } catch (err) {
        console.error(err);
        dispatch(
          showMessageAction({
            _id: 'edit-campus',
            severity: 'error',
            message: 'There was an error saving campus',
          }),
        );
        setLoading(false);
      }
    },
    [createCampus, updateCampus, navigate, _id, data, dispatch],
  );
  return (
    <MainPageTemplate title="Edit Campus">
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Box>
          <Typography variant="h3" sx={{ mb: 4, mt: 4 }}>
            <ApartmentIcon style={{ height: 40, width: 40 }} /> Edit Campus
          </Typography>
          <Paper
            elevation={1}
            sx={{ width: 400, p: 4, pt: 5, pb: 5, textAlign: 'center' }}
          >
            <FormContainer formContext={form} onSuccess={onSubmit}>
              <ProfilePhotoFieldElement
                name="logoId"
                onSubmit={onSubmit}
                profile={data}
                fileType="campus-pic"
                tags={['campus-pic']}
              />
              <TextFieldElement
                style={{ width: 300, marginBottom: 32, width: '100%' }}
                variant="standard"
                name="label"
                label="Label"
                type="text"
                disabled={loading}
                required
              />
              <TextFieldElement
                style={{ width: 300, marginBottom: 32, width: '100%' }}
                variant="standard"
                name="desc"
                label="Description"
                type="text"
                disabled={loading}
              />
              <div style={{ height: 16 }} />
              <Box>
                <LoadingButton
                  type="submit"
                  variant="contained"
                  loading={loading}
                >
                  Save Campus
                </LoadingButton>
              </Box>
            </FormContainer>
          </Paper>
          <div style={{ textAlign: 'center', padding: 16 }}>
            <DeleteCampusModal data={data} />
          </div>
        </Box>
      </Box>
    </MainPageTemplate>
  );
}

export default EditCampusPage;
