import { gql, useSubscription } from '@apollo/client';
import { useState } from 'react';

export const SiteMasterFirmwareStatusAttributes = gql`
  {
    _id
    campusId
    anchorsInManifest
    maxAnchorCount
    timeSinceStart
    buildNumber
    wifiSignalStrength
    wifiBuildTextString
    anchorTcpEnable
    anchorStdout
    vt320stream
    updatedAt
  }
`;

export const siteMasterFirmwareStatusUpdatesSubscription = gql`
  subscription SiteMasterFirmwareStatusUpdates($siteMasterId: ID!) {
    siteMasterFirmwareStatusUpdates(siteMasterId: $siteMasterId) ${SiteMasterFirmwareStatusAttributes}
  }
`;

function useSiteMasterFirmwareStatusUpdates(siteMasterId) {
  const [firmwarestatus, setFirmwareStatus] = useState();
  useSubscription(siteMasterFirmwareStatusUpdatesSubscription, {
    skip: !siteMasterId,
    variables: {
      siteMasterId,
    },
    fetchPolicy: 'no-cache',
    onData: ({ client, data: result }) => {
      try {
        if (
          result &&
          result.data &&
          result.data.siteMasterFirmwareStatusUpdates
        ) {
          const update = result.data.siteMasterFirmwareStatusUpdates;
          setFirmwareStatus(update);
        }
      } catch (err) {
        console.error(err);
      }
    },
  });
  return firmwarestatus;
}

export default useSiteMasterFirmwareStatusUpdates;
