import React from 'react';
import Error404 from '../../components/Error404';
import MainPageTemplate from '../../components/MainPageTemplate';

function Error404Page() {
  return (
    <MainPageTemplate title="Home">
      <Error404 />
    </MainPageTemplate>
  );
}

export default Error404Page;
