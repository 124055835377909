import { SelectElement, TextFieldElement } from 'react-hook-form-mui';
import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { MenuItem } from '@mui/material';

function SelectCampusElement({
  variant,
  name,
  label,
  disabled,
  required,
  style,
}) {
  const data = useSelector(
    (store) =>
      (store.campuses.default && Object.values(store.campuses.default)).filter(
        (c) => c,
      ) || [],
    shallowEqual,
  );
  return (
    <TextFieldElement
      style={style}
      variant={variant}
      name={name}
      label={label}
      type="text"
      disabled={disabled}
      select
    >
      {[{ _id: null, label: 'None' }, ...data].map((option) => (
        <MenuItem key={option._id} value={option._id}>
          {option.label}
        </MenuItem>
      ))}
    </TextFieldElement>
  );
}

export default SelectCampusElement;
