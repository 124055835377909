import MySnackbar from '@theora360/shared/src/components/MySnackbar';
import useMyCampusUpdates from '@theora360/shared/src/pages/campuses/use-my-campus-updates';
import useGatewayUpdates from '@theora360/shared/src/pages/gateways/use-gateway-updates';
import useSiteMasterUpdates from '@theora360/shared/src/pages/site-masters/use-site-master-updates';
import useAnchorUpdates from '@theora360/shared/src/pages/anchors/use-anchor-updates';
import useTagUpdates from '@theora360/shared/src/pages/tags/use-tag-updates';
import useDatasetUpdates from '@theora360/shared/src/pages/datasets/use-dataset-updates';
import queryString from 'query-string';
import React from 'react';
import { Navigate, Route, Routes } from 'react-router';
import ScrollToTop from '../../components/ScrollToTop';
import useMyProfileUpdates from '../../shared/use-my-profile-updates';
import useQueryParams from '../../shared/use-query-params';
import CampusesPage from './campuses/CampusesPage';
import EditCampusPage from './campuses/EditCampusPage';
import DashboardPage from './DashboardPage';
import EditProfilePage from './EditProfilePage';
import Error404Page from './Error404Page';
import EditGatewayPage from './gateways/EditGatewayPage';
import GatewaysPage from './gateways/GatewaysPage';
import EditUserPage from './users/EditUserPage';
import UsersPage from './users/UsersPage';
import DevicesPage from './devices/DevicesPage';
import useSelectedCampusId from '../../shared/use-selected-campus-id';
import ViewSiteMasterPage from './site-masters/ViewSiteMasterPage';
import ViewAnchorPage from './anchors/ViewAnchorPage';
import ViewTagPage from './tags/ViewTagPage';
import DatasetsPage from './datasets/DatasetsPage';
import ViewDatasetPage from './datasets/ViewDatasetPage';
import DemoPage from './demo/DemoPage';
import NeuralNetsPage from './neural-nets/NeuralNetsPage';
import ViewNeuralNetPage from './neural-nets/ViewNeuralNetPage';

function ForwardAfterLogin() {
  const query = useQueryParams();
  const { p, s, c } = query;
  let search = '';
  if (s) {
    try {
      const params = JSON.parse(s);
      search = `?${queryString.stringify({ ...params, c })}`;
    } catch (err) {
      console.error(err);
    }
  }
  if (p) {
    return <Navigate to={`${p}${search}`} />;
  }
  return <Navigate to="/home" />;
}

function ForwardAfterSignUp() {
  const query = useQueryParams();
  const { p, s, c } = query;
  let search = '';
  if (s) {
    try {
      const params = JSON.parse(s);
      search = `?${queryString.stringify({ ...params, c })}`;
    } catch (err) {
      console.error(err);
    }
  }
  if (p) {
    return <Navigate to={`${p}${search}`} />;
  }
  return <Navigate to="/profile" />;
}

function MainRouter() {
  useMyProfileUpdates();
  useMyCampusUpdates();
  const campusId = useSelectedCampusId();
  useGatewayUpdates(campusId);
  useSiteMasterUpdates(campusId);
  useTagUpdates(campusId);
  useAnchorUpdates(campusId);
  useDatasetUpdates(campusId);
  return (
    <ScrollToTop>
      <Routes>
        <Route exact path="/" element={<ForwardAfterLogin />} />
        <Route exact path="/sign-in" element={<ForwardAfterLogin />} />
        <Route
          exact
          path="/complete-sign-up"
          element={<ForwardAfterSignUp />}
        />
        <Route exact path="/home" element={<DashboardPage />} />
        <Route exact path="/profile" element={<EditProfilePage />} />
        <Route exact path="/users" element={<UsersPage />} />
        <Route exact path="/users/edit/:_id" element={<EditUserPage />} />
        <Route exact path="/campuses" element={<CampusesPage />} />
        <Route exact path="/campuses/edit/:_id" element={<EditCampusPage />} />
        <Route exact path="/gateways" element={<GatewaysPage />} />
        <Route exact path="/gateways/edit/:_id" element={<EditGatewayPage />} />
        <Route exact path="/devices/:deviceType" element={<DevicesPage />} />
        <Route exact path="/demo/:tab" element={<DemoPage />} />
        <Route
          exact
          path="/devices/site-masters/:_id/:tab"
          element={<ViewSiteMasterPage />}
        />
        <Route
          exact
          path="/devices/anchors/:_id/:tab"
          element={<ViewAnchorPage />}
        />
        <Route exact path="/devices/tags/:_id/:tab" element={<ViewTagPage />} />
        <Route exact path="/datasets" element={<DatasetsPage />} />
        <Route
          exact
          path="/datasets/view/:_id/:_tab"
          element={<ViewDatasetPage />}
        />
        <Route exact path="/neural-nets" element={<NeuralNetsPage />} />
        <Route
          exact
          path="/neural-nets/view/:_id/:_tab"
          element={<ViewNeuralNetPage />}
        />
        <Route path="*" element={<Error404Page />} />
      </Routes>
      <MySnackbar />
    </ScrollToTop>
  );
}

export default MainRouter;
