import { LoadingButton } from '@mui/lab';
import { Box, Button, Modal, Typography } from '@mui/material';
import React, { useCallback, useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { showMessageAction } from '@theora360/shared/src/redux-store/snackbar-store';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import RestartAltIcon from '@mui/icons-material/RestartAlt';

const resetAnchorSequenceStatsMutation = gql`
  mutation ResetAnchorSequenceStats($_id: ID!) {
    resetAnchorSequenceStats(_id: $_id)
  }
`;

function ResetAnchorSequenceStatsBtn({ data }) {
  const [deleting, setDeleting] = useState(false);
  const [loading, setLoading] = useState(false);
  const onCancel = () => setDeleting(false);
  const onReset = () => setDeleting(true);

  const [resetAnchorSequenceStats] = useMutation(
    resetAnchorSequenceStatsMutation,
  );
  const dispatch = useDispatch();
  const onSubmit = useCallback(async () => {
    setLoading(true);
    try {
      const resp1 = await resetAnchorSequenceStats({
        variables: {
          _id: data._id,
        },
      });
      dispatch(
        showMessageAction({
          _id: 'reset-sequence-stats',
          severity: 'success',
          message: 'Message Sequence Status Reset!',
        }),
      );
      setDeleting(false);
    } catch (err) {
      console.error(err);
      dispatch(
        showMessageAction({
          _id: 'reset-sequence-stats',
          severity: 'error',
          message: 'There was an error resetting sequence stats',
        }),
      );
    }
    setLoading(false);
  }, [resetAnchorSequenceStats, data, dispatch]);
  return (
    <>
      <Modal
        open={deleting}
        onClose={onCancel}
        sx={{
          overflow: 'scroll',
          p: 4,
        }}
      >
        <Box
          sx={{
            backgroundColor: 'white',
            textAlign: 'center',
            borderRadius: 1,
            padding: 4,
            marginTop: 4,
            marginBottom: 4,
            width: 500,
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        >
          <Typography variant="h4" sx={{ mb: 3 }}>
            Reset Message Sequence Stats
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            Are you sure you want to reset this anchor's message sequence stats?
          </Typography>
          <Typography variant="body1" sx={{ mb: 4 }}>
            {data && data.label}
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Button sx={{ mr: 4 }} onClick={onCancel}>
              Cancel
            </Button>
            <LoadingButton
              variant="contained"
              loading={loading}
              onClick={onSubmit}
            >
              Reset
            </LoadingButton>
          </Box>
        </Box>
      </Modal>
      <Button
        startIcon={<RestartAltIcon />}
        variant="outlined"
        onClick={onReset}
        color="subdued"
        sx={{ mb: 2 }}
      >
        Reset Sequence Stats
      </Button>
    </>
  );
}

export default ResetAnchorSequenceStatsBtn;
