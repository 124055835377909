import { useCallback, useMemo, useRef } from 'react';
import { tagColors } from './constants';

function useZPosTraces(tagLocations) {
  const data = useRef({});
  const zero = useRef(null);
  const zPosTraces = useMemo(() => {
    tagLocations.forEach((tagLoc) => {
      if (!data.current[tagLoc.tagId]) {
        data.current[tagLoc.tagId] = {
          x: [],
          y: [],
          yErrorMinus: [],
          yErrorPlus: [],
          yUnfiltered: [],
          lastTimestamp: undefined,
          name: `Tag ${tagLoc.tagId}`,
        };
      }
      const timestamp = tagLoc.epochTimestamp / 1000.0;
      if (
        data.current[tagLoc.tagId].lastTimestamp !== timestamp &&
        tagLoc.xyzPos
      ) {
        data.current[tagLoc.tagId].y.push(tagLoc.xyzPos[2]);
        data.current[tagLoc.tagId].yErrorMinus.push(
          tagLoc.xyzPos[2] - Math.sqrt(tagLoc.xyzVar[2]),
        );
        data.current[tagLoc.tagId].yErrorPlus.push(
          tagLoc.xyzPos[2] + Math.sqrt(tagLoc.xyzVar[2]),
        );
        data.current[tagLoc.tagId].yUnfiltered.push(tagLoc.xyzUnfiltered[2]);
        if (zero.current === null) {
          zero.current = timestamp;
        }
        data.current[tagLoc.tagId].x.push(timestamp - zero.current);
        data.current[tagLoc.tagId].lastTimestamp = timestamp;
      }
    });

    const traces = tagLocations.reduce((prev, tagLoc, index) => {
      if (data.current[tagLoc.tagId]) {
        prev.push({
          x: data.current[tagLoc.tagId].x,
          y: data.current[tagLoc.tagId].yUnfiltered,
          name: `${tagLoc.tagId} unfiltered`,
          // text: anchorLocs.map((a) => a.label),
          mode: 'lines',
          visible: 'legendonly',
          color: tagColors[index % tagColors.length],
          type: 'scatter',
        });
        prev.push({
          x: data.current[tagLoc.tagId].x,
          y: data.current[tagLoc.tagId].y,
          name: `${tagLoc.tagId} filtered`,
          // text: anchorLocs.map((a) => a.label),
          mode: 'lines+markers',
          color: tagColors[index % tagColors.length],
          type: 'scatter',
        });
        prev.push({
          x: data.current[tagLoc.tagId].x,
          y: data.current[tagLoc.tagId].yErrorPlus,
          name: `${tagLoc.tagId} +`,
          // text: anchorLocs.map((a) => a.label),
          mode: 'lines',
          dash: 'dot',
          color: tagColors[index % tagColors.length],
          type: 'scatter',
        });
        prev.push({
          x: data.current[tagLoc.tagId].x,
          y: data.current[tagLoc.tagId].yErrorMinus,
          name: `${tagLoc.tagId} -`,
          // text: anchorLocs.map((a) => a.label),
          mode: 'lines',
          dash: 'dot',
          color: tagColors[index % tagColors.length],
          type: 'scatter',
        });
      }
      return prev;
    }, []);

    traces.sort((a, b) => a.name.localeCompare(b.name));
    return traces;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tagLocations]);

  const reset = useCallback(() => {
    data.current = {};
    zero.current = null;
  }, []);

  return [zPosTraces, reset];
}

export default useZPosTraces;
