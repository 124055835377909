import { gql, useSubscription } from '@apollo/client';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  createCampusAction,
  deleteCampusAction,
  updateCampusAction,
} from '../../redux-store/campus-store';

export const CampusAttributes = gql`
  {
    _id
    label
    desc
    primaryThumbnailUrl
    savedModelId
    createdAt
    updatedAt
    deletedAt
  }
`;

export const CampusUpdateAttributes = gql`
{
  type
  crud
  id
  new ${CampusAttributes}
}
`;

export const myCampusUpdatesSubscription = gql`
  subscription MyCampusUpdates {
    myCampusUpdates ${CampusUpdateAttributes}
  }
`;

function useMyCampusUpdates() {
  useSubscription(myCampusUpdatesSubscription, {
    skip: false,
    variables: {},
    fetchPolicy: 'no-cache',
    shouldResubscribe: false,
    onData: ({ client, data: result }) => {
      try {
        if (result.data && result.data.myCampusUpdates) {
          const updates = result.data.myCampusUpdates;
          updates.forEach((update) => {
            switch (update.type) {
              case 'CAMPUS_CRUD': {
                switch (update.crud) {
                  case 'CREATE':
                    dispatch(createCampusAction(update.new, 'default'));
                    break;
                  case 'READ':
                    dispatch(updateCampusAction(update.new, 'default'));
                    break;
                  case 'UPDATE':
                    dispatch(updateCampusAction(update.new, 'default'));
                    break;
                  case 'DELETE':
                    dispatch(deleteCampusAction(update.id, 'default'));
                    break;
                  default:
                    break;
                }
                break;
              }
              default:
                break;
            }
          });
        }
      } catch (err) {
        console.error(err);
      }
    },
  });

  const dispatch = useDispatch();
}

export default useMyCampusUpdates;
