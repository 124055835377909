import MovieIcon from '@mui/icons-material/Movie';
import { TabContext, TabPanel } from '@mui/lab';
import { Box, Button, Tab, Tabs, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import MainPageTemplate from '../../../components/MainPageTemplate';
import useSafeState from '../../../shared/use-safe-state';
import DevicesTab from './DevicesTab';
import NeighborRangesTab from './NeighborRangesTab';
import RangesTab from './RangesTab';
import TagLocationPlot from './TagLocationPlot';
import useTagLocationUpdates from '../tags/use-tag-location';
import useSelectedCampusId from '../../../shared/use-selected-campus-id';
import TagLocationTab from './TagLocationTab';
import useAnchorRangeUpdates from '../anchors/use-anchor-ranges';
import FallDetectionTab from './FallDetectionTab';
import useZPosTraces from './use-z-pos-traces';
import useRangeTraces from './use-range-traces';
import useTagRangeMatrix from './use-tag-range-matrix';
import use3dLocationTraces from './use-3d-location-traces';
import useFilterAnchors from '../anchors/use-filter-anchors';
import { formatDuration } from '@theora360/shared/src/shared/utils';
import useAiTagLocationUpdates from '../tags/use-ai-tag-location';
import AITagLocationTab from './AITagLocationTab';
import DemoExportButton from './DemoExportButton';
import useTagLocHistory from './use-tag-loc-history';

function AnchorRangeSubscription({ paused, setUpdate }) {
  const campusId = useSelectedCampusId();
  useAnchorRangeUpdates(paused ? undefined : campusId, setUpdate);
  return null;
}

function TagLocationsSubcription({ paused, setUpdate }) {
  const campusId = useSelectedCampusId();
  useTagLocationUpdates(paused ? undefined : campusId, setUpdate);
  return null;
}

function AiTagLocationsSubcription({ paused, setUpdate }) {
  const campusId = useSelectedCampusId();
  useAiTagLocationUpdates(paused ? undefined : campusId, setUpdate);
  return null;
}

let renders = 0;

function DemoPage() {
  const navigate = useNavigate();
  const { tab } = useParams();

  const [paused, setPaused] = useState(false);

  const [tagLocations, setTagLocations] = useState([]);
  const [aiTagLocations, setAiTagLocations] = useState([]);

  const [anchorRangeUpdate, setAnchorRangeUpdate] = useState({
    ranges: {},
    tagIds: [],
    anchorIds: [],
  });
  const { ranges, tagIds, anchorIds } = anchorRangeUpdate;

  const [zPosTraces, resetZPosTraces] = useZPosTraces(tagLocations);
  const [tagLocHistory, resetTagLocHistory] = useTagLocHistory(tagLocations);
  const [tagRangeMatrix, latestTagRangeTimestamp] = useTagRangeMatrix(
    ranges,
    tagIds,
    anchorIds,
  );
  const [allRangeTraces, resetRangeTraces, anchorRangeData] = useRangeTraces(
    tagRangeMatrix,
    anchorIds,
  );

  const campusId = useSelectedCampusId();
  const anchors = useFilterAnchors({
    campusId,
    filters: {},
  });
  const tag3dLocationTraces = use3dLocationTraces(tagLocations, anchors);

  const elapsed = useMemo(() => {
    if (latestTagRangeTimestamp > 0) {
      const nowNow = new Date().getTime() / 1000;
      return nowNow - latestTagRangeTimestamp;
    }
    return 0;
  }, [latestTagRangeTimestamp]);

  return (
    <MainPageTemplate title="Live Data">
      <Typography variant="h3" gutterBottom>
        <MovieIcon style={{ height: 40, width: 40 }} /> Live Data
      </Typography>
      <Box sx={{ mb: 2 }}>
        <Button
          variant="outlined"
          onClick={() => setPaused(!paused)}
          sx={{ mr: 2 }}
        >
          {paused ? 'Resume' : 'Pause'}
        </Button>
        <Button
          variant="outlined"
          onClick={() => {
            resetRangeTraces();
            resetZPosTraces();
            resetTagLocHistory();
          }}
          sx={{ mr: 2 }}
        >
          Reset
        </Button>
        <DemoExportButton
          tagIds={tagIds}
          anchorIds={anchorIds}
          anchorRangeData={anchorRangeData}
          tagLocHistory={tagLocHistory}
        />
      </Box>
      <Box sx={{ mb: 2 }}>
        <Typography variant="h6">Current Epoch Time</Typography>
        <Typography>{`${formatDuration(elapsed)} ago`}</Typography>
      </Box>
      <AnchorRangeSubscription
        paused={paused}
        setUpdate={setAnchorRangeUpdate}
      />
      <TagLocationsSubcription paused={paused} setUpdate={setTagLocations} />
      <AiTagLocationsSubcription
        paused={paused}
        setUpdate={setAiTagLocations}
      />
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={tab}
          onChange={(event, value) => {
            navigate(`/demo/${value}`);
          }}
        >
          <Tab value="summary" label="Summary" />
          <Tab value="ranges" label="Tag Ranges" />
          <Tab value="location" label="Tag Location" />
          <Tab value="ai-location" label="ai Tag Location" />
          <Tab value="fall-detection" label="Fall Detection" />
          <Tab value="neighbors" label="Neighbor Ranges" />
        </Tabs>
      </Box>
      <TabContext value={tab}>
        <TabPanel value="summary" index={0}>
          <DevicesTab />
        </TabPanel>
        <TabPanel value="ranges" index={1}>
          <RangesTab
            tagIds={tagIds}
            anchorIds={anchorIds}
            tagRangeMatrix={tagRangeMatrix}
            allRangeTraces={allRangeTraces}
            latestTagRangeTimestamp={latestTagRangeTimestamp}
          />
        </TabPanel>
        <TabPanel value="location" index={2}>
          <TagLocationTab
            tagLocations={tagLocations}
            tag3dLocationTraces={tag3dLocationTraces}
          />
        </TabPanel>
        <TabPanel value="ai-location" index={3}>
          <AITagLocationTab aiTagLocations={aiTagLocations} />
        </TabPanel>
        <TabPanel value="fall-detection" index={4}>
          <FallDetectionTab
            tagLocations={tagLocations}
            zPosTraces={zPosTraces}
            tagRangeMatrix={tagRangeMatrix}
          />
        </TabPanel>
        <TabPanel value="neighbors" index={5}>
          <NeighborRangesTab />
        </TabPanel>
      </TabContext>
    </MainPageTemplate>
  );
}

export default DemoPage;
