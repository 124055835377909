import { useMemo, useRef } from 'react';
import Fuse from 'fuse.js';
import { shallowEqual, useSelector } from 'react-redux';

const searchOptions = {
  includeScore: true,
  keys: ['message', 'type'],
};

function defaultSort(a, b) {
  if (a && a.timestamp && b && b.timestamp) {
    return b.timestamp - a.timestamp;
  }
  if (a && a.timestamp) {
    return 1;
  }
  if (b && b.timestamp) {
    return -1;
  }
  return 0;
}

function useFilterEvents({ campusId, filters, sortBy }) {
  const queryId = campusId || 'default';
  const data = useSelector(
    (store) =>
      (store.events[queryId] && Object.values(store.events[queryId])) || [],
    shallowEqual,
  );
  const lastResult = useRef();
  const jsonFilters = JSON.stringify(filters);
  const filtered = useMemo(() => {
    let _data = data.filter((d) => d);
    if (filters && filters.anchorId) {
      _data = _data.filter((d) => d.anchorId === filters.anchorId);
    }
    if (filters && filters.siteMasterId) {
      _data = _data.filter((d) => d.siteMasterId === filters.siteMasterId);
    }
    if (filters && filters.search) {
      const fuse = new Fuse(_data, searchOptions);
      _data = fuse
        .search(filters.search)
        .sort((a, b) => a.score - b.score)
        .map((i) => i.item);
      lastResult.current = _data;
    } else {
      lastResult.current = _data;
    }
    if (sortBy) {
      return lastResult.current.sort(sortBy);
    }
    return lastResult.current.sort(defaultSort);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jsonFilters, data, sortBy]);
  return filtered;
}

export default useFilterEvents;
