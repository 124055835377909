import {
  List,
  ListItemButton,
  ListItemText,
  Menu,
  MenuItem,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { selectCampusAction } from '../redux-store/selected-campus';
import useSelectedCampusId from '../shared/use-selected-campus-id';

function SelectCampusMenu() {
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const dispatch = useDispatch();
  const handleMenuItemClick = (event, index) => {
    dispatch(selectCampusAction(campuses[index]._id));
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const campuses = useSelector(
    (store) =>
      (store.campuses.default && Object.values(store.campuses.default)).filter(
        (c) => c,
      ) || [],
    shallowEqual,
  );
  const selectedCampusId = useSelectedCampusId();
  const selectedIndex = campuses.findIndex((c) => c._id === selectedCampusId);

  useEffect(() => {
    if (campuses && campuses.length && !campuses[selectedIndex]) {
      dispatch(selectCampusAction(campuses[0]._id));
    }
  }, [selectedIndex, campuses, dispatch]);

  return (
    <div>
      <List component="nav" sx={{ bgcolor: 'background.paper' }}>
        <ListItemButton onClick={handleClickListItem}>
          <ListItemText
            primary={campuses[selectedIndex] && campuses[selectedIndex].label}
            secondary="Campus"
          />
        </ListItemButton>
      </List>
      <Menu
        id="lock-menu"
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleClose}
      >
        {campuses.map((option, index) => (
          <MenuItem
            key={option._id}
            selected={index === selectedIndex}
            onClick={(event) => handleMenuItemClick(event, index)}
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}

export default SelectCampusMenu;
