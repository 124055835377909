import { useApolloClient } from '@apollo/client';
import ScatterPlotIcon from '@mui/icons-material/ScatterPlot';
import { Box, Paper, Typography } from '@mui/material';
import useLocale from '@theora360/shared/src/shared/use-locale';
import Plotly from 'plotly.js-dist';
import React, { useEffect, useMemo, useRef } from 'react';
import { useDispatch } from 'react-redux';
import TagXYZTable from './TagXYZTable';

function TagLocationPlot({
  prefix = 'demo',
  height = 600,
  tag3dLocationTraces,
  tagLocations,
}) {
  const firstPlot = useRef();
  const dispatch = useDispatch();
  const client = useApolloClient();
  useEffect(() => {
    const { anchorsTrace, tagsTrace, ellipseTraces, rangeTraces } =
      tag3dLocationTraces;

    const xMin = Math.min(anchorsTrace.x);
    const yMin = Math.min(anchorsTrace.y);
    const zMin = Math.min(anchorsTrace.z);
    const xMax = Math.max(anchorsTrace.x);
    const yMax = Math.max(anchorsTrace.y);
    const zMax = Math.max(anchorsTrace.z);
    const xRange = xMax - xMin;
    const yRange = yMax - yMin;
    const zRange = zMax - zMin;

    if (ellipseTraces.length === firstPlot.current) {
      // tagRangeSpheres
      const data = {
        x: [
          anchorsTrace.x,
          tagsTrace.x,
          ...ellipseTraces.map((e) => e.x),
          ...rangeTraces.map((s) => s.x),
        ],
        y: [
          anchorsTrace.y,
          tagsTrace.y,
          ...ellipseTraces.map((e) => e.y),
          ...rangeTraces.map((s) => s.y),
        ],
        z: [
          anchorsTrace.z,
          tagsTrace.z,
          ...ellipseTraces.map((e) => e.z),
          ...rangeTraces.map((s) => s.z),
        ],
        text: [
          anchorsTrace.text,
          tagsTrace.text,
          ...ellipseTraces.map((e) => e.text),
          ...rangeTraces.map((s) => s.text),
        ],
        'marker.color': [
          anchorsTrace.marker?.color,
          tagsTrace.marker?.color,
          ...ellipseTraces.map((e) => undefined),
          ...rangeTraces.map((s) => undefined),
        ],
        'marker.line.color': [
          anchorsTrace.marker?.line?.color,
          tagsTrace.marker?.line?.color,
          ...ellipseTraces.map((e) => undefined),
          ...rangeTraces.map((s) => undefined),
        ],
      };
      Plotly.update(`${prefix}_myPlot`, data);
    } else {
      const data = [anchorsTrace, tagsTrace, ...ellipseTraces, ...rangeTraces];
      var layout = {
        uirevision: true,
        margin: {
          l: 0,
          r: 0,
          b: 0,
          t: 0,
        },
        xaxis: {
          range: [xMin - xRange * 0.1, xMax + xRange * 0.1],
        },
        yaxis: {
          scaleanchor: 'x',
          scaleratio: 1,
          range: [yMin - yRange * 0.1, yMax + yRange * 0.1],
        },
        zaxis: {
          scaleanchor: 'x',
          scaleratio: 1,
          range: [zMin - zRange * 0.1, zMax + zRange * 0.1],
        },
      };
      Plotly.newPlot(`${prefix}_myPlot`, data, layout);
      firstPlot.current = ellipseTraces.length;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tag3dLocationTraces, dispatch, client]);

  const locale = useLocale();
  const lastUpdate = useMemo(() => {
    return Intl.DateTimeFormat(locale, { timeStyle: 'long' }).format(
      new Date(),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tag3dLocationTraces, locale]);

  return (
    <>
      <Typography variant="h5" sx={{ mt: 3, mb: 2 }}>
        <ScatterPlotIcon style={{ height: 40, width: 40 }} /> Tag Location
      </Typography>
      <Box sx={{ mb: 2 }}>
        <Typography variant="p1">{lastUpdate}</Typography>
      </Box>
      <TagXYZTable tagLocations={tagLocations} />
      <Paper sx={{ p: 2 }}>
        <div id={`${prefix}_myPlot`} className="plot" style={{ height }} />
      </Paper>
    </>
  );
}

export default TagLocationPlot;
