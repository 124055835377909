import { gql, useMutation, useQuery } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import { Alert, Box, Paper, Snackbar, Typography } from '@mui/material';
import { showMessageAction } from '@theora360/shared/src/redux-store/snackbar-store';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  FormContainer,
  PasswordElement,
  TextFieldElement,
} from 'react-hook-form-mui';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import MainPageTemplate from '../../../components/MainPageTemplate';
import ProfilePhotoFieldElement from '../../../components/ProfilePhotoFieldElement';
import { UserAttributes, userQuery } from './constants';
import SelectRoleElement from './SelectRoleElement';

const updateUserMutation = gql`
  mutation UpdateUser($user: UserUpdateInput!) {
    updateUser(user: $user) {
      user ${UserAttributes}
    }
  }
`;

const createUserMutation = gql`
  mutation CreateUser($user: UserCreateInput!) {
    createUser(user: $user) {
      user ${UserAttributes}
    }
  }
`;

function EditUserPage() {
  const [loading, setLoading] = useState(false);
  const [updateUser] = useMutation(updateUserMutation);
  const [createUser] = useMutation(createUserMutation);
  const { _id } = useParams();
  const form = useForm();

  const {
    loading: fetchLoading,
    error: fetchError,
    data,
    refetch,
  } = useQuery(userQuery, {
    variables: {
      _id,
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (data) {
      const fetched = data.user;
      form.setValue('username', fetched.username);
      form.setValue('email', fetched.email);
      form.setValue('name', fetched.name);
      form.setValue('roleId', fetched.roleId);
      form.setValue('phone', fetched.phone);
      form.setValue('primaryPhotoId', fetched.primaryPhotoId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const onSubmit = useCallback(
    async (values) => {
      setLoading(true);
      try {
        if (data) {
          const resp1 = await updateUser({
            variables: {
              user: {
                _id,
                ...values,
              },
            },
          });
        } else {
          const resp1 = await createUser({
            variables: {
              user: {
                _id,
                ...values,
              },
            },
          });
        }
        dispatch(
          showMessageAction({
            _id: 'edit-user',
            severity: 'success',
            message: 'User saved!',
          }),
        );
        navigate('/users');
      } catch (err) {
        console.error(err);
        dispatch(
          showMessageAction({
            _id: 'edit-user',
            severity: 'error',
            message: 'There was an error saving user',
          }),
        );
        setLoading(false);
      }
    },
    [updateUser, createUser, data, dispatch, navigate, _id],
  );
  return (
    <MainPageTemplate title="Edit User">
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Box sx={{ textAlign: 'center' }}>
          <Typography variant="h2" gutterBottom>
            Edit User
          </Typography>
          <Paper
            elevation={1}
            sx={{ width: 400, p: 4, pt: 5, pb: 5, textAlign: 'center' }}
          >
            <FormContainer formContext={form} onSuccess={onSubmit}>
              <ProfilePhotoFieldElement
                name="profilePhotoId"
                onSubmit={onSubmit}
                profile={data && data.user}
                fileType="profile-pic"
                tags={['profile-pic']}
              />
              <TextFieldElement
                style={{ width: 300, marginBottom: 32, width: '100%' }}
                variant="standard"
                name="name"
                label="Name"
                type="text"
                disabled={loading}
                required
              />
              <TextFieldElement
                style={{ width: 300, marginBottom: 32, width: '100%' }}
                variant="standard"
                name="username"
                label="Username"
                type="text"
                disabled={loading}
                required
              />
              <TextFieldElement
                style={{ width: 300, marginBottom: 32, width: '100%' }}
                variant="standard"
                name="email"
                label="Email"
                type="email"
                disabled={loading}
                required
              />
              {!data && (
                <PasswordElement
                  style={{ width: 300, marginBottom: 32, width: '100%' }}
                  variant="standard"
                  name="password"
                  label="Password"
                  disabled={loading}
                  required
                />
              )}
              <TextFieldElement
                style={{ width: 300, marginBottom: 32, width: '100%' }}
                variant="standard"
                name="phone"
                label="Phone"
                type="tel"
                validation={{
                  pattern: {
                    value:
                      /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
                    message: 'Invalid phone number',
                  },
                }}
                disabled={loading}
              />
              <SelectRoleElement
                style={{ width: 300, marginBottom: 32, width: '100%' }}
                variant="standard"
                name="roleId"
                label="Role"
              />
              <Box style={{ mt: 1 }}>
                <LoadingButton
                  type="submit"
                  variant="contained"
                  loading={loading}
                >
                  Save User
                </LoadingButton>
              </Box>
            </FormContainer>
          </Paper>
        </Box>
      </Box>
    </MainPageTemplate>
  );
}

export default EditUserPage;
