import { gql, useSubscription } from '@apollo/client';
import { useState } from 'react';

export const SiteMasterLanTimeslotsAttributes = gql`
  {
    _id
    campusId
    lastMsgServerMsgNum
    lastMsgNum
    lastMsgHundreds
    lastMsgErrorsInRequest
    lans {
      _id
      timeslot
      numAnchors
      status
      updatedAt
    }
    updatedAt
  }
`;

export const siteMasterLanTimeslotUpdatesSubscription = gql`
  subscription SiteMasterLanTimeslotsUpdates($siteMasterId: ID!) {
    siteMasterLanTimeslotUpdates(siteMasterId: $siteMasterId) ${SiteMasterLanTimeslotsAttributes}
  }
`;

function useSiteMasterLanTimeslotsUpdates(siteMasterId) {
  const [anchormanifest, setLanTimeslots] = useState();
  useSubscription(siteMasterLanTimeslotUpdatesSubscription, {
    skip: !siteMasterId,
    variables: {
      siteMasterId,
    },
    fetchPolicy: 'no-cache',
    onData: ({ client, data: result }) => {
      try {
        if (result && result.data && result.data.siteMasterLanTimeslotUpdates) {
          const update = result.data.siteMasterLanTimeslotUpdates;
          setLanTimeslots(update);
        }
      } catch (err) {
        console.error(err);
      }
    },
  });
  return anchormanifest;
}

export default useSiteMasterLanTimeslotsUpdates;
