import { gql, useSubscription } from '@apollo/client';
import { useState } from 'react';

export const AnchorStdoutAttributes = gql`
  {
    _id
    campusId
    timestamp
    lines
    updatedAt
  }
`;

export const anchorStdoutUpdatesSubscription = gql`
  subscription AnchorStdoutUpdates($anchorId: ID!) {
    anchorStdoutUpdates(anchorId: $anchorId) ${AnchorStdoutAttributes}
  }
`;

function useAnchorStdoutUpdates(anchorId) {
  const [stdout, setStdout] = useState();
  useSubscription(anchorStdoutUpdatesSubscription, {
    skip: !anchorId,
    variables: {
      anchorId,
    },
    fetchPolicy: 'no-cache',
    onData: ({ client, data: result }) => {
      try {
        if (result && result.data && result.data.anchorStdoutUpdates) {
          const update = result.data.anchorStdoutUpdates;
          setStdout(update);
        }
      } catch (err) {
        console.error(err);
      }
    },
  });
  return stdout;
}

export default useAnchorStdoutUpdates;
