import { LoadingButton } from '@mui/lab';
import { Box, Button, Modal, Typography } from '@mui/material';
import React, { useCallback, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { gql, useMutation } from '@apollo/client';
import { showMessageAction } from '@theora360/shared/src/redux-store/snackbar-store';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { formatDuration } from '@theora360/shared/src/shared/utils';

const deleteNeredeAnnotationMutation = gql`
  mutation DeleteNeredeAnnotation($_id: ID!) {
    deleteNeredeAnnotation(_id: $_id) {
      deletedId
    }
  }
`;

function DeleteAnnotationModal({ deleting, onCancel, onFinish }) {
  const [loading, setLoading] = useState(false);

  const [deleteNeredeAnnotation] = useMutation(deleteNeredeAnnotationMutation);
  const dispatch = useDispatch();
  const onSubmit = useCallback(async () => {
    setLoading(true);
    try {
      await deleteNeredeAnnotation({
        variables: {
          _id: deleting._id,
        },
      });
      dispatch(
        showMessageAction({
          _id: 'delete-annotation',
          severity: 'success',
          message: 'Dataset deleted!',
        }),
      );
      onFinish();
    } catch (err) {
      console.error(err);
      dispatch(
        showMessageAction({
          _id: 'delete-annotation',
          severity: 'error',
          message: 'There was an error deleting annotation',
        }),
      );
      setLoading(false);
    }
  }, [deleteNeredeAnnotation, deleting, dispatch, onFinish]);
  return (
    <>
      <Modal
        open={!!deleting}
        onClose={onCancel}
        sx={{
          overflow: 'scroll',
          p: 4,
        }}
      >
        <Box
          sx={{
            backgroundColor: 'white',
            textAlign: 'center',
            borderRadius: 1,
            padding: 4,
            marginTop: 4,
            marginBottom: 4,
            width: 500,
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        >
          <Typography variant="h4" sx={{ mb: 3 }}>
            Delete Dataset
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            Are you sure you want to delete this annotation?
          </Typography>
          <Typography variant="body1" sx={{ mb: 4 }}>
            {deleting &&
              `${deleting.label} (${formatDuration(deleting.duration)})`}
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Button sx={{ mr: 4 }} onClick={onCancel}>
              Cancel
            </Button>
            <LoadingButton
              variant="contained"
              loading={loading}
              onClick={onSubmit}
            >
              Delete
            </LoadingButton>
          </Box>
        </Box>
      </Modal>
    </>
  );
}

export default DeleteAnnotationModal;
