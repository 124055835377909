import { gql, useMutation } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import { Box, Modal, TextField, Typography } from '@mui/material';
import { TagAttributes } from '@theora360/shared/src/pages/tags/use-tag-updates';
import { showMessageAction } from '@theora360/shared/src/redux-store/snackbar-store';
import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { FormContainer, TextFieldElement } from 'react-hook-form-mui';
import { useDispatch } from 'react-redux';
import SelectCampusFieldElement from '../../../components/SelectCampusFieldElement';

const updateTagMutation = gql`
  mutation UpdateTag($tag: TagUpdateInput!) {
    updateTag(tag: $tag) {
      tag ${TagAttributes}
    }
  }
`;

const createTagMutation = gql`
  mutation CreateTag($tag: TagCreateInput!) {
    createTag(tag: $tag) {
      tag ${TagAttributes}
    }
  }
`;

export const tagQuery = gql`
  query Tag($_id: ID!) {
    tag(_id: $_id) ${TagAttributes}
  }
`;

function EditTagModal({ visible, onCancel, onFinish }) {
  const { data } = visible || {};
  const [loading, setLoading] = useState(false);
  const [updateTag] = useMutation(updateTagMutation);
  const [createTag] = useMutation(createTagMutation);
  const form = useForm();

  useEffect(() => {
    if (data) {
      form.setValue('label', data.label);
      form.setValue('desc', data.desc);
      form.setValue('campusId', data.campusId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const dispatch = useDispatch();
  const onSubmit = useCallback(
    async (_values) => {
      const { ...values } = _values;
      setLoading(true);
      try {
        const resp1 = await updateTag({
          variables: {
            tag: {
              _id: data._id,
              ...values,
            },
          },
        });
        dispatch(
          showMessageAction({
            _id: 'edit-tag',
            severity: 'success',
            message: 'Tag saved!',
          }),
        );
        onFinish();
      } catch (err) {
        console.error(err);
        dispatch(
          showMessageAction({
            _id: 'edit-tag',
            severity: 'error',
            message: 'There was an error saving tag',
          }),
        );
      }
      setLoading(false);
    },
    [updateTag, data, dispatch, onFinish],
  );

  return (
    <Modal
      open={!!visible}
      onClose={onCancel}
      sx={{
        overflow: 'scroll',
        p: 4,
      }}
    >
      <Box
        sx={{
          backgroundColor: 'white',
          textAlign: 'center',
          borderRadius: 1,
          padding: 4,
          marginTop: 4,
          marginBottom: 4,
          width: 500,
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
      >
        <Typography variant="h4" sx={{ mb: 3 }}>
          Edit Tag
        </Typography>
        <FormContainer formContext={form} onSuccess={onSubmit}>
          <SelectCampusFieldElement />
          <TextFieldElement
            style={{ marginBottom: 32, width: '100%' }}
            variant="standard"
            name="label"
            label="Label"
            type="text"
            disabled={loading}
            required
          />
          <TextFieldElement
            style={{ marginBottom: 32, width: '100%' }}
            variant="standard"
            name="desc"
            label="Description"
            type="text"
            disabled={loading}
          />
          <div style={{ height: 16 }} />
          <Box>
            <LoadingButton type="submit" variant="contained" loading={loading}>
              Save Tag
            </LoadingButton>
          </Box>
        </FormContainer>
      </Box>
    </Modal>
  );
}

export default EditTagModal;
