import ScatterPlotIcon from '@mui/icons-material/ScatterPlot';
import { Box, Button, Paper, Typography } from '@mui/material';
import Plotly from 'plotly.js-dist';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import LabelOutlinedIcon from '@mui/icons-material/LabelOutlined';
import useSafeState from '../../../shared/use-safe-state';
import BookmarksOutlinedIcon from '@mui/icons-material/BookmarksOutlined';
import useFilterDatasetAnnotations from './use-filter-dataset-annotations';

function DatasetRangesPlot({
  prefix = 'demo',
  height = 600,
  title,
  rangeTraces,
  tagId,
  anchorIds,
  onAnnotate,
  datasetId,
}) {
  const [show, setShow] = useState(false);
  const [annotating, setAnnotating, _annotating] = useSafeState(0);
  const currentAnn = useRef();
  const handleAnnotate = () => {
    if (annotating > 0) {
      setAnnotating(0);
    } else {
      setAnnotating(1);
    }
  };

  const annotations = useFilterDatasetAnnotations({ datasetId, filters: {} });
  const annotationTraces = useMemo(() => {
    const highest = {};
    const lowest = {};
    let startTimestamp = Infinity;
    console.log('rangeTraces', rangeTraces);
    rangeTraces.forEach((trace) => {
      trace.t?.forEach((t, i) => {
        if (t < startTimestamp) {
          startTimestamp = t;
        }
        const y = trace.y[i];
        if (highest[t] === undefined || highest[t] < y) {
          highest[t] = y;
        }
        if (lowest[t] === undefined || lowest[t] > y) {
          lowest[t] = y;
        }
      });
    });

    return annotations.map((a) => {
      const entries = Object.entries(highest)
        .filter(([t, y]) => t >= a.startTimestampMs && t <= a.endTimestampMs)
        .concat(
          Object.entries(lowest)
            .filter(
              ([t, y]) => t >= a.startTimestampMs && t <= a.endTimestampMs,
            )
            .reverse(),
        );
      entries.push(entries[0]);
      return {
        x: entries.map(([t, y]) => (t - startTimestamp) / 1000),
        y: entries.map(([t, y]) => y),
        name: a.label,
        fill: 'toself',
        fillcolor: '#00147955',
        line: { color: '#001479' },
        marker: {
          color: '#001479',
        },
        color: entries.map(([t, y]) => '#001479'),
      };
    });
  }, [annotations, rangeTraces]);

  const traces = useMemo(() => {
    if (show) {
      return rangeTraces.concat(annotationTraces);
    }
    return rangeTraces;
  }, [rangeTraces, show, annotationTraces]);

  const firstPlot = useRef();
  const myPlot = useRef();
  useEffect(() => {
    if (traces.length === firstPlot.current) {
      const plotData = {
        x: [...traces.map((s) => s.x)],
        y: [...traces.map((s) => s.y)],
        text: [...traces.map((s) => s.text)],
        'marker.color': [...traces.map((s) => s.color)],
        'marker.line.color': [...traces.map((s) => s.color)],
        'line.dash': [...traces.map((s) => s.dash)],
      };
      Plotly.update(`${prefix}_myPlot`, plotData);
    } else {
      const plotData = [...traces];
      var layout = {
        uirevision: true,
      };
      Plotly.newPlot(`${prefix}_myPlot`, plotData, layout);
      myPlot.current?.on('plotly_click', function (data) {
        if (_annotating.current === 1 && data.points.length > 0) {
          const p = data.points[0];
          currentAnn.current = {
            startTimestampMs: traces[p.curveNumber]?.t[p.pointIndex],
            endTimestampMs: undefined,
          };
          setAnnotating(2);
        } else if (_annotating.current === 2 && data.points.length > 0) {
          const p = data.points[0];
          currentAnn.current.endTimestampMs =
            traces[p.curveNumber]?.t[p.pointIndex];
          onAnnotate({
            tagId,
            ...currentAnn.current,
          });
          setAnnotating(0);
        }
      });
      firstPlot.current = traces.length;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [traces]);

  return (
    <>
      <Typography variant="h5" sx={{ mt: 3, mb: 2 }}>
        <ScatterPlotIcon style={{ height: 40, width: 40 }} /> {title}
      </Typography>
      <Paper sx={{ p: 2 }}>
        <Box sx={{ mb: 2, display: 'flex', alignItems: 'center' }}>
          <Button
            variant="outlined"
            sx={{ mr: 2 }}
            startIcon={<BookmarksOutlinedIcon />}
            onClick={() => setShow(!show)}
          >
            {show > 0 ? 'Hide Annotations' : 'Show Annotations'}
          </Button>
          <Button
            variant="outlined"
            sx={{ mr: 2 }}
            startIcon={<LabelOutlinedIcon />}
            onClick={handleAnnotate}
          >
            {annotating > 0 ? 'Cancel' : 'Annotate'}
          </Button>
          {annotating === 1 && (
            <Typography>
              Select a start location by clicking a data point on the plot below
            </Typography>
          )}
          {annotating === 2 && (
            <Typography>
              Select a stop location by clicking a data point on the plot below
            </Typography>
          )}
        </Box>
        <div
          ref={myPlot}
          id={`${prefix}_myPlot`}
          className="plot"
          style={{ height }}
        />
      </Paper>
    </>
  );
}

export default DatasetRangesPlot;
